import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Row, Col, Image } from '@themesberg/react-bootstrap';
import { withRouter } from "react-router";
import AppContext from '../components/AppContext';
import CommonHeader from '../components/CommonHeader';
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import { useTranslation } from 'react-i18next';
import { decryptData } from '../utils/crypto';

const AlertSummary = ({ children, setSelectedYear, selectedWebsiteId, setSelectedWebsiteId, setSelectedOtpclientid }) => {
  const [t, i18n] = useTranslation();
  const contextData = useContext(AppContext);
  const [selectedYearNew, setSelectedYearNew] = useState({});
  const [selectedOtpclientidNew, setSelectedOtpclientidNew] = useState({})
  const [autoLoad, setAutoLoad] = useState(false)
  const [updateKey, setUpdateKey] = useState(0);
  const [blurLoader, setBlurLoader] = useState(false);
  const [years, setYears] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("IsPageRefresh") == "1") {
      setSelectedOtpclientidNew(JSON.parse(localStorage.getItem("alertWebsiteId")));
      setSelectedYearNew(JSON.parse(localStorage.getItem("alertSummaryYear")));
    }
  }, []);

  useEffect(() => {
    if (contextData.companyId > 0) {
      getWebsites();
    }
  }, [contextData.companyId]);

  const getWebsites = () => {
    setBlurLoader(true);
    request(baseURL + `/Intelligence/GetAlertsChannelWebsiteByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
        let resultData = [];
        if (res.result !== null && res.result.length) {
          resultData = res.result.map((e) => ({ ["WebsiteList.WebsiteName"]: e.WebsiteName, ["WebsiteList.WebsiteId"]: e.WebsiteId, ["WebsiteList.WebsiteUrl"]: e.WebsiteUrl, ["ClietConfiguration.otpclientid"]: e.OTPClientId, ["WebsiteList.AlertTrackingDate"]: e.AlertTrackingDate }));
        }
        rendersite({ resultSet: resultData });
      })
      .catch((err) => {
      })
  }

  const onWebsiteDropdownChange = (data) => {
    setSelectedOtpclientidNew({});
    setSelectedOtpclientidNew(data);
  };

  const cancelFilters = () => {
    onWebsiteDropdownChange(JSON.parse(localStorage.getItem("alertWebsiteId")));
    onYearDropdownChange(JSON.parse(localStorage.getItem("alertSummaryYear")));
  }

  const applyFilters = () => {
    setBlurLoader(false);
    const websiteId = selectedOtpclientidNew["WebsiteList.WebsiteId"];
    setSelectedWebsiteId(websiteId);
    contextData.changeWebsiteId(websiteId);
    setSelectedOtpclientid(selectedOtpclientidNew);
    setSelectedYear(selectedYearNew);
    localStorage.setItem("alertWebsiteId", JSON.stringify(selectedOtpclientidNew));
    localStorage.setItem("alertSummaryYear", JSON.stringify(selectedYearNew));
  }

  const onYearDropdownChange = (data) => {
    setSelectedYearNew(data);
  };

  const renderYears = () => {
    let currentYear = new Date().getFullYear();
    const uptoYear = currentYear - 13;
    let ddlYears = [{ id: currentYear, year: currentYear }];
    currentYear--;
    while (currentYear >= uptoYear) {
      ddlYears.push({ id: currentYear, year: currentYear });
      currentYear -= 1;
    }
    setYears(ddlYears);
    if (Object.keys(selectedYearNew).length <= 0) {
      setSelectedYearNew(ddlYears[0]);
      setUpdateKey(Math.random);
    }
    if (localStorage.getItem("alertSummaryYear") !== null) {
      setSelectedYearNew(JSON.parse(localStorage.getItem("alertSummaryYear")));
    }
  };

  const rendersite = ({ resultSet, error, pivotConfig, request }) => {
    if (resultSet !== undefined && resultSet.length > 0) {
      renderYears();
      localStorage.setItem("alertSummaryData", JSON.stringify(resultSet));
      if (selectedWebsiteId !== null && Object.keys(selectedWebsiteId).length <= 0) {
        if (JSON.parse(localStorage.getItem("alertWebsiteId")) !== null) {
          onWebsiteDropdownChange(JSON.parse(localStorage.getItem("alertWebsiteId")));
          setAutoLoad(true);
        } else {
          onWebsiteDropdownChange(resultSet[0]);
          setAutoLoad(true);
        }
      }
    }
  };

  const commonHeader = useMemo(() => <CommonHeader cancelFilters={cancelFilters} channel="alert" autoLoad={autoLoad} dataSource={[
    { name: 'Dropdown1 - website', data: JSON.parse(localStorage.getItem("alertSummaryData")), selectedItem: selectedOtpclientidNew, isVisible: true, label: t('header.alertSummaryFiltersLabelWebsite'), callback: onWebsiteDropdownChange, displayText: "WebsiteList.WebsiteName" },
    { name: 'Dropdown2 - year', data: years, selectedItem: selectedYearNew, isVisible: true, label: t('header.alertSummaryFiltersLabelYear'), callback: onYearDropdownChange, displayText: "year" },
  ]} title={t("header.AlertSummaryHeader")} showDate={false} applyFilters={applyFilters} />, [selectedOtpclientidNew, selectedYearNew, autoLoad])

  return (
    <>
      {blurLoader && <div id='blurdiv' className="blurbackground">
        <div className={`preloader loader-center-align`}>
          <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
        </div>
      </div>}
      <Row>
        <Col sm={12}>
          {commonHeader}
        </Col>
      </Row>
      <Row id="alertSummary">
        <Col xs={12}>
          <h5 className="h5 mt-4 mb-0 ms-3">{t("header.AlertSummaryTableHeader")}</h5>
          {contextData.selectedTab === "Alerts" &&
            <div className='d-inline-block p-3 pe-0 w-100'>
              {children}
            </div>}
        </Col>

      </Row>
    </>
  );
};

export default withRouter(AlertSummary);
