import React, { useContext } from 'react';
import axios from "axios";
import { urls } from "../utils/demoClientHelper";
import { decryptData } from "../utils/crypto";
import AppContext from '../components/AppContext';

const ProcessClientData = () => {
    const contextData = useContext(AppContext);

    const processData = async () => {
        const dateRanges = [180, 90, 30, 7];

        for (const dateRange of dateRanges) {
            const results = [];
            const tasks = urls.map(url => processUrlAsync(url, results, dateRange));
            await Promise.all(tasks);
        }
        return true;
    }

    async function processUrlAsync(url, results, dateRange) {
        try {
            const endDate = new Date();
            endDate.setDate(endDate.getDate() - 1);
            const startDate = new Date(endDate);
            startDate.setDate(startDate.getDate() - dateRange + 1);

            const endDateString = endDate.toISOString().split('T')[0];
            const startDateString = startDate.toISOString().split('T')[0];

            const urlObj = new URL(url);
            const queryParams = new URLSearchParams(urlObj.search);
            const oldParams = {};
            const newParams = {};

            for (let [key, value] of queryParams) {
                key = key.replace(/\s+/g, '')
                if (['startdate', 'enddate', 'websiteurl', 'intellconfigid', 'companyid', 'intelconfigid', 'selectedcompanyid', 'countryid', 'visitorid', 'culture', 'toolid', 'traffic'].includes(key.toLowerCase())) {
                    oldParams[key] = key.toLowerCase() === 'startdate' ? startDateString :
                        key.toLowerCase() === 'enddate' ? endDateString :
                            value;
                    continue;
                }
                oldParams[key] = value;
                newParams[key] = value;
            }

            const newUrl = updateUrlPathAndQuery(urlObj, newParams, true).replace(/[\+\s]/g, '');
            url = updateUrlPathAndQuery(urlObj, oldParams, false).replace(/[\+\s]/g, '');

            const data = await fetchDataAsync(url);
            results.push({ key: `${newUrl}_${dateRange}`, value: data });

        } catch (error) {
            console.error(`Error processing URL ${url}: ${error.message}`);
        }
    }

    // Function to update URL with new parameters
    function updateUrlPathAndQuery(uri, params, isNewURL) {
        const urlObj = new URL(uri);
        urlObj.search = new URLSearchParams(params).toString();
        return isNewURL ? (urlObj.pathname.toString() + urlObj.search.toString()) : urlObj.toString();
    }

    // Function to fetch data from the URL
    async function fetchDataAsync(url) {
        const response = await axios.get(url, {
            headers: {
                'Authorization': `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`
            }
        });
        return response.data;
    }

    return (
        <div className=" d-flex justify-content-center align-items-center">
            <button className="mt-5 btn btn-primary" onClick={processData}>Process Data</button>
        </div>
    )
}

export default ProcessClientData;