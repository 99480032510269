import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Form, Image, Row, Tooltip, OverlayTrigger, Button, Alert } from "@themesberg/react-bootstrap";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import { pageValuesByKey } from "../utils/constant";
import { convertTranslatinString } from "../utils/common";

import DataChartsComponent from "./DataCharts";

const DataChartRenderer = ({ barChartClickEvent, ...props }) => {

    let {
        itemId,
        chartType,
        cardProperty,
        data,
        widjetName,
        isLegendShown,
        legends,
        title
    } = props;

    const [t, i18n] = useTranslation();
    const [apiRes, setApiRes] = React.useState(undefined);
    const [summeryData, setSummeryData] = React.useState([]);
    const [tooltipelementremoval, setToolTipElementRemoval] = React.useState([]);
    const [alertVisibleMessage, setAlertVisibleMessage] = useState(null);


    if (isMobile && chartType === "pie") {
        cardProperty.h = 15
    }

    useEffect(() => {
        if (chartType === "multiline") {
            hideLinesInGraph(parseDataDynamic(data));
        }
        else if (chartType === "pie") {
            setApiRes(addingTooltipToData(data));
        }
        else {
            setApiRes(data);
        }
    }, [data])

    const addingTooltipToData = (data) => {
        let newResult = [];
        let itemToSum = Array.isArray(data) ? data.map(x => x.TotalOpenCount) : [];
        let total = itemToSum.reduce((a, b) => a + b, 0)

        data && Array.isArray(data) && data.map((item) => {
            let toolTip = item?.ToolTipTitle ? item?.ToolTipTitle : "";
            let obj = {
                ...item,
                toolTip: Math.round(item.TotalOpenCount * 100 / total) + "% " + toolTip
            }

            newResult.push(obj);
        })
        return newResult;
    }

    const parseDataDynamic = (res) => {
        let data = [];
        let sData = [];
        let type = res?.type;
        res && Object.keys(res).map((item, i) => {
            if (typeof res[item] == 'object') {
                let s = {
                    type: type,
                    key: item.split('_').join(' '),
                    values: [],
                    copyValues: [],
                    show: 1
                }
                res[item].map((i) => {
                    s.values.push({ "value": i.Count, "category": i.ProcessDate, "xvalue": i.ProcessDate, "x": i.ProcessDate })
                })

                data.push(s);
            }
            else {
                let formatSummery = {
                    "shortTitle": item,
                    "value": res[item],
                }
                typeof res[item] != "string" && sData.push(formatSummery);
            }
        })
        setSummeryData(sData);
        return data;
    }

    const hideLinesInGraph = (data) => {
        let apiDataUpdated = [];
        if (data?.length > 0) {
            if (chartType === "multiline") {
                data.map((e) => {
                    if (e?.key) {
                        apiDataUpdated.push(e);
                    }
                })
            }
        }
        setApiRes(apiDataUpdated);
    }

    const TypeToChartComponent = {
        line: (props) => {
            return <DataChartsComponent type="line" {...props} />;
        },
        pie: (props) => {
            return <DataChartsComponent type="pie" legendPercentage={true} {...props} />;
        },
        multiline: (props) => {
            return <DataChartsComponent type="multiline" datafortooltip={tooltipelementremoval} {...props} />;
        },
        map: (props) => {
            return <DataChartsComponent type="map" title={title} datafortooltip={tooltipelementremoval} {...props} />;
        },
        bar: (props) => {
            return <DataChartsComponent type="bar" legendPercentage={true} barChartClickEvent={barChartClickEvent} {...props} />
        },
        areachart: (props) => {
            return <DataChartsComponent type="areachart"  {...props} />
        }
    };

    const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
        .map((key) => ({
            [key]: React.memo(TypeToChartComponent[key]),
        }))
        .reduce((a, b) => ({ ...a, ...b }));

    const component = TypeToMemoChartComponent[chartType];

    const createTooltip = (t, tooltipKey, placement) => {

        if (tooltipKey !== "") {

            return (
                <OverlayTrigger
                    placement={placement ? placement : "right"}
                    trigger={["hover", "focus"]}
                    overlay={
                        <Tooltip>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: t(tooltipKey, {
                                        interpolation: { escapeValue: false },
                                    }),
                                }}
                            ></div>
                        </Tooltip>
                    }
                >
                    <Button variant="white" className="btn-help border-0 p-1">
                        <i className="far fa-question-circle lg"></i>
                    </Button>
                </OverlayTrigger>
            );
        }
    }

    const renderProps = props.data;

    function formatCount(count) {
        if (count >= 1000000000) {
            return (count / 1000000000).toFixed(2) + "B";
        } else if (count >= 1000000) {
            return (count / 1000000).toFixed(2) + "M";
        } else if (count >= 1000) {
            return (count / 1000).toFixed(2) + "K";
        }
        return count.toString();
    }

    const onSwitchChangeMultilineChart = (e, legend) => {
        const toggleState = e.target.checked; // Current toggle state
        const updatedData = [...apiRes]; // Copy of current data
        const labels = [...tooltipelementremoval]; // Copy of current labels

        // Step 1: Disable items based on keysToDisable prop
        updatedData.forEach((item) => {
            if (props.customAttributes.keysToDisable && props.customAttributes.keysToDisable.includes(item.key)) {
                item.show = 0; // Set show property to false for matching keys
                const labelIndex = labels.indexOf(item.key);
                if (labelIndex === -1) labels.push(item.key); // Add to disabled list if not already present
            }
        });

        const activeToggles = updatedData.filter(item => item.show === 1).length; // Count of active toggles

        updatedData.forEach((item) => {
            if (item.key === legend) {
                if (toggleState) {
                    // Enabling the toggle
                    item.show = 1;
                    const labelIndex = labels.indexOf(legend);
                    if (labelIndex > -1) labels.splice(labelIndex, 1); // Remove from disabled list
                } else {
                    // Disabling the toggle
                    if (activeToggles === 1) {
                        // Last pending switch: Show message and re-enable the toggle
                        setAlertVisibleMessage(t("header.LastPendingToggleMessage", "At least one option needs to be enabled."));
                        setTimeout(() => setAlertVisibleMessage(null), 3000);
                        item.show = 1; // Re-enable the toggle
                    } else {
                        item.show = 0;
                        labels.push(legend); // Add to disabled list
                    }
                }
            }
        });

        // Reset the `isDisable` property for all items
        updatedData.forEach((item) => (item.isDisable = false));

        // Update state with modified data and labels
        setToolTipElementRemoval(labels);
        setApiRes(updatedData);
    };

    const renderChart =
        (Component) =>
            ({
                cardProperty,
                tableCaption,
            }) => {
                return (
                    <>

                        {apiRes ?
                            <>
                                {alertVisibleMessage && (<div className="fade modal-backdrop show"></div>)}
                                <Alert
                                    className="toast-custom"
                                    variant="success"
                                    show={alertVisibleMessage}
                                >
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                                            {alertVisibleMessage}
                                        </div>
                                        <Button variant="close" size="xs" onClick={() => setAlertVisibleMessage(null)} />
                                    </div>
                                </Alert>
                                <Component
                                    isSummeryShown={false}
                                    data={apiRes}
                                    resultSet={apiRes}
                                    isResFromAPI={true}
                                    cardProperty={cardProperty}
                                    widjetName={widjetName}
                                    isLegendShown={isLegendShown}
                                    legends={legends}
                                />
                                {chartType === "multiline" &&
                                    <Row>
                                        <Col>
                                            <div className="switch-toggle-component w-100 tools-chart-renderer">
                                                {props.customAttributes.titleAboveToggles && <div className="row">
                                                    <div className="col-12">
                                                        <h5 className="mt-4">
                                                            <div style={{ textAlign: "center" }}>
                                                                {t("header.title_" + convertTranslatinString(props.customAttributes.titleAboveToggles), props.customAttributes.titleAboveToggles)}
                                                                {props.customAttributes.tooltipText && createTooltip(t, props.customAttributes.tooltipText)}
                                                            </div>
                                                        </h5>
                                                    </div>
                                                </div>}
                                                {apiRes?.length > 0 && summeryData.map((summery, index) => (
                                                    <div className="d-inline-block switch-toggle-component-item">
                                                        <div className="metrics-label-container">
                                                            <label className={'widget-summary-metrics-label d-inline-block label-' + (index + 1)}>{apiRes[index]?.key}</label>
                                                            <div className={'widget-summary-metrics metrics-' + (index + 1)}>{formatCount(summery.value) ?? 0}</div>
                                                        </div>
                                                        <div className="toggle-switch-container">
                                                            {pageValuesByKey.find(x => x?.key == apiRes[index]?.key)?.IsSwitch !== 0 && (
                                                                <Form className="d-inline-block ms-2">
                                                                    <Form.Check
                                                                        type="switch"
                                                                        className={apiRes[index]?.show === 1 ? "switch-checked" : "switch-uncheck"}
                                                                        checked={apiRes[index]?.show === 1} // Dynamically control the switch state
                                                                        onChange={(e) => onSwitchChangeMultilineChart(e, apiRes[index].key)}
                                                                        label=""
                                                                        id={`switch${index}`} // Ensure unique IDs for accessibility
                                                                        htmlFor={`switch${index}`}
                                                                        disabled={apiRes[index]?.isDisable}
                                                                    />
                                                                </Form>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </Col>
                                    </Row>} </> : <>{chartType == "areachart" && <Component
                                        isResFromAPI={true}
                                    />}</>}
                    </>
                );
            };

    return (
        component &&
        renderChart(component)({
            itemId,
            ...renderProps,
            cardProperty,
            // items,
            // setItems,
            // onItemChange,
            // onPageNoChange,
            // showSearchTextBox,
            // searchText,
            // setSearchText,
            // onSearchBoxChange,
            // tableCaption,
        })
    );
};

DataChartRenderer.defaultProps = {
    cardProperty: {},
    summaryBesideText: "",
}

DataChartRenderer.propTypes = {
    cardProperty: PropTypes.object,
    summaryBesideText: PropTypes.func,
};

export default React.memo(DataChartRenderer);