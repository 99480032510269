import React, { useEffect, useState, useContext } from 'react';
import ArchiveAlerts from '../components/ArchiveAlerts';
import { isMobile } from "react-device-detect";
import { archieveAlerts } from "../stubs/ComponentJson/alerts";
import ChartRenderer from '../components/ChartRenderer';
import ChartSummary from '../components/CardSummary';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import DashboardItem from '../components/DashboardItem';
import { Button, Alert } from '@themesberg/react-bootstrap'
import { CARD_MOBILE_LAYOUT, DEFAULT_PAGE_NO } from "../AppConfig";
import AppContext from '../components/AppContext';
import SummaryRenderer from '../components/SummaryRenderer';
import { TABLE_TOTAL_ROWS } from "../AppConfig";
import ChartRendererTable from '../components/ChartRendererTable';
import Search from "../components/Search";
import { useTranslation } from 'react-i18next';



const deserializeItem = (i) => ({
    ...i,
    layout: JSON.parse(i.layout) || {},
    summary: i.summary ? JSON.parse(i.summary) : {},
    vizState: i.vizState ? JSON.parse(i.vizState) : {},
    apiUrl: i.apiUrl ? i.apiUrl : undefined
});

const defaultLayout = (i) => ({
    x: i.layout.x || 0,
    y: i.layout.y || 0,
    w: i.layout.w || 4,
    h: i.layout.h || 15,
    minW: 4,
    minH: 15,
});

const defaultLayoutMobile = (i) => ({
    x: i.layout.x || 0,
    y: i.layout.y || 0,
    w: 12,
    // w: i.layout.w || 4,
    h: i.layout.h + 2 || 12,
    minW: 4,
    minH: 8,
});


export const ArchiveAlertsPage = () => {
    const [t, i18n] = useTranslation();
    const { companyId } = useContext(AppContext);
    const [selectedWebsiteId, setSelectedWebsiteId] = useState({});
    const [alertStatsData, setAlertStatsData] = useState([]);
    const [selectedOtpclientid, setSelectedOtpclientid] = useState({});
    const [pageNo, setPageNo] = useState(0);
    const [offset, setOffset] = useState(0);
    const [getTotalResults, setGetTotalResults] = useState(0)
    const [items, setItems] = useState([]);
    const [searchData, setSearchData] = useState("");
    // const [apiUrl, setApiUrl] = useState({ url: `${process.env.REACT_APP_API_KEY}/Website/GetArchivedAlertsByWebsite?messageid=0&websiteid=12007&companyid=9345&page=1`, type: 'get', count: 0 });

    const [numberOfRecords, setNumberOfRecords] = useState(0);
    const contextData = useContext(AppContext);
    useEffect(() => {

        const websiteId = contextData.websiteId;
        const companyId = contextData.companyId
        const otpClientId = selectedOtpclientid && selectedOtpclientid["ClietConfiguration.otpclientid"];
        if (websiteId > 0 && contextData.selectedTab == "Alerts") {
            if (pageNo === 0) {
                setPageNo(1);
            }

            let dta = archieveAlerts(otpClientId, offset, websiteId, companyId, pageNo);
            setAlertStatsData(dta);
        }
        else {
            setAlertStatsData([]);
        }
    }, [contextData.websiteId, contextData.selectedTab]);

    useEffect(() => {
        setSearchData("");
        if (pageNo && selectedOtpclientid["ClietConfiguration.otpclientid"] > 0) {
            onPageNoChange(pageNo);
        }
    }, [selectedOtpclientid])

    const onPageNoChange = (number) => {
        const num = (number - 1) * TABLE_TOTAL_ROWS;
        const page = number;
        setOffset(num);
        setPageNo(page);
        // setApiUrl({ url: `${process.env.REACT_APP_API_KEY}/Website/GetArchivedAlertsByWebsite?messageid=0&websiteid=${12007}&companyid=${companyId}`, type: 'get' })
    }

    const renderSummary = (cardSummary) => {
        return (
            <ChartSummary
                summaryPrimary={cardSummary.summaryPrimary}
                summarySecondary={cardSummary.summarySecondary}
                isCarouselCard={cardSummary.isCarouselCard}
                showPercentageForPrimary={cardSummary.showPercentageForPrimary}
                showPositiveOrNegativeForPrimary={cardSummary.showPositiveOrNegativeForPrimary}
                showPercentageForSecoundary={cardSummary.showPercentageForSecoundary}
                showPositiveOrNegativeForSecoundary={cardSummary.showPositiveOrNegativeForSecoundary}
                carousalMainSummary={cardSummary.carousalMainSummary}
            />
        );

    };

    const dashboardItem = (item) => (
        <div key={item.id} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}>
            <DashboardItem
                key={item.id}
                itemId={item.id}
                title={t("header.AlertArchivedInstruction")}
                customTitle={"archivedalert"}
            >
                {/* {Object.keys(item.summary).length > 0 &&

                    <SummaryRenderer summaryTooltip={'header.archivedAlertsTooltipText'} apiUrl={apiUrl} numberOfRecords={30} vizState={item.summary} title={item.name} />
                } */}

                {/* <ChartRenderer totalRecords={numberOfRecords} setNumberOfRecords={setNumberOfRecords} apiUrl={apiUrl } vizState={item.vizState} cardProperty={item.layout} legends={item.legends} setPageNo={setPageNo} pageNo={pageNo} onPageNoChange={onPageNoChange} showPagination={item.pagination}
                   offset={offset} setOffset={setOffset}/> */}
                {
                <div className="float-right">
                    <div className="mt-3">
                        
                        <Search searchWaterMark={t("header.searchPlaceHolder")} setsearchData={setSearchData} searchData={searchData} isAutoComplete={false} type={'client'}></Search>
                    </div>
                </div>
                }
                {item.apiUrl && 
                <ChartRendererTable
                    showCount={true}
                    minimumRows={10}
                    showPagination={true}
                    apiUrl={item.apiUrl}
                    summaryBesideText={t("header.AlertArchivedTotalAlertText")}
                    showNumberOfRows={10}
                    totalRecords={numberOfRecords}
                    // setNumberOfRecords={setNumberOfRecords}
                    SearchText={searchData}
                    setSearchText={setSearchData}
                    setGetTotalResults={(value) => setGetTotalResults(value)}
                />}
            </DashboardItem>
        </div>
    );

    const Empty = () => (
        <div
            style={{
                textAlign: 'center',
                padding: 12,
            }}
        >
            <h2>{t("header.noChartsonDashboard")}</h2>
            {/* <Link to="/explore"> */}
            <Button variant="secondary" className="m-1">
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                {t("header.addChart")}
            </Button>
            {/* </Link> */}
        </div>
    );

    return alertStatsData && alertStatsData.length > 0 ? (
        <ArchiveAlerts dashboardItems={alertStatsData}
            selectedWebsiteId={selectedWebsiteId}
            selectedOtpclientid={selectedOtpclientid}
            setSelectedWebsiteId={setSelectedWebsiteId}
            setSelectedOtpclientid={setSelectedOtpclientid}
            setPageNo={setPageNo}
            setOffset={setOffset}
        >
            {alertStatsData.map(deserializeItem).map(dashboardItem)}
        </ArchiveAlerts>
    ) : (
        <h1 className='text-center'>connect.ID Intelligence</h1>
    );
};


export default ArchiveAlertsPage;