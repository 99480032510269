import React from 'react';
import { useTranslation } from "react-i18next";
import { Row, Col, Card } from "@themesberg/react-bootstrap";

import { DataStatusMessage } from "../../utils/constant";

import CommonMessageComponent from "../CommonMessageComponent";
import DataChartRenderer from "../DataChartRenderer";
import DataTableRenderer from "../DataTableRenderer";

const WebcastCharts = ({ hasEvents, liveChart, onDemandChart, viewerDevice, viewerDomain }) => {
    const [t] = useTranslation();

    return (
        <Row className="half-width-card-container">
            {hasEvents !== 1 && (
                <Col md={6}>
                    <div className="d-inline-block tools-dashboard-card-loader card-spacing-bottom w-100 md-ps-1">
                        <Card border="light" className="bg-white shadow-sm">
                            <Card.Header className="pt-3">
                                {t("header.title_Live")}
                            </Card.Header>
                            <Card.Body>
                                {liveChart.transactionStatus !==
                                    DataStatusMessage.DataAvailable && (
                                        <CommonMessageComponent
                                            transactionStatus={liveChart.transactionStatus}
                                        ></CommonMessageComponent>
                                    )}
                                {liveChart.transactionStatus ===
                                    DataStatusMessage.DataAvailable && (
                                        <DataChartRenderer
                                            data={liveChart.data}
                                            chartType="line"
                                            isLegendShown={true}
                                            legends={[{ name: "Viewers" }]}
                                            widjetName={"Viewerslive"}
                                            cardProperty={{ x: 6, y: 2, w: 6, h: 8 }}
                                        />
                                    )}
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
            )}
            {hasEvents !== 1 && (
                <Col md={6}>
                    <div className="d-inline-block tools-dashboard-card-loader card-spacing-bottom w-100 md-ps-1">
                        <Card border="light" className="bg-white shadow-sm">
                            <Card.Header className="pt-3">
                                {t("header.title_On_demand")}
                            </Card.Header>
                            <Card.Body>
                                {onDemandChart.transactionStatus !==
                                    DataStatusMessage.DataAvailable && (
                                        <CommonMessageComponent
                                            transactionStatus={onDemandChart.transactionStatus}
                                        ></CommonMessageComponent>
                                    )}
                                {onDemandChart.transactionStatus ===
                                    DataStatusMessage.DataAvailable && (
                                        <DataChartRenderer
                                            data={onDemandChart.data}
                                            chartType="line"
                                            isLegendShown={true}
                                            legends={[{ name: "Viewers" }]}
                                            widjetName={"Viewersondemand"}
                                            cardProperty={{ x: 6, y: 2, w: 6, h: 8 }}
                                        />
                                    )}
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
            )}
            <Col md={6}>
                <div className="d-inline-block tools-dashboard-card-loader card-spacing-bottom w-100 md-ps-1">
                    <Card border="light" className="bg-white shadow-sm">
                        <Card.Header className="pt-3">
                            {t("header.title_Viewer_Domains")}
                        </Card.Header>
                        <Card.Body>
                            {viewerDomain.transactionStatus !==
                                DataStatusMessage.DataAvailable && (
                                    <CommonMessageComponent
                                        transactionStatus={viewerDomain.transactionStatus}
                                    ></CommonMessageComponent>
                                )}
                            {viewerDomain.transactionStatus ===
                                DataStatusMessage.DataAvailable && (
                                    <DataChartRenderer
                                        data={viewerDomain.data}
                                        cardProperty={{ x: 0, y: 0, w: 6, h: 8 }}
                                        chartType="pie"
                                    />
                                )}
                        </Card.Body>
                    </Card>
                </div>
            </Col>
            {hasEvents !== 1 && (
                <Col md={6}>
                    <div className="d-inline-block tools-dashboard-card-loader card-spacing-bottom w-100 md-ps-1">
                        <Card border="light" className="bg-white shadow-sm">
                            <Card.Header className="pt-3">
                                {t("header.title_Viewer_Device")}
                            </Card.Header>
                            <Card.Body>
                                {viewerDevice.transactionStatus !==
                                    DataStatusMessage.DataAvailable && (
                                        <CommonMessageComponent
                                            transactionStatus={viewerDevice.transactionStatus}
                                        ></CommonMessageComponent>
                                    )}
                                {viewerDevice.transactionStatus ===
                                    DataStatusMessage.DataAvailable && (
                                        <DataChartRenderer
                                            data={viewerDevice.data}
                                            cardProperty={{ x: 0, y: 0, w: 6, h: 8 }}
                                            chartType="pie"
                                        />
                                    )}
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
            )}
            {hasEvents === 1 && (
                <Col md={6}>
                    <div className="d-inline-block tools-dashboard-card-loader card-spacing-bottom w-100 md-ps-1">
                        <Card border="light" className="bg-white shadow-sm">
                            <Card.Header className="pt-3">
                                {t("header.title_Viewer_Domains")}
                            </Card.Header>
                            <Card.Body>
                                {viewerDomain.transactionStatus !==
                                    DataStatusMessage.DataAvailable && (
                                        <CommonMessageComponent
                                            transactionStatus={viewerDomain.transactionStatus}
                                        ></CommonMessageComponent>
                                    )}
                                {viewerDomain.transactionStatus ===
                                    DataStatusMessage.DataAvailable && (
                                        <DataTableRenderer
                                            header={viewerDomain.header}
                                            dataSet={viewerDomain.data}
                                            transactionStatus={viewerDomain.transactionStatus}
                                            customAttributes={{
                                                displayRowsPerPage: 5,
                                                showCount: true,
                                                summaryCount: viewerDomain.count,
                                                showPagination: false,
                                            }}
                                        ></DataTableRenderer>
                                    )}
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
            )}
        </Row>
    );
}

export default WebcastCharts;