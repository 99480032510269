import axios from 'axios';
import React, { Component, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import { isEmpty, updateLocalStorageObject } from '../AppConfig';
import AppContext from '../components/AppContext';
import { getMenusAndSubMenuDetails } from '../utils/common';
import { menuItemsDetais } from '../utils/sidebar';
import { header_languages } from "../AppConfig";
import { encryptData, decryptData } from '../utils/crypto';

const ClientPreview = (props) => {
    const contextData = useContext(AppContext);
    const [selectedLanguage, setSelectedLanguage] = useState({});
    const [t, i18n] = useTranslation();
    const [isLanguageChanged, setIsLanguageChanged] = useState(false);
    const [captcha, setCaptcha] = useState("");

    const { pathname } = useLocation();
    const parts = pathname.split('/');
    const token = parts[2];

    const getMenu = () => {
        //1stroundchange change in whole method
        // setBlurLoader(true)
        axios
            .get(
                `${process.env.REACT_APP_API_KEY}/UserV2/GetMainMenuByClientUser`,
                {
                    params: {
                        userId: contextData.userId,
                        companyid: contextData.companyId,
                        websiteid: contextData.websiteId
                    },
                    headers: {
                        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                    }
                },
            )
            .then(response => {
                if (response && response.data && response.data.resultData.menuDetails) {
                    //let menuDta = getMenusAndSubMenuDetails(response.data.resultData.menuDetails);
                    // contextData.setMenu(menuDta);
                    let menuDta = response.data.resultData.menuDetails;
                    menuDta = menuDta.filter((x) => { return x.id !== 4 && x.id !== 13 && x.id !== 14 })



                    if (Array.isArray(menuDta) && menuDta.length > 0) {
                        let defaultMenuData = menuDta.filter(dta => dta.isActive === true)[0];

                        if (defaultMenuData) {
                            let settingDta = {
                                id: 0,
                                dispIndex: 13,
                                isActionPoint: false,
                                isActive: true,
                                subMenu: [],
                                subSections: null,
                                title: "Settings"
                            };
                            menuDta.push(settingDta);
                            contextData.setMenu(menuDta);
                        }
                        else {
                            let submenuarray = [{ actionParam: "", actionType: 1, dispIndex: 0, id: 1, isActionPoint: true, isActive: true, isEnabled: true, title: "My account" },
                            { actionParam: "", actionType: 1, dispIndex: 1, id: 2, isActionPoint: true, isActive: true, isEnabled: true, title: "My documents" },
                            { actionParam: "", actionType: 1, dispIndex: 2, id: 3, isActionPoint: true, isActive: true, isEnabled: true, title: "Digest subscriptions" }];

                            let settingDta = {
                                id: 0,
                                dispIndex: 13,
                                isActionPoint: false,
                                isActive: true,
                                subMenu: submenuarray,
                                subSections: null,
                                title: "Settings"
                            };
                            menuDta.push(settingDta);

                            // newobject.subMenu = submenuarray;
                            contextData.setMenu(menuDta);

                            localStorage.setItem("selectedTab", "Settings")
                            contextData.changeSelectedTab("Settings");
                            props.history.push(`/settings/myaccount-page`);
                        }



                        if (defaultMenuData.id === 1) {
                            localStorage.setItem("selectedTab", "Website")
                            contextData.changeSelectedTab("Website");
                            props.history.push(`/website`);
                        }
                        else if (defaultMenuData.id === 8) {
                            request(baseURL + `/Intelligence/GetAlertsChannelWebsiteByClientUser?companyid=` + contextData.companyId,
                                METHODS.GET,
                                {},
                                {
                                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                                })
                                .then((res) => {

                                    if (res && res.result.length > 0) {

                                        let wid = res.result[0].WebsiteId;
                                        request(baseURL + `/UserV2/GetSubMenuByClientUser?companyId=${contextData.companyId}&sectionid=` + defaultMenuData.id + `&websiteid=${wid}`,
                                            METHODS.GET,
                                            {},
                                            {
                                                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                                            })
                                            .then((res) => {
                                                let menuDta = res[0].subSections;

                                                let subMenuDetails = menuItemsDetais.find(men => men.title === menuDta[0].actions[0].title);

                                                if (subMenuDetails.link) {
                                                    localStorage.setItem("selectedTab", "Alerts")
                                                    contextData.changeSelectedTab("Alerts");
                                                    props.history.push(subMenuDetails.link);
                                                }
                                                else {
                                                    props.history.push(`/website`);
                                                }
                                            })
                                            .catch((err) => {

                                            })

                                    }
                                    else {
                                        localStorage.setItem("selectedTab", "Alerts")
                                        contextData.changeSelectedTab("Alerts");
                                        props.history.push("/alert-marketing");
                                    }
                                })
                                .catch((err) => {

                                })
                        }
                        else if (defaultMenuData.id === 5) {

                            request(baseURL + `/UserV2/GetSubMenuByClientUser?companyId=${contextData.companyId}&sectionid=` + defaultMenuData.id + `&websiteid=0`,
                                METHODS.GET,
                                {},
                                {
                                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                                })
                                .then((res) => {
                                    let menuDta = res[0].subSections;

                                    let subMenuDetails = menuItemsDetais.find(men => men.title === menuDta[0].actions[0].title);

                                    if (subMenuDetails.link) {
                                        localStorage.setItem("selectedTab", "Webcasting & Video")
                                        contextData.changeSelectedTab("Webcasting & Video");
                                        props.history.push(subMenuDetails.link);
                                    }
                                    else {
                                        props.history.push(`/website`);
                                    }
                                })
                                .catch((err) => {

                                })


                        }
                        else if (defaultMenuData.id === 6) {
                            request(baseURL + `/Intelligence/GetToolsWebsiteForClientUser?companyid=` + contextData.companyId,
                                METHODS.GET,
                                {},
                                {
                                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                                })
                                .then((res) => {
                                    if (res && res.result.length > 0) {

                                        let wid = res.result[0].WebsiteId;
                                        request(baseURL + `/UserV2/GetSubMenuByClientUser?companyId=${contextData.companyId}&sectionid=` + defaultMenuData.id + `&websiteid=${wid}`,
                                            METHODS.GET,
                                            {},
                                            {
                                                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                                            })
                                            .then((res) => {
                                                let menuDta = res[0].subSections;

                                                let subMenuDetails = menuItemsDetais.find(men => men.title === menuDta[0].actions[0].title);

                                                if (subMenuDetails.link) {
                                                    localStorage.setItem("selectedTab", "Tools")
                                                    contextData.changeSelectedTab("Tools");
                                                    props.history.push(subMenuDetails.link);
                                                }
                                                else {
                                                    props.history.push(`/website`);
                                                }
                                            })
                                            .catch((err) => {

                                            })

                                    }
                                    else {
                                        localStorage.setItem("selectedTab", "Alerts")
                                        contextData.changeSelectedTab("Alerts");
                                        props.history.push("/tools-marketing");
                                    }

                                })
                                .catch((err) => {

                                })
                        }
                        else if (defaultMenuData.id === 10) {
                            localStorage.setItem("selectedTab", "Reports")
                            contextData.changeSelectedTab("Reports");
                            props.history.push(`/reports`);
                        }
                        else if (defaultMenuData.id === 11) {
                            localStorage.setItem("selectedTab", "Service Centre")
                            contextData.changeSelectedTab("Service Centre");
                            props.history.push(`/service-centre-pages`);
                        }


                        // if (Array.isArray(defaultMenuData.subMenu) && defaultMenuData.subMenu.length > 1) {
                        //     let defaultSubMenuData = defaultMenuData.subMenu.filter(dta => dta.isActive === true)[0];
                        //     const subMenuDetails = menuItemsDetais.find(dta => dta.title === defaultSubMenuData.title);
                        //     if (subMenuDetails && Object.keys(subMenuDetails).length > 0)
                        //         props.history.push(`${subMenuDetails.link}`);
                        // }
                        // else if (defaultMenuData) {
                        //     const menuDta = menuItemsDetais.find(dta => dta.title === defaultMenuData.title);
                        //     if (menuDta && Object.keys(menuDta).length > 0)
                        //         props.history.push(`${menuDta.link}`);
                        // }
                    }
                }
                else {
                    // setShowMeRedirect(false);
                    // setShowMePassword(true);
                    // setShowMeLogin(true);
                    // setShowMeInvalid(true);
                    // setShowMeDetails(false);
                }
            })
            .catch(error => {
                console.log("login error", error);
            });
    }

    const getSelectedLanguage = () => {
        let language;
        if (i18n.language) {
            const selectedLanguagData = header_languages.find(dta => dta.id === i18n.language);
            if (selectedLanguagData && Object.keys(selectedLanguagData).length > 0) {
                language = selectedLanguagData;
                setSelectedLanguage(selectedLanguagData);
                setIsLanguageChanged(true);
            }
        }
        else {
            language = header_languages[0];
            if (contextData.languagePreference) {
                const selectedLanguagData = header_languages.find(dta => dta.id === contextData.languagePreference);
                if (selectedLanguagData && Object.keys(selectedLanguagData).length > 0)
                    language = selectedLanguagData;
                //setSelectedLanguage(selectedLanguagData);
            }
        }
        setSelectedLanguage(language);
        // i18n.changeLanguage(language.id);
    };

    const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const generateString = (length) => {
        let result = '';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        setCaptcha(result);
    }

    useEffect(() => {
        if (contextData.userId > 0 && contextData.companyId > 0) {
            getMenu();
        }
        getSelectedLanguage();
        generateString(6);
    }, [contextData.userId, contextData.companyId])

    useEffect(() => {
        localStorage.clear();
        sessionStorage.clear();
        let obj = {
            "token": token,
            "userId": 0,
            "companyId": 0,
            "isValidate": false
        }
        request(baseURL + `/Common/GetUserDetilsUsingJWT`,
            METHODS.POST,
            obj,
            {}
        )
            .then((response) => {

                const { userToken, userId, languagePreference, firstName, lastName, email, selectedCompanyID, cryptoKey } = response.result;
                contextData.changeCryptoKey(cryptoKey);
                const companies = response.result.resultData;
                let companyId = selectedCompanyID;
                let userName = (!isEmpty(firstName) && !isEmpty(lastName)) ? firstName.concat(lastName) : "";
                let langPref;
                langPref = languagePreference;
                localStorage.setItem("sessionTime", new Date());
                localStorage.setItem("token", encryptData(userToken, cryptoKey));
                localStorage.setItem("userId", encryptData(userId, cryptoKey));
                localStorage.setItem("firstName", encryptData(firstName, cryptoKey));
                localStorage.setItem("lastName", encryptData(lastName, cryptoKey));
                localStorage.setItem("userName", encryptData(userName, cryptoKey));
                localStorage.setItem("companies", JSON.stringify(companies));
                localStorage.setItem("email", encryptData(email, cryptoKey));

                updateLocalStorageObject("selectedCompanyData", "companyId", companyId, cryptoKey)
                // localStorage.setItem("companyId", encryptData(companyId, cryptoKey));

                localStorage.setItem("langPref", langPref);
                localStorage.setItem("userlangPref", langPref);
                localStorage.setItem("selectedCompanyID", selectedCompanyID);

                contextData.changeFirstName(firstName);
                contextData.changeLastName(lastName);
                contextData.changeUserName(userName);
                contextData.changeEmail(email);
                contextData.changeUserToken(userToken);
                contextData.changeUerId(userId);
                contextData.changeLanguagePref(langPref);
                contextData.changeCompanyId(companyId);
                contextData.setCompanies(companies);

                document.cookie = "currentUser=1"

                //this.props.history.push("/");
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])

    return (
        <>{t("header.HealthCheckerTabSpellingAndContentLoading")}</>
    )
}

export default ClientPreview;