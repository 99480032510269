export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_FORMATNEW = "YYYY-MM-DD";

export const pageValuesByKey = [
    { key: "acceptAll", value: "Accepted all", IsSwitch: 0, isToolTip: 1, defaultCheck: 1 },
    { key: "disableAll", value: "Declined all", IsSwitch: 0, isToolTip: 1, defaultCheck: 1 },
    { key: "customPref", value: "Accepted a subset", IsSwitch: 0, isToolTip: 1, defaultCheck: 1 },
    { key: "pageviews", value: "Page Views", IsSwitch: 1, isToolTip: 1, defaultCheck: 1 },
    { key: "users", value: "Users", IsSwitch: 1, isToolTip: 1, defaultCheck: 1 },
    { key: "visitors", value: "Total Visitors", IsSwitch: 1, isToolTip: 1, defaultCheck: 1 },
    { key: "unique", value: "Unique", IsSwitch: 0, isToolTip: 0, defaultCheck: 1 },
    { key: "screenPageViews", value: "Page Views", IsSwitch: 1, isToolTip: 1, defaultCheck: 1 },
    { key: "totalUsers", value: "Users", IsSwitch: 1, isToolTip: 1, defaultCheck: 1 },


    { key: "SentCount", value: "Sent", IsSwitch: 1, isToolTip: 1, defaultCheck: 0, tooltip: "header.tooltip_sent" },
    { key: "DeliveredCount", value: "Delivered", IsSwitch: 1, isToolTip: 1, defaultCheck: 1, tooltip: "header.tooltip_delivered" },
    { key: "BouncedCount", value: "Bounced", IsSwitch: 1, isToolTip: 1, defaultCheck: 0, tooltip: "header.tooltip_bounced" },
    { key: "OpenCount", value: "Total Open", IsSwitch: 1, isToolTip: 1, defaultCheck: 0, tooltip: "header.tooltip_totalOpen" },
    //{key: "OpenCount", value: "Open Count",IsSwitch:1,isToolTip:1,defaultCheck:0},
    { key: "UniqueOpenSeriesData", value: "Unique Open", IsSwitch: 1, isToolTip: 1, defaultCheck: 1, tooltip: "header.tooltip_uniqueOpen" },
    { key: "ClickCount", value: "Total Click", IsSwitch: 1, isToolTip: 1, defaultCheck: 0, tooltip: "header.tooltip_totalClick" },
    { key: "UniqueClickSeriesData", value: "Unique Click", IsSwitch: 1, isToolTip: 1, defaultCheck: 1, tooltip: "header.tooltip_uniqueClick" },


    { key: "totalreturncalculator", value: "total return calculator", IsSwitch: 0, isToolTip: 0, defaultCheck: 1 },
    { key: "sharefacttable", value: "share fact table", IsSwitch: 0, isToolTip: 0, defaultCheck: 1 },

    { key: "Tool_Views", value: "Tool Views", IsSwitch: 1, isToolTip: 1, defaultCheck: 1 },
    { key: "Visitors", value: "Visitors", IsSwitch: 1, isToolTip: 1, defaultCheck: 1 },
    { key: "Unique", value: "Unique", IsSwitch: 0, isToolTip: 0, defaultCheck: 1 },
]
export class DataStatusMessage {
    static Loading = 0
    static DataAvailable = 1
    static DataUnAvailable = 2
    static ErrorOccured = 3
    static NA = 4
}

export class TF_ToolName {
    static SharePriceCenter = 1
    static SharePriceMiniChart = 2
    static CorporateAlertService = 3
    static DividendCalculator = 4
    static LargestShareholder = 5
    static RegulatoryNews = 6
    static OMXNews = 7
    static SharePriceTable = 8
    static HistoricalPriceLookup = 9
    static TotalReturnCenter = 10
    static SharePriceCalculator = 11
    static ExchangeList = 12
    static OMXFilings = 13
    static CisionFilings = 14
    static DGAPFilings = 15
    static SECFilings = 16
    static USPressRelease = 17
    static ASXAnnouncement = 18
    static ModularFinanceNews = 19
    static STTNews = 20
    static ReportsAndPresentations = 21
}

export class localStorageKeys {
    static LanguageData = "languageData"
    static EventCalendarProfileId = "EventCalendarProfileId"
    static Occupation = "Occupation"
    static Title = "Title"
    static alertState = "alertState"
    static AlertStatsDate = "AlertStatsDate"
    static alertWebsiteId = "alertWebsiteId"
    static alertSummaryYear = "alertSummaryYear"
    static yearsData = "yearsData"
    static alertSummaryData = "alertSummaryData"
    static archivedAlertsData = "archivedAlertsData"
    static SelectedDatePicker = "SelectedDatePicker"
    static IsPageRefresh = "IsPageRefresh"
    static websiteData = "websiteData"
    static profileData = "profileData"
    static WebsiteProfilesIntelConfigId = "WebsiteProfilesIntelConfigId"
    static WebsiteListWebsiteId = "WebsiteListWebsiteId"
    static siteMapData = "siteMapData"
    static EventCalendarWebsiteId = "EventCalendarWebsiteId"
    static EventCalendarProfileId = "EventCalendarProfileId"
    static eventsWebsiteData = "eventsWebsiteData"
    static PageURL = "PageURL"
    static companyId = "companyId"
    static companyName = "companyName"
    static alertSubscribers = "alertSubscribers"
    static manuageAlertId = "manuageAlertId"
    static manualAlertData = "manualAlertData"
    static firstName = "firstName"
    static lastName = "lastName"
    static userName = "userName"
    static langPref = "langPref"
    static userlangPref = "userlangPref"
    static validatelogin = "validatelogin"
    static sessiontime = "sessiontime"
    static IStoken = "IStoken"
    static countryId = "countryId"
    static company = "company"
    static country = "country"
    static airuleId = "airuleId"
    static SectionID = "SectionID"
    static webcastEventId = "webcastEventId"
    static eventsData = "eventsData"
    static PresentationId = "PresentationId"
    static presentationData = "presentationData"
    static dashboardItems = "dashboardItems"
    static dashboardIdCounter = "dashboardIdCounter"
    static sidebarContracted = "sidebarContracted"
    static serviceCenterSelectedItem = "serviceCenterSelectedItem"
    static clientsData = "clientsData"
    static reportsPage = "reportsPage"
    static reportsTypeData = "reportsTypeData"
    static token = "token"
    static userId = "userId"
    static companies = "companies"
    static email = "email"
}

export class VITableHeaders {

    static CategoryPieChartTFTable = [
        { key: "Title", shortTitle: "Top Categories", type: "string", isSortable: false, OtherType: "", showTooltip: true },
        { key: "Count", shortTitle: "Views", type: "number", isSortable: false, OtherType: "" }
    ]

    static CategoryPieChartTable = [
        { key: "Title", shortTitle: "Top Categories", type: "string", isSortable: false, OtherType: "", showTooltip: true },
        { key: "Count", shortTitle: "Page Views", type: "number", isSortable: false, OtherType: "" }
    ]

    static CountryPieChartTFTable = [
        { key: "Title", shortTitle: "Top Countries", type: "string", isSortable: false, OtherType: "", showTooltip: true },
        { key: "Count", shortTitle: "Views", type: "number", isSortable: false, OtherType: "" }
    ]

    static CountryPieChartTable = [
        { key: "Title", shortTitle: "Top Countries", type: "string", isSortable: false, OtherType: "", showTooltip: true },
        { key: "Count", shortTitle: "Page Views", type: "number", isSortable: false, OtherType: "" }
    ]

    static TrafficPieChartTFTable = [
        { key: "Title", shortTitle: "Traffic Sources", type: "string", isSortable: false, OtherType: "" },
        { key: "Count", shortTitle: "Views", type: "number", isSortable: false, OtherType: "" }
    ]

    static TrafficPieChartTable = [
        { key: "Title", shortTitle: "Traffic Sources", type: "string", isSortable: false, OtherType: "" },
        { key: "Count", shortTitle: "Page Views", type: "number", isSortable: false, OtherType: "" }
    ]

    static VisitorAllTable = [
        { key: "companyDetailsVA", shortTitle: "Company & Country", type: "string", isSortable: true, isSortAscAndDesc: true, OtherType: "LargeData" },
        { key: "categoryName", shortTitle: "Category", type: "string", isSortable: false, OtherType: "" },
        { key: "pageViews", shortTitle: "Page Views", type: "number", isSortable: true, isSortAscAndDesc: false, OtherType: "" },
        { key: "watched", shortTitle: "Watchlist", type: "number", isSortable: false, OtherType: "Checkbox" },
        { key: "", shortTitle: "Actions", isSortable: false, OtherType: "Details" }
    ]

    static VisitorAllTFTable = [
        { key: "companyDetailsVA", shortTitle: "Company & Country", type: "string", isSortable: true, isSortAscAndDesc: true, OtherType: "LargeData" },
        { key: "categoryName", shortTitle: "Category", type: "string", isSortable: false, OtherType: "" },
        { key: "pageViews", shortTitle: "Tool Views", type: "number", isSortable: true, isSortAscAndDesc: false, OtherType: "" },
        { key: "watched", shortTitle: "Watchlist", type: "number", isSortable: false, OtherType: "Checkbox" },
        { key: "", shortTitle: "Actions", isSortable: false, OtherType: "Details" }
    ]

    static PagesViewedTFTable = [
        { key: "pagesTitle", shortTitle: "Tool Title & Path", type: "string", isSortable: true, isSortAscAndDesc: true, OtherType: "LargeData" },
        { key: "avgTimeOnPage", shortTitle: "Avg. Time on Tool", type: "number", isSortable: true, isSortAscAndDesc: true, OtherType: "Seconds" },
        { key: "pagesViews", shortTitle: "Tool Views", type: "number", isSortable: true, isSortAscAndDesc: false }
    ]

    static PagesViewedTable = [
        { key: "pagesTitle", shortTitle: "Page Title & Path", type: "string", isSortable: true, isSortAscAndDesc: true, OtherType: "LargeData" },
        { key: "avgTimeOnPage", shortTitle: "Avg. Time on Page", type: "number", isSortable: true, isSortAscAndDesc: true, OtherType: "Seconds" },
        { key: "pagesViews", shortTitle: "Page Views", type: "number", isSortable: true, isSortAscAndDesc: false }
    ]

    static TrafficSourceTFTable = [
        { key: "domainNameTrafficSourcePage", shortTitle: "Top Domains", type: "string", isSortable: false, isSortAscAndDesc: false },
        { key: "pageViewsTrafficSourcePage", shortTitle: "Tool Views", type: "number", isSortable: false, isSortAscAndDesc: false }
    ]

    static TrafficSourceTable = [
        { key: "domainNameTrafficSourcePage", shortTitle: "Top Domains", type: "string", isSortable: false, isSortAscAndDesc: false },
        { key: "pageViewsTrafficSourcePage", shortTitle: "Page Views", type: "number", isSortable: false, isSortAscAndDesc: false }
    ]

    static WatchlistTFTable = [
        { key: "watchlist.cmp_name", shortTitle: "Company & Country", type: "string", isSortable: true, isSortAscAndDesc: true, OtherType: "LargeData" },
        { key: "watchlist.cat_name", shortTitle: "Category", type: "string", isSortable: false, OtherType: "" },
        { key: "watchlist.pgviews", shortTitle: "Tool Views", type: "number", isSortable: true, isSortAscAndDesc: false, OtherType: "" },
        { key: "watchlist.delete", shortTitle: "Watchlist", type: "number", isSortable: false, OtherType: "Checkbox" },
        { key: "watchlist.active", shortTitle: "Notifications", isSortable: false, OtherType: "Toggle" },
        { key: "", shortTitle: "Actions", isSortable: false, OtherType: "Details" }
    ]

    static WatchlistTable = [
        { key: "watchlist.cmp_name", shortTitle: "Company & Country", type: "string", isSortable: true, isSortAscAndDesc: true, OtherType: "LargeData" },
        { key: "watchlist.cat_name", shortTitle: "Category", type: "string", isSortable: false, OtherType: "" },
        { key: "watchlist.pgviews", shortTitle: "Page Views", type: "number", isSortable: true, isSortAscAndDesc: false, OtherType: "" },
        { key: "watchlist.delete", shortTitle: "Watchlist", type: "number", isSortable: false, OtherType: "Checkbox" },
        { key: "watchlist.active", shortTitle: "Notifications", isSortable: false, OtherType: "Toggle" },
        { key: "", shortTitle: "Actions", isSortable: false, OtherType: "Details" }
    ]

    static AllPagesTFTable = [
        { key: "pagesTitle", shortTitle: "Tool Title & Path", type: "string", isSortable: true, isSortAscAndDesc: true, OtherType: "LargeData" },
        { key: "pagesAvgTimeOnPage", shortTitle: "Avg. Time on Tool", type: "number", isSortable: true, isSortAscAndDesc: true, OtherType: "Seconds" },
        { key: "pagesPageViews", shortTitle: "Tool Views", type: "number", isSortable: true, isSortAscAndDesc: false, OtherType: "" },
        { key: "", shortTitle: "Actions", isSortable: false, OtherType: "Details" }
    ]

    static AllPagesTable = [
        { key: "pagesTitle", shortTitle: "Page Title & Path", type: "string", isSortable: true, isSortAscAndDesc: true, OtherType: "LargeData" },
        { key: "pagesAvgTimeOnPage", shortTitle: "Avg. Time on Page", type: "number", isSortable: true, isSortAscAndDesc: true, OtherType: "Seconds" },
        { key: "pagesPageViews", shortTitle: "Page Views", type: "number", isSortable: true, isSortAscAndDesc: false, OtherType: "" },
        { key: "", shortTitle: "Actions", isSortable: false, OtherType: "Details" }
    ]

    static VisitorsTFTable = [
        { key: "pageCompanyCountryTitle", shortTitle: "Company & Country", type: "string", isSortable: true, isSortAscAndDesc: true, OtherType: "LargeData" },
        { key: "pagesCategory", shortTitle: "Category", type: "string", isSortable: false, isSortAscAndDesc: false },
        { key: "pageViews", shortTitle: "Tool Views", type: "number", isSortable: true, isSortAscAndDesc: false }
    ]

    static VisitorsTable = [
        { key: "pageCompanyCountryTitle", shortTitle: "Company & Country", type: "string", isSortable: true, isSortAscAndDesc: true, OtherType: "LargeData" },
        { key: "pagesCategory", shortTitle: "Category", type: "string", isSortable: false, isSortAscAndDesc: false },
        { key: "pageViews", shortTitle: "Page Views", type: "number", isSortable: true, isSortAscAndDesc: false }
    ]
}

export class TnfTableHeaders {

    static ToolsOverviewPieChartTable = [{ key: "Pagetitle", shortTitle: "TO Page Title", type: "string", isSortable: false, OtherType: "" },
    { key: "Pageviews", shortTitle: "TO Page Views", type: "string", isSortable: false, OtherType: "" }]

    static ToolsOverviewDetailTable = [{ key: "PageTitle", shortTitle: "Page title and screen class", type: "string", isSortable: true, isSortAscAndDesc: true, OtherType: "" },
    { key: "Views", shortTitle: "Views", type: "number", isSortable: true, isSortAscAndDesc: false, OtherType: "number" },
    { key: "Users", shortTitle: "Unique Users", type: "number", isSortable: true, isSortAscAndDesc: true, OtherType: "number" },
    { key: "ViewsPerUser", shortTitle: "Views per user", type: "number", isSortable: true, isSortAscAndDesc: true, OtherType: "number" },
    { key: "AverageEngagementTime", shortTitle: "Average engagement time", type: "time", isSortable: true, isSortAscAndDesc: true, OtherType: "time" },
    { key: "EventCountAllEvents", shortTitle: "Event count all events", type: "number", isSortable: true, isSortAscAndDesc: true, OtherType: "number" }]

    static MapUserTable = [{ key: "Country", shortTitle: "Country", type: "string", isSortable: false, OtherType: "" },
    { key: "Count", shortTitle: "Country Event Count", type: "number", isSortable: false, OtherType: "" }]

    static MapUserTableForTools = [{ key: "Country", shortTitle: "Country", type: "string", isSortable: false, OtherType: "" },
    { key: "Count", shortTitle: "Country Event Count As Views", type: "number", isSortable: false, OtherType: "" }]

    static CustomEventDateSelector = [{ key: "Label", shortTitle: "Custom Parameter", type: "string", isSortable: false, OtherType: "ReplaceTextData" },
    { key: "EventCount", shortTitle: "Event Count", type: "number", isSortable: false, OtherType: "" },
    { key: "TotalUsers", shortTitle: "Total Users", type: "number", isSortable: false, OtherType: "" }]

    static CustomEventSearchOptions = [{ key: "Label", shortTitle: "Custom Parameter", type: "string", isSortable: false, OtherType: "" },
    { key: "EventCount", shortTitle: "Event Count", type: "number", isSortable: false, OtherType: "" },
    { key: "TotalUsers", shortTitle: "Total Users", type: "number", isSortable: false, OtherType: "" }]

    static CustomEventSubmitButtonClick = [{ key: "Label", shortTitle: "Custom Parameter", type: "string", isSortable: false, OtherType: "" },
    { key: "EventCount", shortTitle: "Event Count", type: "number", isSortable: false, OtherType: "" },
    { key: "TotalUsers", shortTitle: "Total Users", type: "number", isSortable: false, OtherType: "" }]

    static CustomEventServiceSubscribers = [{ key: "Label", shortTitle: "Custom Parameter", type: "string", isSortable: false, OtherType: "" },
    { key: "EventCount", shortTitle: "Event Count", type: "number", isSortable: false, OtherType: "" },
    { key: "TotalUsers", shortTitle: "Total Users", type: "number", isSortable: false, OtherType: "" }]

    static NotificationTable = [
        { key: "companyCountryName", shortTitle: "Company & Country", type: "string", isSortable: false, isSortAscAndDesc: false, OtherType: "LargeData" },
        { key: "notifications.Active", shortTitle: "Status", type: "Toggle", isSortable: false, isSortAscAndDesc: false, OtherType: "Toggle" },
        { key: "notifications.Ismailsent", shortTitle: "Sent", type: "number", isSortable: false, isSortAscAndDesc: false },
        { key: "notifications.Edit", shortTitle: "Edit", type: "string", isSortable: false, isSortAscAndDesc: false },
        { key: "notifications.History", shortTitle: "History", type: "string", isSortable: false, isSortAscAndDesc: false },
        { key: "notifications.Delete", shortTitle: "Delete", type: "string", isSortable: false, isSortAscAndDesc: false }
    ]

    static customEventsContent = {
        "3year": "3 years",
        "1year": "1 year",
        "all": "All",
        "custom": "Custom",
        "6month": "6 months",
        "1month": "1 month",
        "today": "Today",
        "3month": "3 months"
    };

}


export class WebcastHeaders {
    static ViewerDomainTable = [
        { key: "title", shortTitle: "Top Domains", type: "string", isSortable: false, isSortAscAndDesc: false, showTooltip: true },
        { key: "TotalOpenCount", shortTitle: "Unique viewers", type: "number", isSortable: false, isSortAscAndDesc: false },
    ];

    static ViewersTable = [
        { key: "nameCompany", shortTitle: "Name and company", type: "string", isSortable: true, isSortAscAndDesc: true, OtherType: "LargeData", notClickable: true },
        { key: "email", shortTitle: "Viewer email", type: "string", isSortable: true, isSortAscAndDesc: true, notClickable: true },
        { key: "totalViewerDuration", shortTitle: "Total viewer duration", type: "string", isSortable: false, isSortAscAndDesc: false, notClickable: true },
        { key: "", shortTitle: "", type: "string", isSortable: false, isSortAscAndDesc: false, OtherType: "MoreDetails" },
    ];

    static ViewersV1Table = [
        { key: "nameCompany", shortTitle: "Name and company", type: "string", isSortable: true, isSortAscAndDesc: true, OtherType: "LargeData", notClickable: true },
        { key: "email", shortTitle: "Viewer email", type: "string", isSortable: true, isSortAscAndDesc: true, notClickable: true },
        { key: "viewedDate", shortTitle: "Viewed date", type: "date", isSortable: true, isSortAscAndDesc: false, notClickable: true },
        { key: "connectTime", shortTitle: "Connect Time", type: "string", isSortable: true, isSortAscAndDesc: true, notClickable: true },
    ];

    static ViewersChildTable = [
        { key: "status", shortTitle: "Event status", type: "string", isSortable: false, isSortAscAndDesc: false, notClickable: true },
        { key: "viewedDate", shortTitle: "Viewed date", type: "string", isSortable: false, isSortAscAndDesc: false, notClickable: true },
        { key: "connectTime", shortTitle: "Connect time", type: "string", isSortable: false, isSortAscAndDesc: false, notClickable: true },
        { key: "disconnectTime", shortTitle: "Disconnect time", type: "string", isSortable: false, isSortAscAndDesc: false, notClickable: true },
        { key: "viewDuration", shortTitle: "View duration", type: "string", isSortable: false, isSortAscAndDesc: false, notClickable: true },
    ];

    static QuestionsVETable = [
        { key: "Email", shortTitle: "Viewer", type: "string", isSortable: true, isSortAscAndDesc: true, OtherType: "LargeData", notClickable: true },
        { key: "Question", shortTitle: "Questions", type: "string", isSortable: true, isSortAscAndDesc: true, notClickable: true },
        { key: "QuestionTime", shortTitle: "Time", type: "string", isSortable: true, isSortAscAndDesc: false, OtherType: "DateTime", notClickable: true },
        { key: "QuestionApprovedFor", shortTitle: "Publish status", type: "string", isSortable: true, isSortAscAndDesc: true, OtherType: "Dropdown" },
    ];

    static QuestionsILTable = [
        { key: "Email", shortTitle: "Viewer", type: "string", isSortable: true, isSortAscAndDesc: true, OtherType: "LargeData", notClickable: true },
        { key: "Question", shortTitle: "Questions", type: "string", isSortable: true, isSortAscAndDesc: true, notClickable: true },
        { key: "QuestionTime", shortTitle: "Time", type: "string", isSortable: true, isSortAscAndDesc: false, OtherType: "DateTime", notClickable: true },
    ];

    static ManageUserILTable = [
        { key: "email", shortTitle: "Email", type: "string", isSortable: true, isSortAscAndDesc: true, notClickable: true },
        { key: "name", shortTitle: "Name & Job Title", type: "string", isSortable: true, isSortAscAndDesc: true, OtherType: "LargeData", notClickable: true },
        { key: "company", shortTitle: "Company", type: "string", isSortable: true, isSortAscAndDesc: true, notClickable: true },
        { key: "metadata", shortTitle: "Additional Information", type: "string", isSortable: false, isSortAscAndDesc: false, OtherType: "LargeData", notClickable: true },
        { key: "registrationDate", shortTitle: "Registration Date", type: "date", isSortable: true, isSortAscAndDesc: false, OtherType: "DateOnly", notClickable: true },
        { key: "emailVarificationDone", shortTitle: "Email Verification Done", type: "string", isSortable: true, isSortAscAndDesc: true, notClickable: true },
    ];

    static ManageUserVETable = [
        { key: "email", shortTitle: "Email", type: "string", isSortable: true, isSortAscAndDesc: true, notClickable: true },
        { key: "nameJobTitle", shortTitle: "Name & Job Title", type: "string", isSortable: true, isSortAscAndDesc: true, OtherType: "LargeData", notClickable: true },
        { key: "company", shortTitle: "Company", type: "string", isSortable: true, isSortAscAndDesc: true, notClickable: true },
        { key: "metadata", shortTitle: "Additional Information", type: "string", isSortable: false, isSortAscAndDesc: false, OtherType: "LargeData", notClickable: true },
        { key: "registrationDate", shortTitle: "Registration Date", type: "date", isSortable: true, isSortAscAndDesc: false, OtherType: "DateOnly", notClickable: true },
        { key: "eventAccessStatus", shortTitle: "Status", type: "string", isSortable: true, isSortAscAndDesc: true, notClickable: true },
        { key: "", shortTitle: "Action", type: "string", isSortable: false, OtherType: "ManageUserActions", isSortAscAndDesc: false },
    ];

    static questionStatusDDL = [
        { id: 2, statusName: "Pending" },
        { id: 3, statusName: "Approved for All" },
        { id: 4, statusName: "Approved for Presenter" }
    ];
}

export class TooltipForRowValues {
    static TitleAndTooltip = [
        { "key": "Direct", "tooltip": "header.InsightsTrafficSourceDirectHelpTooltipText" },
        { "key": "Organic", "tooltip": "header.InsightsTrafficSourceOrganicHelpTooltipText" },
        { "key": "Social", "tooltip": "header.InsightsTrafficSourceSocialHelpTooltipText" },
        { "key": "Referral", "tooltip": "header.InsightsTrafficSourceReferralHelpTooltipText" },
        { "key": "Email", "tooltip": "header.InsightsTrafficSourceEmailHelpTooltipText" },
        { "key": "PaidSearch", "tooltip": "header.InsightsTrafficSourcePaidSearchHelpTooltipText" }
    ]
}

export class TooltipForHeaderLabels {
    static LableAndTooltip = [
        { labelName: "Top Categories", tooltipText: "header.InsightsTopCategoryHelpText" },
        { labelName: "Top Countries", tooltipText: "header.InsightsTopCountryHelpText" },
        { labelName: "Top Domains", tooltipText: "header.topDomainsTooltipText" },
    ];
}