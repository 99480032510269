import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Card, Button, OverlayTrigger, Col, Row, FormCheck, Form, InputGroup, Alert, Popover, Image } from "@themesberg/react-bootstrap";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faPlusCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import request from '../../apis/request';
import { METHODS, baseURL } from '../../apis/utilities/constant';
import LoaderImage from "../../assets/img/LoaderGraphic.svg";
import { ERROR_TIMEOUT } from "../../AppConfig";
import { convertTranslatinString } from "../../utils/common"
import { decryptData } from '../../utils/crypto';
import { TnfTableHeaders, DataStatusMessage } from "../../utils/constant";

import ChartRendererTable from '../../components/ChartRendererTable'
import ModalComponent from "../../components/VoltModal";
import DashboardItem from '../../components/DashboardItem';
import AppContext from "../../components/AppContext";
import CommonHeader from '../../components/CommonHeader';
import Search from "../../components/Search";
import ButtonComponent from "../../components/Buttons";
import DropdownWithIconsComponent from "../../components/DropdownWithIcons";
import DataTableRenderer from "../../components/DataTableRenderer.js";
import QuestionModal from "../../components/QuestionModel";

export const NotificationPage = () => {
    const [t] = useTranslation();
    const contextData = useContext(AppContext);

    const [websiteList, setWebsiteList] = useState([]);
    const [profilesList, setProfilesList] = useState([]);

    const [selectedWebsiteIdNew, setSelectedWebsiteIdNew] = useState(websiteList[0]);
    const [selectedProfileIdNew, setSelectedProfileIdNew] = useState({});
    const [autoLoad, setAutoLoad] = useState(false);

    const [notificationResponse, setNotificationResponse] = useState(null);
    const [notification, setNotification] = useState({ transactionStatus: DataStatusMessage.Loading });

    const [showHistoryPopup, setShowHistoryPopup] = useState(false);
    const [historyApiUrl, setHistoryApiUrl] = useState({});

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteModalContent, setDeleteModalContent] = useState({});
    const [deleteNotificationData, setDeleteNotificationData] = useState({});

    const [notificationAddEditData, setNotificationAddEditData] = useState({
        operation: '', companyid: 0, companyname: '', notificationname: '', threshold: '', country: '', countrydata: '', companyurl: '', moreurls: [], startdate: null, enddate: null, durationId: 0,
        companynameisvalid: true, notificationnameisvalid: true, thresholdisvalid: true, startdateisvalid: true, enddateisvalid: true, durationidisvalid: true
    });
    const [showAddEditPopup, setShowAddEditPopup] = useState(false);
    const [addEditFormErrors, setAddEditFormErrors] = useState("");

    const [searchData, setSearchData] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [successMessage, setSuccessMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [showAutoFillForCompanyPopup, setAutoFillForCompanyPopup] = useState(false);
    const [notificationEditData, setNotificationEditData] = useState({});
    const [duration, setDuration] = useState([]);
    const [temList, settemList] = useState([]);
    const [dListCountry, setDListCountry] = useState([]);
    const [showAutoFillForCompany, setAutoFillForCompany] = useState(false);

    useEffect(() => {
        if (contextData.companyId > 0) {

            setAutoLoad(false);
            if (localStorage.getItem("TnfWebsiteData") && JSON.parse(localStorage.getItem("TnfWebsiteData")).length > 0
                && localStorage.getItem("TnfAllProfileData") && JSON.parse(localStorage.getItem("TnfAllProfileData")).length > 0) {

                let websiteList = JSON.parse(localStorage.getItem("TnfWebsiteData"));
                let selectedWebsite = JSON.parse(localStorage.getItem("TnfWebsiteListWebsiteId"));
                renderWebsite(websiteList, selectedWebsite)

            } else if (!localStorage.getItem("TnfWebsiteData") || !localStorage.getItem("TnfAllProfileData")
                || JSON.parse(localStorage.getItem("TnfWebsiteData")).length == 0 || JSON.parse(localStorage.getItem("TnfAllProfileData")).length == 0) {

                if (!localStorage.getItem("TnfWebsiteData") || JSON.parse(localStorage.getItem("TnfWebsiteData")).length == 0) {
                    getWebsites();
                }
                else if (!localStorage.getItem("TnfAllProfileData") || JSON.parse(localStorage.getItem("TnfAllProfileData")).length == 0) {
                    getProfiles(JSON.parse(localStorage.getItem("TnfWebsiteData")));
                }
            }
        }
    }, [contextData.companyId]);

    useEffect(() => {
        if (contextData.selectedProfile["WebsiteProfiles.ProfileId"] && contextData.selectedProfile["WebsiteProfiles.IntelConfigId"] && contextData.companyId !== 0) {
            setTnFNotificationData();
        }
    }, [contextData.selectedProfile]);

    const setTnFNotificationData = () => {
        setShowLoader(true);
        request(`${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetNotification?profileId=${contextData.selectedProfile["WebsiteProfiles.ProfileId"]}&packageid=5&companyid=${contextData.companyId}&intellconfigid=${contextData.selectedProfile["WebsiteProfiles.IntelConfigId"]}&isTF=1`,
            METHODS.GET, {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
                "content-type": "application/json"
            })
            .then((response) => {
                if (response) {
                    setNotificationResponse(response);
                    setNotification({ header: TnfTableHeaders.NotificationTable, transactionStatus: DataStatusMessage.DataAvailable, result: response.data });
                    setSearchData("");
                    setShowLoader(false);
                }
            }).catch(() => {
                setNotification({ result: [], transactionStatus: DataStatusMessage.DataUnAvailable })
            })
    };

    useEffect(() => {
        if (notificationResponse) {
            setNotification(prevState => ({
                ...prevState,
                result: searchContent(notificationResponse)
            }));
        }
    }, [searchData]);

    const searchContent = (objSearch) => {
        let filteredResult = [];
        if (searchData) {
            if (objSearch.filterColumns && objSearch.filterColumns.length > 0) {
                const filtercolumns = objSearch.filterColumns;
                let filterfs = {};
                for (let i = 0; i < filtercolumns.length; i++) {
                    filterfs[filtercolumns[i]] = searchData;
                }
                let result = objSearch.data;
                filteredResult = result.filter(function (item) {
                    for (var key in filterfs) {
                        if (item[key]?.toLowerCase().includes(filterfs[key]?.toLowerCase())) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                });
            }
        }
        else {
            filteredResult = objSearch.data.slice();
        }
        return filteredResult;
    };

    const getWebsites = () => {
        request(baseURL + `/TnfAnalytics/GetTnFChannelWebsiteByClientUser?CompanyId=${contextData.companyId}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
                let resultData = [];

                if (res.result && res.result.length > 0) {
                    resultData = res.result.map((e) => ({ cmsplatformid: res.platformid, ["WebsiteList.WebsiteName"]: e.WebsiteName, ["WebsiteList.WebsiteId"]: e.WebsiteId, ["WebsiteList.WebsiteUrl"]: e.WebsiteUrl }))
                }

                if (resultData.length > 0) {
                    localStorage.setItem("TnfWebsiteData", JSON.stringify(resultData))
                    getProfiles(resultData)
                }
                else {
                    localStorage.setItem("TnfWebsiteData", JSON.stringify([]))
                    localStorage.setItem("TnfAllProfileData", JSON.stringify([]))
                }
            })
            .catch((err) => {
            })
    };

    const getProfiles = (resultData) => {
        request(baseURL + `/TnfAnalytics/GetTnFWebsites?CompanyId=${contextData.companyId}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
                if (res.result != null && res.result.length > 0) {
                    let result = removeDuplicateObjects(res.result, 'IntelConfigId')
                    localStorage.setItem("TnfAllProfileData", JSON.stringify(result))

                } else {
                    localStorage.setItem("TnfAllProfileData", JSON.stringify([]))
                }
                if (!localStorage.getItem("TnfWebsiteListWebsiteId")) {
                    renderWebsite(resultData, resultData[0])
                }
                else {
                    renderWebsite(resultData, JSON.parse(localStorage.getItem("TnfWebsiteListWebsiteId")))
                }
                if (res.result.length == 0) {
                    setAutoLoad(true);
                }
            })
            .catch((err) => {
            })
    };

    const renderWebsite = (resultSet, selectedWebsiteValue) => {
        setWebsiteList(resultSet)
        setSelectedWebsiteIdNew(selectedWebsiteValue);
        renderProfileList(selectedWebsiteValue, 0);
    };

    const renderProfileList = (selectedWebsiteValue, isTempUpdate) => {
        if (localStorage.getItem("TnfAllProfileData") && JSON.parse(localStorage.getItem("TnfAllProfileData")).length > 0) {

            let profileList = JSON.parse(localStorage.getItem("TnfAllProfileData"));
            let selectedWebsiteProfiles = profileList.filter((e) => { return e.WebsiteId === selectedWebsiteValue["WebsiteList.WebsiteId"] });

            if (selectedWebsiteProfiles.length > 0) {
                let profileData = selectedWebsiteProfiles.map((e) => ({ ["WebsiteProfiles.ProfileId"]: e.ProfileId, ["WebsiteProfiles.DisplayName"]: e.DisplayName, ["WebsiteProfiles.IntelConfigId"]: e.IntelConfigId }));
                setProfilesList(profileData)
                let selectedProfile = profileData[0];
                if (isTempUpdate === 0) {
                    let storedProfile = JSON.parse(localStorage.getItem("TnfWebsiteProfilesIntelConfigId"));
                    if (storedProfile && Object.keys(storedProfile).length > 0
                        && profileData.some(obj => obj["WebsiteProfiles.ProfileId"] === storedProfile["WebsiteProfiles.ProfileId"])) {
                        selectedProfile = storedProfile;
                    };
                    localStorage.setItem("TnfProfileData", JSON.stringify(profileData))
                }
                setSelectedProfileIdNew(selectedProfile);
            }
            else {
                setProfilesList([])
                setSelectedProfileIdNew({});
                if (isTempUpdate === 0) {
                    localStorage.setItem("TnfProfileData", JSON.stringify([]))
                }
            }
        }
        setAutoLoad(true)
    };

    const onWebsiteDropdownChange = (data) => {
        setSelectedWebsiteIdNew(data);
        renderProfileList(data, 1)
    };

    const onProfileDropdownChange = (data) => {
        const intellConfigId = data["WebsiteProfiles.IntelConfigId"];
        contextData.changeIntelConfigId(intellConfigId);
        setSelectedProfileIdNew(data)
    };

    const applyFilters = () => {
        const websiteId = selectedWebsiteIdNew["WebsiteList.WebsiteId"];

        contextData.changeWebsiteId(websiteId);
        contextData.changeSelectedWebsite(selectedWebsiteIdNew);

        if (JSON.parse(localStorage.getItem("TnfAllProfileData")).length > 0) {
            contextData.changeSelectedProfile(selectedProfileIdNew);
        }
        if (profilesList.length > 0) {
            localStorage.setItem("TnfProfileData", JSON.stringify(profilesList))
        }
        else {
            localStorage.setItem("TnfProfileData", JSON.stringify([]))
        }
        localStorage.setItem("TnfWebsiteListWebsiteId", JSON.stringify(selectedWebsiteIdNew));// Adding WebsiteList.WebsiteId to local stoarge in case of Page Refresh
        localStorage.setItem("TnfWebsiteProfilesIntelConfigId", JSON.stringify(selectedProfileIdNew));// Adding WebsiteProfiles.IntelConfigId to local stoarge in case of Page Refresh
    };

    const cancelFilters = () => {
        onWebsiteDropdownChange(JSON.parse(localStorage.getItem("TnfWebsiteListWebsiteId")))
        onProfileDropdownChange(JSON.parse(localStorage.getItem("TnfWebsiteProfilesIntelConfigId")))
    };

    const removeDuplicateObjects = (array, key) => {
        const seen = {};
        return array.filter(item => {
            const itemKey = key ? item[key] : item;
            return seen.hasOwnProperty(itemKey) ? false : (seen[itemKey] = true);
        });
    };

    const openAddNotification = () => {
        setNotificationAddEditData({
            operation: 'add', companyid: 0, companyname: '', country: '', notificationname: '', threshold: '', companyurl: '', moreurls: [], startdate: null, enddate: null, durationId: 4,
            companynameisvalid: true, notificationnameisvalid: true, thresholdisvalid: true, startdateisvalid: true, enddateisvalid: true, durationisvalid: true
        });
        if (duration.length == 0) {
            request(
                `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetNotificationDuration?packageid=5`,
                METHODS.GET,
                {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                }
            ).then((response) => {
                if (response?.resultData) {
                    setDuration(response.resultData);
                    setAddEditFormErrors("");
                    setShowAddEditPopup(true);
                    setDListCountry([]);
                }
            });
        }
        else {
            setAddEditFormErrors("");
            setShowAddEditPopup(true);
            setDListCountry([]);
        }
    };

    const closeAddEditPopup = () => {
        setShowAddEditPopup(false);
    };

    const validStartDate = (current) => {
        let yesterday = moment().subtract(1, 'day');;
        return current.isAfter(yesterday);
    };

    const validEndDate = (current) => {
        let yesterday = moment().subtract(1, 'day');;
        return current.isAfter(yesterday);
    };

    const changeStartDate = (current) => {
        if (addEditFormErrors == t("header.pleaseSelectDateRange") || (addEditFormErrors == t("header.dateValidationErrorNew") && moment(notificationAddEditData.enddate) > moment(current))) {
            setAddEditFormErrors("");
        }
        setNotificationAddEditData(prevState => ({
            ...prevState,
            startdate: current
        }));
    };

    const changeEndDate = (current) => {
        if (addEditFormErrors == t("header.pleaseSelectDateRange") || (addEditFormErrors == t("header.dateValidationErrorNew") && moment(current) > moment(notificationAddEditData.startdate))) {
            setAddEditFormErrors("");
        }
        setNotificationAddEditData(prevState => ({
            ...prevState,
            enddate: current
        }));
    };

    const validateFormElement = (e) => {
        e.preventDefault();
        let iserror = 0;
        if (notificationAddEditData.companyname.trim().length == 0) {
            setNotificationAddEditData(prevState => ({
                ...prevState,
                companynameisvalid: false
            }));
            iserror = 1;
        }
        if (notificationAddEditData.notificationname.trim().length == 0) {
            setNotificationAddEditData(prevState => ({
                ...prevState,
                notificationnameisvalid: false
            }));
            iserror = 1;
        }
        if (notificationAddEditData.threshold.trim().length == 0 || notificationAddEditData.threshold.trim() == "0" + t("header.Notification_Setting_or_more")) {
            setNotificationAddEditData(prevState => ({
                ...prevState,
                thresholdisvalid: false
            }));
            iserror = 1;
        }
        if (notificationAddEditData.durationId == 3) {

            if (notificationAddEditData.startdate === null) {
                setAddEditFormErrors(t("header.pleaseSelectDateRange"))
                setNotificationAddEditData(prevState => ({
                    ...prevState,
                    startdateisvalid: false
                }));
                iserror = 1;
            }
            if (notificationAddEditData.enddate === null) {
                setAddEditFormErrors(t("header.pleaseSelectDateRange"))
                setNotificationAddEditData(prevState => ({
                    ...prevState,
                    enddateisvalid: false
                }));
                iserror = 1;
            }
            if (moment(notificationAddEditData.enddate) < moment(notificationAddEditData.startdate)) {
                setAddEditFormErrors(t("header.dateValidationErrorNew"))
                setNotificationAddEditData(prevState => ({
                    ...prevState,
                    enddateisvalid: false
                }));
                iserror = 1;
            }
        }
        if (iserror == 0) {
            onAddEditSave();
        }
    };

    const onFormElementsChange = (event) => {
        const { name, value, id, checked } = event.target;
        const forms = { ...notificationAddEditData };
        let formVlue;
        if (name == "durationId") {
            formVlue = id;
        }
        else if (name === "confirm" || name === "toggle")
            formVlue = checked;
        else if (name === "threshold") {
            formVlue = value.replace(/\D/g, "");

            if (formVlue == "0" || formVlue.trim().length == 0) {
                forms['thresholdisvalid'] = false;
            }
            else {
                forms['thresholdisvalid'] = true;
            }
        }
        else if (name === "companyname") {
            formVlue = value;
            onTextChanged(value);
        }
        else if (name == "notificationname") {
            formVlue = value.replace(/[&+()$~%'":;<>`]/g, '');
        }
        else
            formVlue = value;

        forms[name] = formVlue;
        if (formVlue.trim().length > 0 && name !== "threshold") {
            forms[name + 'isvalid'] = true;
        }
        else if (name !== "threshold") {
            forms[name + 'isvalid'] = false;
        }
        setNotificationAddEditData(forms);
    };

    const onTextChanged = (value) => {
        const showAutoFill = value.trim().length > 2 ? true : false;
        setNotificationAddEditData(prevState => ({
            ...prevState,
            companyname: value
        }));
        if (showAutoFill) {
            request(
                baseURL + `/WebsiteAnalytics/SearchCompanyListByName?searchtext=` + value.trim(),
                METHODS.GET,
                {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                }
            ).then((response) => {
                if (response.resultData !== null) {
                    settemList(response.resultData)
                }
                else {
                    settemList([])
                }
                setAutoFillForCompany(showAutoFill);
            });
        }
        else {
            setAutoFillForCompany(showAutoFill);
        }
    };

    const suggestionSelectedValue = (value) => {
        setNotificationAddEditData(prevState => ({
            ...prevState,
            companyname: value.companyName,
            companyid: value.companyId,
            notificationname: value.companyName,
            notificationnameisvalid: true
        }));

        request(
            baseURL + `/WebsiteAnalytics/GetCountryByVisitor?visitorid=` + value.companyId,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            }
        ).then((response) => {
            if (response.resultData.length > 0) {
                setDListCountry(response.resultData)
                setNotificationAddEditData(prevState => ({
                    ...prevState,
                    countrydata: response.resultData[0]
                }));
            }
        });
        let curl = "";
        let moreurls = [];
        request(
            baseURL + `/WebsiteAnalytics/GetIntelligenceDomainByVisitor?visitorid=` + value.companyId + `&countryid=` + 0,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            }
        ).then((response) => {
            if (response?.resultData?.length > 0) {

                for (let i = 0; i < response.resultData.length; i++) {
                    if (response.resultData[i].domainUrl != "" && curl == "") {

                        curl = response.resultData[i].domainUrl;
                    }
                    else if (response.resultData[i].domainUrl != "") {

                        moreurls.push(response.resultData[i].domainUrl)
                    }
                    else if (i == 0 && response.resultData.length == 1) {

                        curl = "-"
                    }
                }
            }
            setNotificationAddEditData(prevState => ({
                ...prevState,
                companyurl: curl,
                moreurls: moreurls
            }));
        });
        setAutoFillForCompany(false);
    };

    const onCountryChange = (data) => {
        setNotificationAddEditData(prevState => ({
            ...prevState,
            countrydata: data
        }));

        let curl = "";
        let moreurls = [];
        request(
            baseURL + `/WebsiteAnalytics/GetIntelligenceDomainByVisitor?visitorid=` + notificationAddEditData.companyid + `&countryid=` + data.countryId,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            }
        ).then((response) => {
            if (response?.resultData?.length > 0) {
                for (let i = 0; i < response.resultData.length; i++) {
                    if (response.resultData[i].domainUrl != "" && curl == "") {

                        curl = response.resultData[i].domainUrl;
                    }
                    else if (response.resultData[i].domainUrl != "") {

                        moreurls.push(response.resultData[i].domainUrl)
                    }
                    else if (i == 0 && response.resultData.length == 1) {

                        curl = "-"
                    }
                }
            }
            setNotificationAddEditData(prevState => ({
                ...prevState,
                companyurl: curl,
                moreurls: moreurls
            }));
        });
    };

    const onCloseSuccessMessage = () => {
        setSuccessMessage(undefined)
    };

    const onCloseErrorMessage = () => {
        setErrorMessage(undefined)
    };

    const companyUrlClick = (event, url) => {
        event.preventDefault();
        window.open(url, "_blank");
    };

    const rowClickEvent = (event, data, eventFor) => {
        if (eventFor === "status") {
            setNotificationStatus(event, data);
        }
        else if (eventFor === "edit") {
            editNotification(data);
        }
        else if (eventFor === "history") {
            showHistory(data);
        }
        else if (eventFor === "delete") {
            deleteNotificationPopup(data);
        }
    };

    const setNotificationStatus = (event, data) => {
        const { checked } = event.target;
        let notificationd = {}
        notificationd = {
            CompanyId: data.companyId,
            CountryId: data.countryId,
            DurationId: data.durationId,
            EmailAlert: 1,
            FromDate: data.fromDate,
            ToDate: data.toDate,
            IntellConfigId: data.intellConfigId,
            IsActive: checked,
            NotificationName: data.notificationName,
            PageViewsCount: data.pageViewsCount,
            RuleId: data.ruleId,
            SMSAlert: 0
        }
        setShowLoader(true);
        request(
            baseURL + `/WebsiteAnalytics/SetNotification?companyid=` + contextData.companyId + `&packageid=` + 5 + `&isTF=` + 1,
            METHODS.POST,
            notificationd,
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            }
        ).then((response) => {
            if (response?.resultData && response.resultData?.ruleId > 0) {
                setSuccessMessage(t("header.Message_" + convertTranslatinString(response.resultData.message)));
                setTimeout(() => {
                    setSuccessMessage(undefined);
                }, ERROR_TIMEOUT);
                setTnFNotificationData();
            }
            else {
                setErrorMessage(t("header.Something_went_wrong"));
                setTimeout(() => {
                    setErrorMessage(undefined);
                }, ERROR_TIMEOUT);
            }
            setShowLoader(false);
        }).catch((error) => {
            setErrorMessage(t("header.Something_went_wrong"));
            setTimeout(() => {
                setErrorMessage(undefined);
            }, ERROR_TIMEOUT);
            setShowLoader(false);
        });
    };

    const editNotification = (data) => {
        setNotificationEditData(data);
        let curl = "";
        let moreurls = [];
        request(
            baseURL + `/WebsiteAnalytics/GetIntelligenceDomainByVisitor?visitorid=` + data.companyId + `&countryid=0`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            }
        ).then((response) => {
            if (response?.resultData?.length > 0) {
                for (let i = 0; i < response.resultData.length; i++) {
                    if (response.resultData[i].domainUrl != "" && curl == "") {
                        curl = response.resultData[i].domainUrl;
                    }
                    else if (response.resultData[i].domainUrl != "") {
                        moreurls.push(response.resultData[i].domainUrl)
                    }
                    else if (i == 0 && response.resultData.length == 1) {
                        curl = "-"
                    }
                }
            }
            setNotificationAddEditData(prevState => ({
                ...prevState,
                notificationname: data.notificationName,
                companyurl: curl,
                moreurls: moreurls,
                threshold: data.pageViewsCount > 0 ? data.pageViewsCount + t("header.Notification_Setting_or_more") : "",
                duration: data.timeframe,
                companyname: data.visitorName,
                country: data.visitorCountryName,
                operation: 'edit',
                thresholdisvalid: true,
                notificationnameisvalid: true,
                startdateisvalid: true,
                enddateisvalid: true,
                startdate: data.durationId == 3 ? data.fromDate : null,
                enddate: data.durationId == 3 ? data.toDate : null,
                durationId: data.durationId
            }));

            if (duration.length === 0) {
                request(
                    `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetNotificationDuration?packageid=5`,
                    METHODS.GET,
                    {},
                    {
                        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                    }
                ).then((response) => {
                    if (response?.resultData) {
                        setDuration(response.resultData);
                        setAddEditFormErrors("");
                        setShowAddEditPopup(true);
                    }
                });
            }
            else {
                setShowAddEditPopup(true);
                setAddEditFormErrors("");
            }
        });
    };

    const showHistory = (data) => {
        setHistoryApiUrl
            (
                {
                    url: `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetNotificationAuditTrail?intellconfigid=` + data.intellConfigId + `&ruleid=` + data.ruleId + `&packageid=5&isTF=1`, type: 'get', count: 0
                }
            )
        setShowHistoryPopup(true);
    };

    const closeHistoryPopup = () => {
        setShowHistoryPopup(false);
    };

    const deleteNotificationPopup = (row) => {
        setShowDeleteModal(true);
        setDeleteModalContent({ type: "Notification", title: "header.NotificationDeleteTitle", content: "header.NotificationDeleteNote" })
        const data = {
            CompanyId: row.companyId,
            CountryId: row.countryId,
            DurationId: row.durationId,
            EmailAlert: 1,
            FromDate: row.fromDate,
            ToDate: row.toDate,
            IntellConfigId: row.intellConfigId,
            IsActive: row["notifications.Active"],
            NotificationName: row.notificationName,
            PageViewsCount: row.pageViewsCount,
            RuleId: row.ruleId,
            SMSAlert: 0
        }
        setDeleteNotificationData(data)
    };

    const onDeleteSuccessModelClose = () => {
        setShowDeleteModal(false);
        deleteNotification();
    };

    const onDeleteModalClose = () => {
        setShowDeleteModal(false);
    };

    const deleteNotification = () => {
        setShowLoader(true);
        request(
            baseURL + `/VisitorAnalytics/DeleteNotification?intelconfigid=` + deleteNotificationData.IntellConfigId + `&isTF=1`,
            METHODS.POST,
            deleteNotificationData,
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            }
        ).then((response) => {
            if (response && response.resultData.ruleId > 0) {
                setSuccessMessage(t("header.Message_Notification_deleted_successfully"));
                setTimeout(() => {
                    setSuccessMessage(undefined);
                }, ERROR_TIMEOUT);
                setTnFNotificationData();
            }
            else {
                setErrorMessage(t("header.Something_went_wrong"));
                setTimeout(() => {
                    setErrorMessage(undefined);
                }, ERROR_TIMEOUT);
            }
            setShowLoader(false);
        }).catch((error) => {
            setErrorMessage(t("header.Something_went_wrong"));
            setTimeout(() => {
                setErrorMessage(undefined);
            }, ERROR_TIMEOUT);
            setShowLoader(false);
        });
    };

    const onAddEditSave = () => {
        setShowLoader(true);
        let notificationd = {}
        if (notificationAddEditData.operation == "edit") {
            notificationd = {
                CompanyId: notificationEditData.companyId,
                CountryId: notificationEditData.countryId,
                DurationId: notificationAddEditData.durationId,
                EmailAlert: 1,
                FromDate: notificationAddEditData.durationId == 3 ? notificationAddEditData.startdate : "0001-01-01T00:00:00",
                ToDate: notificationAddEditData.durationId == 3 ? notificationAddEditData.enddate : "0001-01-01T00:00:00",
                IntellConfigId: notificationEditData.intellConfigId,
                IsActive: notificationEditData['notifications.Active'],
                NotificationName: notificationAddEditData.notificationname,
                PageViewsCount: notificationAddEditData.threshold.replace(t("header.Notification_Setting_or_more"), ""),
                RuleId: notificationEditData.ruleId,
                SMSAlert: 0
            }
        }
        else {
            notificationd = {
                CompanyId: notificationAddEditData.companyid,
                CountryId: notificationAddEditData.countrydata?.countryId,
                DurationId: notificationAddEditData.durationId,
                EmailAlert: 1,
                FromDate: notificationAddEditData.durationId == 3 ? notificationAddEditData.startdate : "0001-01-01T00:00:00",
                ToDate: notificationAddEditData.durationId == 3 ? notificationAddEditData.enddate : "0001-01-01T00:00:00",
                IntellConfigId: JSON.parse(localStorage.getItem("TnfWebsiteProfilesIntelConfigId"))["WebsiteProfiles.IntelConfigId"],
                IsActive: 1,
                NotificationName: notificationAddEditData.notificationname,
                PageViewsCount: notificationAddEditData.threshold.replace(t("header.Notification_Setting_or_more"), ""),
                RuleId: 0,
                SMSAlert: 0
            }
        }
        request(
            baseURL + `/WebsiteAnalytics/SetNotification?companyid=` + contextData.companyId + `&packageid=` + 5 + `&isTF=` + 1,
            METHODS.POST,
            notificationd,
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            }
        ).then((response) => {
            if (response?.resultData && response.resultData?.ruleId > 0) {
                setSuccessMessage(t("header.Message_" + convertTranslatinString(response.resultData.message)));
                setTimeout(() => {
                    setSuccessMessage(undefined);
                }, ERROR_TIMEOUT);
                setShowAddEditPopup(false);
                setTnFNotificationData();
            }
            else {
                setErrorMessage(response.resultData.message.length > 0 ? t("header.Message_" + convertTranslatinString(response.resultData.message)) : t("header.Something_went_wrong"));
                setTimeout(() => {
                    setErrorMessage(undefined);
                }, ERROR_TIMEOUT);
                setShowLoader(false);
            }
        }).catch((error) => {
            setErrorMessage(t("header.Something_went_wrong"));
            setTimeout(() => {
                setErrorMessage(undefined);
            }, ERROR_TIMEOUT);
            setShowLoader(false);
        });
    };

    const commonHeader = useMemo(() =>
        <>
            <CommonHeader channel="IR-tools-anaytics" cancelFilters={cancelFilters} autoLoad={autoLoad} dataSource={[
                { name: 'Dropdown1 - Website', data: websiteList, selectedItem: selectedWebsiteIdNew, isVisible: true, label: t("header.filterLabelWebsite"), callback: onWebsiteDropdownChange, displayText: "WebsiteList.WebsiteName" },
                { name: 'Dropdown2 - Profile', data: profilesList, selectedItem: selectedProfileIdNew, isVisible: true, label: t("header.filterLabelProfile"), callback: onProfileDropdownChange, displayText: "WebsiteProfiles.DisplayName" },
            ]}
                title={t("header.WONotificationsTitle")}
                showDate={false}
                applyFilters={applyFilters}
            />
        </>,
        [selectedProfileIdNew, selectedWebsiteIdNew, autoLoad]);

    return (<>
        {showDeleteModal && (
            <QuestionModal
                onSuccessModelClose={onDeleteSuccessModelClose}
                showModal={showDeleteModal}
                onCancelClose={onDeleteModalClose}
                setShowModal={setShowDeleteModal}
                setIsSuccess={setShowDeleteModal}
                QuestionModalContent={deleteModalContent}
            />
        )}
        <ModalComponent
            title={t("header.WONotificationsHistoryTitle")}
            showCloseButton={true}
            open={showHistoryPopup}
            subtitle={notificationEditData.visitorName}
            subtitle2={notificationEditData.visitorCountryName}
            handleClose={closeHistoryPopup}
            className={"notification-table"}
        >
            <Row>
                <Col md={12}>
                    <ChartRendererTable
                        apiUrl={historyApiUrl}
                        showCount={false}
                        showNumberOfRows={10}
                        showPagination={true}
                    />
                </Col>
            </Row>
        </ModalComponent>
        <ModalComponent title={t("header.notificationSetting")}
            showCloseButton={true}
            open={showAddEditPopup}
            handleClose={closeAddEditPopup}
        >
            <Row>
                <Col md={12}>
                    <p className='fw-bold'>{t("header.WONotificationnotifiedText")}</p>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col md={3}>
                    {t("header.WONotificationSetupCName")}   {notificationAddEditData.operation == "add" && <span style={{ color: "red" }}>*</span>}
                </Col>
                <Col md={4}>
                    {notificationAddEditData.operation == "edit" && <>{notificationAddEditData.companyname}</>}
                    {notificationAddEditData.operation == "add" && <><div className="pe-1">

                        <InputGroup>
                            {
                                (notificationAddEditData.companynameisvalid ? <Form.Control maxLength={100} type="text" value={notificationAddEditData.companyname} className='input-right-border' onChange={(e) => onFormElementsChange(e)} name="companyname" autoComplete="off" /> :
                                    <Form.Control isInvalid maxLength={100} value={notificationAddEditData.companyname} type="text" onChange={(e) => onFormElementsChange(e)} name="companyname" autoComplete="off" />)
                            }
                            {showAutoFillForCompany && <div className="auto-dropdown">
                                <ul className="list-unstyled auto-dropdown-menu">
                                    {temList.length > 0 && temList.map((value, index) =>
                                        <li className="auto-dropdown-menu-item mt-1 mb-1" key={index} onClick={() => suggestionSelectedValue(value)}>{value.companyName}</li>
                                    )}
                                    {
                                        temList.length === 0 && <li style={{ color: '#cc493f' }} className="mt-1 mb-1">{t("header.contentUnavailable")}</li>
                                    }
                                </ul>
                            </div>}
                        </InputGroup>
                    </div></>}
                </Col>
            </Row>
            <Row className="mt-4">
                <Col md={3}>
                    {t("header.WONotificationSetupCountry")}
                </Col>
                <Col md={4}>
                    {notificationAddEditData.operation == "edit" && <>{notificationAddEditData.country}</>}
                    {notificationAddEditData.operation == "add" && <>
                        <DropdownWithIconsComponent
                            isDropdownDisabled={false}
                            dropdownData={dListCountry}
                            arrayValue="countryName"
                            selectedDropdown={notificationAddEditData.countrydata}
                            onChange={onCountryChange}
                            showLabel={false}
                            showIconInDropdownItem={false}
                            isPrimaryImage={false}
                            customClassName="text-overflow full-width"
                        />
                    </>}
                </Col>
            </Row>
            <Row className="mt-4">
                <Col md={3}>
                    {t("header.WONotificationSetupCompURL")}
                </Col>
                <Col md={9}>
                    <a className='link-primary' onClick={(e) => companyUrlClick(e, notificationAddEditData.companyurl)} >{notificationAddEditData.companyurl}</a>

                    {notificationAddEditData.moreurls.length > 0 && <>
                        <OverlayTrigger
                            placement="bottom"
                            trigger="click"
                            overlay={
                                <Popover className="notification-url-popover">
                                    <Popover.Content>
                                        <ul className="list-unstyled auto-dropdown-menu mb-0">
                                            {notificationAddEditData.moreurls.map((item) =>

                                                <li className="auto-dropdown-menu-item mt-1 mb-1 link-primary" onClick={(e) => companyUrlClick(e, item)} >
                                                    {item}
                                                </li>
                                            )
                                            }
                                        </ul>
                                    </Popover.Content>
                                </Popover>
                            }>
                            <Button variant="white" size="sm" className="m-0 p-0 ms-3 text-bold">{t("header.WONotificationSetupMore")}</Button>
                        </OverlayTrigger></>}
                </Col>
            </Row>
            <Row className="mt-4">
                <Col md={3}>
                    {t("header.WONotificationSetupName")} <span style={{ color: "red" }}>*</span>
                </Col>
                <Col md={4}>
                    {
                        (notificationAddEditData.notificationnameisvalid ? <Form.Control maxLength={100} type="text" value={notificationAddEditData.notificationname} onChange={(e) => onFormElementsChange(e)} name="notificationname" autoComplete="off" /> :
                            <Form.Control isInvalid maxLength={100} value={notificationAddEditData.notificationname} type="text" onChange={(e) => onFormElementsChange(e)} name="notificationname" autoComplete="off" />)
                    }
                </Col>
            </Row>
            <Row className="mt-4">
                <Col md={12}>
                    <b>
                        {t("header.notificationNofifyMessage")}
                    </b><br />
                    {t("header.notificationsProcessingMessage")}
                </Col>
            </Row>
            <Row className="mt-4">
                <Col md={6}>
                    <Form.Group>
                        {duration.map((type, i) => (
                            <FormCheck
                                checked={type.durationId == notificationAddEditData.durationId ? true : false}
                                type="radio"
                                value={notificationAddEditData.durationId}
                                label={t("header." + convertTranslatinString(type.description))}
                                name="durationId"
                                id={type.durationId}
                                onChange={(e) => onFormElementsChange(e)}
                            />
                        ))}
                    </Form.Group>
                </Col>
                {notificationAddEditData.durationId == 3 && <>
                    <Col md={6}>
                        <Row>
                            <Col md={5}>
                                <Datetime
                                    closeOnClickOutside={false}
                                    timeFormat={false}
                                    closeOnSelect={true}
                                    onChange={changeStartDate}
                                    className="mt-2"
                                    isValidDate={validStartDate}
                                    renderInput={(props, openCalendar) => (
                                        <InputGroup>
                                            <Form.Control
                                                required
                                                type="text"
                                                value={notificationAddEditData.startdate ? moment(notificationAddEditData.startdate).format("MMM DD,YYYY") : ""}
                                                placeholder={t("header.notificationsFrom")}
                                                onFocus={openCalendar}
                                            />
                                            <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                        </InputGroup>
                                    )} />
                            </Col>
                            <Col md={5}>
                                <Datetime
                                    closeOnClickOutside={false}
                                    timeFormat={false}
                                    closeOnSelect={true}
                                    onChange={changeEndDate}
                                    isValidDate={validEndDate}
                                    className="mt-2"
                                    renderInput={(props, openCalendar) => (
                                        <InputGroup>
                                            <Form.Control
                                                required
                                                type="text"
                                                value={notificationAddEditData.enddate ? moment(notificationAddEditData.enddate).format("MMM DD,YYYY") : ""}
                                                placeholder={t("header.notificationsTo")}
                                                onFocus={openCalendar}
                                                onChange={() => { }} />
                                            <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                        </InputGroup>
                                    )} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div className="invalid-feedback d-block" style={{ textAlign: "left" }}>{addEditFormErrors ? addEditFormErrors : ''}</div>
                            </Col>
                        </Row>
                    </Col>
                </>}
            </Row>
            <Row className="mt-4">
                <Col md={3}>
                    {t("header.WONotificationSetupPVThreshold")}<span style={{ color: "red" }}>*</span>
                </Col>
                <Col md={4}>
                    <Form.Group className="mb-3">
                        {
                            (notificationAddEditData.thresholdisvalid ? <Form.Control maxLength={8} onBlur={() => {
                                if (notificationAddEditData.threshold.length > 0) {
                                    setNotificationAddEditData(prevState => ({
                                        ...prevState,
                                        threshold: notificationAddEditData.threshold + t("header.Notification_Setting_or_more")
                                    }));
                                }
                            }} onFocus={() => {
                                if (notificationAddEditData.threshold.length > 0) {
                                    setNotificationAddEditData(prevState => ({
                                        ...prevState,
                                        threshold: notificationAddEditData.threshold.replace(t("header.Notification_Setting_or_more"), "")
                                    }))
                                }
                            }} type="text" value={notificationAddEditData.threshold} onChange={(e) => onFormElementsChange(e)} name="threshold" autoComplete="off" /> :
                                <Form.Control isInvalid maxLength={8} onBlur={() => {
                                    if (notificationAddEditData.threshold.length > 0) {
                                        setNotificationAddEditData(prevState => ({
                                            ...prevState,
                                            threshold: notificationAddEditData.threshold + t("header.Notification_Setting_or_more")
                                        }));
                                    }
                                }} onFocus={() => {
                                    if (notificationAddEditData.threshold.length > 0) {
                                        setNotificationAddEditData(prevState => ({
                                            ...prevState,
                                            threshold: notificationAddEditData.threshold.replace(t("header.Notification_Setting_or_more"), "")
                                        }))
                                    }
                                }}
                                    value={notificationAddEditData.threshold} type="text" onChange={(e) => onFormElementsChange(e)} name="threshold" autoComplete="off" />)
                        }
                    </Form.Group>
                    {notificationAddEditData.threshold == "0" + t("header.Notification_Setting_or_more")
                        && <div className="invalid-feedback d-block">{t("header.WONotificationSetupPageViewsThreshold")}</div>
                    }
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md={3}>
                </Col>
                <Col md={9}>
                    <ButtonComponent variant="secondary" btnText={t("header.WONotificationSetupSave")} isIcon={false} isPrimary={true} btn_class="me-3" onClick={(e) => validateFormElement(e)}>
                    </ButtonComponent>

                    <ButtonComponent variant="btn-outline-primary" btnText={t("header.WONotificationSetupCancel")} isIcon={false} isPrimary={false} btn_class="mb-0" onClick={(e) => closeAddEditPopup(e)} >
                    </ButtonComponent>
                </Col>
            </Row>
        </ModalComponent>
        <Row>
            <Col>{commonHeader}</Col>
        </Row>
        <Row>
            <Col>
                {showLoader && <div id='blurdiv' className="blurbackground">
                    <div className={`preloader loader-center-align`}>
                        <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                    </div>
                </div>}
                {(successMessage || errorMessage) && <div className="fade modal-backdrop show"></div>}
                <Alert
                    className="toast-custom"
                    variant="success"
                    show={successMessage ? true : false}
                    onClose={() => onCloseSuccessMessage()}
                >
                    <div className="d-flex justify-content-between">
                        <div>
                            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                            {successMessage}
                        </div>
                        <Button variant="close" size="xs" onClick={() => onCloseSuccessMessage()} />
                    </div>
                </Alert>
                <Alert
                    className="toast-custom"
                    variant="danger"
                    show={errorMessage ? true : false}
                    onClose={() => onCloseErrorMessage()}
                >
                    <div className="d-flex justify-content-between">
                        <div>
                            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                            {errorMessage}
                        </div>
                        <Button variant="close" size="xs" onClick={() => onCloseErrorMessage()} />
                    </div>
                </Alert>
                <div className="d-inline-block p-3 pe-0 w-100">
                    <div className="shadow-soft border rounded border-light w-100 nested-card-container Notification-container">
                        <DashboardItem>
                            <Row className="mt-5">
                                <Col md={12}>
                                    <Row className={localStorage.getItem("isDemoClient") ? "custom-disable" : ""}>
                                        <Col md={12} className="position-relative">
                                            {<div className="search-box-container notification-table-search">
                                                <Search
                                                    setsearchData={setSearchData}
                                                    searchData={searchData}
                                                    searchWaterMark={t("header.WONotificationsSearchTT")}
                                                    isAutoComplete={false}
                                                    setAutoFillForCompany={setAutoFillForCompanyPopup}
                                                    showAutoFillForCompany={showAutoFillForCompanyPopup}
                                                    type={"client"}
                                                ></Search>
                                                <Card.Link
                                                    onClick={(e) => openAddNotification()}
                                                    className="mt-2 float-start"
                                                >
                                                    <FontAwesomeIcon title={t("header.Icon_Tooltip_AddaNotification")} icon={faPlusCircle} size="lg" />
                                                </Card.Link>
                                            </div>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            {notification.transactionStatus === DataStatusMessage.DataAvailable &&
                                                <DataTableRenderer
                                                    header={notification.header}
                                                    dataSet={notification.result}
                                                    transactionStatus={notification.transactionStatus}
                                                    customAttributes={
                                                        {
                                                            displayRowsPerPage: 10,
                                                            showPagination: true,
                                                            summaryText: t("header.WONotificationsTotNotifications"),
                                                            summaryTooltip: t("header.NotificationSummaryTooltip"),
                                                            summaryCount: notification.result.length,
                                                            showCount: true
                                                        }
                                                    }
                                                    rowClickEvent={rowClickEvent}
                                                ></DataTableRenderer>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </DashboardItem>
                    </div>
                </div>
            </Col>
        </Row>
    </>);
}
export default NotificationPage;