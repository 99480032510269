export class METHODS {
    static POST = "post"
    static GET = "get"
    static PATCH = "patch"
    static DELETE = "delete"
    static PUT = "put"
}

export class CHART_TYPE {
    static piechart = "piechart"
    static linechart = "linechart"
    static barchart = "barchart"
    static multilinechart = "multilinechart"
    static table = "table"
}

export class COMPONENT {
    static AUDIENCETREND = "audiencetrend"
    static TRAFFICSOURCE = "trafficsource"
    static USERS = "users"
    static COUNTRY = "table,country"
    static SEARCH = "table,search"
    static CONTENTINSIGHT = "table,contentinsights,website"
}

export const baseURL = process.env.REACT_APP_API_KEY;