import React, { useContext, useEffect, useState, useMemo } from 'react';
import EventCalendar from '../components/EventCalender';
import { Row, Col } from '@themesberg/react-bootstrap'
import AppContext from '../components/AppContext';
import request from '../apis/request';
import { METHODS, baseURL } from '../apis/utilities/constant';
import { useTranslation } from 'react-i18next';
import { decryptData } from '../utils/crypto';
import CommonHeader from '../components/CommonHeader';
import { DataStatusMessage } from "../utils/constant";
import CommonMessageComponent from '../components/CommonMessageComponent';


export const EventCalendarPage = () => {

    const contextData = useContext(AppContext);
    const [selectedWebsiteId, setSelectedWebsiteId] = useState({});
    const [selectedLanguage, setSelectedLanguage] = useState({ status: DataStatusMessage.Loading });
    const [blurLoader, setBlurLoader] = useState(false);
    const [t, i18n] = useTranslation();
    const [selectedWebsiteIdNew, setSelectedWebsiteIdNew] = useState("");
    const [selectedLanguageNew, setSelectedLanguageNew] = useState({});
    const [autoLoad, setAutoLoad] = useState(false);
    const [isApplyDisabled, setIsApplyDisabled] = useState(true);
    const [websiteList, setWebsiteList] = useState([]);


    useEffect(() => {
        let lngData = [];
        setIsApplyDisabled(lngData.length > 0 ? false : true)
        let wId = selectedWebsiteIdNew ? selectedWebsiteIdNew["WebsiteList.WebsiteId"] : undefined;
        if (wId) {
            setBlurLoader(true);
            request(baseURL + `/EventCalendar/GetEventLanguagesByClient?CompanyId=${contextData.companyId}&UserId=${contextData.userId}&WebsiteId=${wId}`,
                METHODS.GET,
                {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                })
                .then((res) => {
                    let lngData = [];
                    setIsApplyDisabled(lngData.length > 0 ? false : true)
                    if (typeof res.message == 'string') {
                        localStorage.setItem("languageData", JSON.stringify([]));
                        onLanguageDropdownChange({ status: DataStatusMessage.DataUnAvailable });
                        setAutoLoad(true);

                    }
                    else {
                        // onLanguageDropdownChange("");

                        let lngData = [];
                        res?.result && Array.isArray(res?.result) && res?.result.length > 0 && res?.result.map((item) => {
                            lngData.push({
                                "Languages.Languagecode": item.LanguageCode,
                                "Languages.languagename": item.DisplayName
                            })
                        })

                        localStorage.setItem("languageData", JSON.stringify([]))
                        if (lngData.length > 0) {
                            localStorage.setItem("languageData", JSON.stringify(lngData))
                            onLanguageDropdownChange(lngData[0]);
                            setAutoLoad(true);
                            setIsApplyDisabled(lngData.length > 0 ? false : true)

                        } else {
                            setAutoLoad(true);

                        }
                    }

                    setBlurLoader(false);
                })
                .catch((err) => {
                    onLanguageDropdownChange({ status: DataStatusMessage.ErrorOccured });
                    localStorage.setItem("languageData", JSON.stringify([]));
                    setAutoLoad(true);
                })
        }
    }, [selectedWebsiteIdNew]);

    useEffect(() => {
        setBlurLoader(true);
        if (contextData.companyId > 0) {
            request(baseURL + `/Intelligence/GetToolsWebsiteForClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
                METHODS.GET,
                {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                })
                .then((res) => {
                    setBlurLoader(false);
                    onWebsiteDropdownChange(undefined);

                    let webSiteData = [];
                    res?.result && Array.isArray(res?.result) && res?.result.length > 0 && res?.result.map((item) => {
                        webSiteData.push({
                            "ClietConfiguration.otpclientid": 0,
                            "WebsiteList.WebsiteId": item.WebsiteId,
                            "WebsiteList.WebsiteName": item.WebsiteName
                        })
                    })
                    if (Object.keys(selectedWebsiteIdNew).length <= 0) {
                        if (localStorage.getItem("SelectedEventId")) {
                            onWebsiteDropdownChange(JSON.parse(localStorage.getItem("SelectedEventId")))
                        } else {
                            webSiteData.length > 0 ? onWebsiteDropdownChange(webSiteData[0]) : onWebsiteDropdownChange({});
                        }

                    }
                    localStorage.setItem("eventsWebsiteData", JSON.stringify(webSiteData))
                    setWebsiteList(webSiteData);

                })
                .catch((err) => {
                    setBlurLoader(false);
                    setWebsiteList([]);
                })

        }

    }, [contextData.companyId])


    const cancelFilters = () => {
        onWebsiteDropdownChange(JSON.parse(localStorage.getItem("SelectedEventId")))
        onLanguageDropdownChange(JSON.parse(localStorage.getItem("EventCalendarProfileId")))
    }

    const onWebsiteDropdownChange = (data) => {
        setSelectedWebsiteIdNew(data)
    };

    const onLanguageDropdownChange = (data) => {
        setSelectedLanguageNew(data)
    };

    const applyFilters = () => {
        setSelectedLanguage(selectedLanguageNew);
        contextData.changeWebsiteId(selectedWebsiteIdNew["WebsiteList.WebsiteId"]);
        setSelectedWebsiteId(selectedWebsiteIdNew);
        localStorage.setItem("SelectedEventId", JSON.stringify(selectedWebsiteIdNew));// Adding SelectedEventId to local stoarge in case of Page Refresh
        localStorage.setItem("EventCalendarProfileId", JSON.stringify(selectedLanguageNew));// Adding EventCalendarProfileId to local stoarge in case of Page Refresh
    }


    const commonHeader = useMemo(() =>
        <CommonHeader
            cancelFilters={cancelFilters}
            applyFilters={applyFilters}
            // forceUpdate={forceUpdate}
            autoLoad={autoLoad}
            dataSource={[
                { name: 'Dropdown1 - website', data: JSON.parse(localStorage.getItem("eventsWebsiteData")), selectedItem: selectedWebsiteIdNew, isVisible: true, label: t("header.filterLabelWebsite"), callback: onWebsiteDropdownChange, displayText: "WebsiteList.WebsiteName" },
                { name: 'Dropdown2 - language', data: JSON.parse(localStorage.getItem("languageData")), selectedItem: selectedLanguageNew, isVisible: selectedLanguageNew ? true : false, label: t("header.ToolsEventCalEventListLanguage"), callback: onLanguageDropdownChange, displayText: "Languages.languagename" },
            ]}
            title={t("header.ToolsEventCalTabManageEvents")}
            showDate={false}
            isApplyDisabled={isApplyDisabled}
        />,
        [selectedWebsiteIdNew, selectedLanguageNew, autoLoad, isApplyDisabled])

    return (
        <>
            <Row className="align-items-center">
                <Col>
                    {commonHeader}
                </Col>
            </Row>

            {
                selectedLanguage?.hasOwnProperty("status") &&
                <div className="d-inline-block p-3 pe-0 w-100">
                    <div className="shadow-soft border rounded border-light w-100 nested-card-container box-height">
                        <div className='text-center no-data position-relative mt-5'>
                            <CommonMessageComponent transactionStatus={selectedLanguage.status}></CommonMessageComponent>
                        </div>
                    </div>
                </div>
            }
            {
                selectedLanguage["Languages.Languagecode"] &&
                <EventCalendar
                    selectedWebsiteId={selectedWebsiteId}
                    selectedLanguage={selectedLanguage}
                />
            }
        </>
    )
};


export default EventCalendarPage;