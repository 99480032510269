//import { getCardDataFromGAMultiline } from "../../apis/services/websitePageService";

// export const apiChartsPages = [
//     {
//         id: 1,
//         service: (payload, callBackFn) => { getCardDataFromGAMultiline(payload, callBackFn) },
//     },
// ]

export const getSearchPage = (profileId, startDate, endDate) => {
    let searchData = [
        {
            id: 6,
            layout: "{\"x\":0,\"y\":25,\"w\":12,\"h\":26.3}",
            // layoutone: "{\"x\":0,\"y\":24,\"w\":12,\"h\":7}",
            // layouttwo: "{\"x\":0,\"y\":24,\"w\":12,\"h\":7}",
            // layoutthree: "{\"x\":0,\"y\":24,\"w\":12,\"h\":7}",
            name: "",
            // vizStateSecond: `{}`,
            // summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"dimensions\":[],\"measures\":[\"Googleanalyticssearchdata.searchcount\"],\"timeDimensions\":[{\"dimension\": \"Googleanalyticssearchdata.gadate\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{},\"filters\":[{\"member\": \"Googleanalyticssearchdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}]}}`,
            // vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Googleanalyticssearchdata.searchkeyword\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false,\"limit\":5},\"query\":{\"dimensions\":[\"Googleanalyticssearchdata.searchkeyword\"],\"timeDimensions\":[{\"dimension\": \"Googleanalyticssearchdata.gadate\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":[[\"Googleanalyticssearchdata.searchresultviews\",\"desc\"]],\"limit\":5,\"measures\":[\"Googleanalyticssearchdata.searchresultviews\"],\"filters\":[{\"member\": \"Googleanalyticssearchdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}]}}`,
            // legends: ``,
            enableOption: true,
            // apiUrl: {url:`${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetCardDataFromGA?StartDate=${startDate}&EndDate=${endDate}&profileID=${profileId}&Matrix=searchResultViews,pageviews&Dimensions=searchKeyword&Component=table,search`,type:"get",count:""},
            apiUrl: { url: `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=${startDate}&EndDate=${endDate}&profileID=${profileId}&Matrix=searchResultViews,pageviews&Dimensions=searchKeyword&Component=table,search`, type: "get", count: "" },

        }
    ];

    return searchData;
};