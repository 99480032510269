import { Row, Col } from '@themesberg/react-bootstrap';
import React from 'react';
import EditablePage from '../components/EditablePage';



function EditablePages() {
    return (
        <>
            <Row className="mb-3">
                <Col lg={12} xl={12}>
                    <EditablePage/>
                </Col>
            </Row>
        </>
    )
}

export default EditablePages