import React, { useEffect, useState, useContext } from 'react';
import ManualAlerts from '../components/ManualAlerts';
import { isMobile } from "react-device-detect";
import { manualAlerts } from "../stubs/ComponentJson/alerts";
import ChartRenderer from '../components/ChartRenderer';
import ChartSummary from '../components/CardSummary';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import DashboardItem from '../components/DashboardItem';
import { Button, Alert } from '@themesberg/react-bootstrap'
import { CARD_MOBILE_LAYOUT, DEFAULT_PAGE_NO, ERROR_TIMEOUT, OPERATION_TYPE, TABLE_TOTAL_ROWS } from "../AppConfig";
import AppContext from '../components/AppContext';
import ChartRendererTable from '../components/ChartRendererTable';
import moment from 'moment';
import request from '../apis/request';
import { METHODS, baseURL } from '../apis/utilities/constant';
import { useTranslation } from "react-i18next";
import { decryptData } from '../utils/crypto';

const deserializeItem = (i) => ({
    ...i,
    layout: i.layout ? JSON.parse(i.layout) : {},
    summary: i.summary ? JSON.parse(i.summary) : {},
    vizState: i.vizState ? JSON.parse(i.vizState) : {},
    isResultShownFromApi: i.isResultShownFromApi ? true : false,
    chartType: i.chartType ? i.chartType : undefined,
    isSummeryShown: i.isSummeryShown ? i.isSummeryShown : false,
    isLegendShown: i.isLegendShown ? i.isLegendShown : false,
    apiUrl: i.apiUrl ? i.apiUrl : undefined
});

const defaultLayout = (i) => ({
    x: i.layout.x || 0,
    y: i.layout.y || 0,
    w: i.layout.w || 4,
    h: i.layout.h || 15,
    minW: 4,
    minH: 15,
});

const defaultLayoutMobile = (i) => ({
    x: i.layout.x || 0,
    y: i.layout.y || 0,
    w: 12,
    // w: i.layout.w || 4,
    h: i.layout.h + 2 || 12,
    minW: 4,
    minH: 8,
});



export const ManualAlertsPage = () => {

    const contextData = useContext(AppContext);
    const { companyId } = useContext(AppContext);
    const [selectedWebsiteId, setSelectedWebsiteId] = useState({});
    const [alertStatsData, setAlertStatsData] = useState([]);
    const [selectedOtpclientid, setSelectedOtpclientid] = useState({});
    const [pageNo, setPageNo] = useState(0);
    const [offset, setOffset] = useState(0);
    const [confirmationText, setConfirmationText] = useState(undefined);
    const [operationType, setOperationType] = useState(undefined);
    const [actionData, setActionData] = useState(undefined);
    const [tableRefresh, setTableRefresh] = useState(false);
    const [blurLoader, setBlurLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [editPreviewData, setEditPreviewData] = useState(undefined);
    const [success, setSuccessMessage] = useState(undefined);
    const [getTotalResults, setGetTotalResults] = useState(0)
    const [t] = useTranslation();

    useEffect(() => {
        const selectedstartdate = moment(contextData.startDate).format("YYYY-MM-DD");
        const selectedenddate = moment(contextData.endDate).format("YYYY-MM-DD");
        const profileId = contextData.selectedProfile["WebsiteProfiles.ProfileId"];
        // const websiteId = contextData.websiteId["WebsiteList.WebsiteId"];
        const websiteId = contextData.websiteId;
        const userId = contextData.userId;
        const companyId = contextData.companyId

        // if (pageNo === 0) {
        //     setPageNo(1);
        // }
        // let dta = alertStats(companyId);
        if (websiteId > 0 && contextData.selectedTab == "Alerts") {
            const otpClientId = selectedOtpclientid && selectedOtpclientid["ClietConfiguration.otpclientid"] != undefined ? selectedOtpclientid["ClietConfiguration.otpclientid"] : 29;
            let dta = manualAlerts(otpClientId, selectedstartdate, selectedenddate, offset, websiteId, companyId, userId);
            setAlertStatsData(dta);
        }
        else {
            setAlertStatsData(null);
        }

    }, [contextData.companyId, contextData.selectedTab, contextData.websiteId, tableRefresh]);

    const onConfirm = () => {

        let messageId = actionData["ManualAlert.MessageId"];
        let alertType = actionData["ManualAlert.AlertType"];
        // let websiteId = contextData.websiteId["WebsiteList.WebsiteId"];
        const websiteId = contextData.websiteId;

        if (operationType == OPERATION_TYPE.SEND) {
            setBlurLoader(true);
            request(baseURL + `/Intelligence/AlertReadyForSending?WebsiteId=${websiteId}&ResendMessageId=${messageId}&companyid=${contextData.companyId}&userid=${contextData.userId}`, METHODS.POST, {}, {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
                .then((res) => {
                    setBlurLoader(false);
                    if (res?.result) {
                        setSuccessMessage(t("header.AlertMessageAlertSentSuccessfully"));
                        setTimeout(() => {
                            setSuccessMessage(undefined);
                        }, ERROR_TIMEOUT);
                        setTableRefresh(!tableRefresh);
                    }
                    else {
                        setErrorMessage(t("header.manualAlertsErrorOccured"));
                        setTimeout(() => {
                            setErrorMessage(undefined);
                        }, ERROR_TIMEOUT);
                    }
                })
                .catch((err) => {
                    setErrorMessage(t("header.manualAlertsErrorOccured"));
                    setTimeout(() => {
                        setErrorMessage(undefined);
                    }, ERROR_TIMEOUT);
                    setBlurLoader(false);
                })
        }
        else if (operationType == OPERATION_TYPE.CANCEL) {
            setBlurLoader(true);
            request(baseURL + `/Intelligence/DismissAlert?WebsiteId=${websiteId}&MessageId=${messageId}&companyid=${contextData.companyId}&userid=${contextData.userId}&MessaageType=${alertType}`,
                METHODS.POST,
                {},
                {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
                })
                .then((res) => {
                    let { result } = res;
                    setBlurLoader(false);
                    if (result) {
                        if (typeof result == 'string') {
                            setErrorMessage(t("header.manualAlertsErrorOccured"));
                            setTimeout(() => {
                                setErrorMessage(undefined);
                            }, ERROR_TIMEOUT);
                        }
                        else {
                            setTableRefresh(!tableRefresh);
                        }
                        //setErrorMessage("Error Occured");
                    }
                    else {
                        setBlurLoader(false);
                        setErrorMessage(t("header.manualAlertsErrorOccured"));
                        setTimeout(() => {
                            setErrorMessage(undefined);
                        }, ERROR_TIMEOUT);
                    }
                })
                .catch((err) => {
                    setBlurLoader(false);
                })
        }
        else {

        }

        setConfirmationText(undefined);
        setOperationType(undefined);
    }



    const handleEditAndPreview = (data) => {
        let messageId = data["ManualAlert.MessageId"];
        let alertType = data["ManualAlert.AlertType"];
        // let websiteId = contextData.websiteId["WebsiteList.WebsiteId"];//VP
        const websiteId = contextData.websiteId;

        setBlurLoader(true);

        request(baseURL + `/Intelligence/GetAlertManualByWebsite?WebsiteId=${websiteId}&MessageId=${messageId}&companyid=${contextData.companyId}&userid=${contextData.userId}`, METHODS.GET, {}, {
            Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        })
            .then((res) => {
                setEditPreviewData(res?.result && Array.isArray(res?.result) && res?.result.length > 0 ? res?.result[0] : undefined)
                setBlurLoader(false);
            })
            .catch((err) => {
                setEditPreviewData(undefined);
                setBlurLoader(false);
            })
    }

    const handleEditCancel = (e, row, rowkey, type) => {
        let alertType = row["ManualAlert.AlertType"];
        setActionData(row);
        if (type == OPERATION_TYPE.SEND) {
            if (alertType == "Scheduled")
                setConfirmationText(t("header.AlertMessageScheduled"));
            else
                setConfirmationText(t("header.AlertMessageSendAlertNow"));

            setOperationType(OPERATION_TYPE.SEND);
        }
        else if (type == OPERATION_TYPE.CANCEL) {
            setOperationType(OPERATION_TYPE.CANCEL);
            setConfirmationText(t("header.AlertMessageConfirmDismiss"));
        }
        else {
            setOperationType(OPERATION_TYPE.EDIT);
            handleEditAndPreview(row);
        }
    }

    const onPageNoChange = (number) => {
        const num = (number - 1) * TABLE_TOTAL_ROWS;
        const page = number;
        setOffset(num);
        setPageNo(page);
    }

    const handleClose = () => {
        setOperationType(undefined);
        setConfirmationText(undefined);
        setActionData(undefined);
    }

    const renderSummary = (cardSummary) => {
        return (
            <ChartSummary
                summaryPrimary={cardSummary.summaryPrimary}
                summarySecondary={cardSummary.summarySecondary}
                isCarouselCard={cardSummary.isCarouselCard}
                showPercentageForPrimary={cardSummary.showPercentageForPrimary}
                showPositiveOrNegativeForPrimary={cardSummary.showPositiveOrNegativeForPrimary}
                showPercentageForSecoundary={cardSummary.showPercentageForSecoundary}
                showPositiveOrNegativeForSecoundary={cardSummary.showPositiveOrNegativeForSecoundary}
                carousalMainSummary={cardSummary.carousalMainSummary}
            />
        );

    };

    const dashboardItem = (item) => (

        <div key={item.id} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}  >

            <DashboardItem
                key={item.id}
                itemId={item.id}
                title={getTotalResults > 0 && t("header.AlertManageManualAlertsInstruction")}
                customTitle={"manualalert"}
            >
                {
                    item.apiUrl !== undefined &&
                    <>


                        <ChartRendererTable
                            showCount={true}
                            handleEditCancel={(e, row, rowkey, type) => { handleEditCancel(e, row, rowkey, type) }}
                            minimumRows={10}
                            // summaryTooltip={showTooltips(item)}
                            showPagination={true}
                            apiUrl={item.apiUrl}
                            showNumberOfRows={10}
                            summaryBesideText={t("header.manualAlertsTotalAlertsButton")}
                            setGetTotalResults={(value) => setGetTotalResults(value)}
                        />

                    </>
                }
                {/* {Object.keys(item.summary).length > 0 && renderSummary(item.summary)}
                <ChartRenderer vizState={item.vizState} cardProperty={item.layout} legends={item.legends} setPageNo={setPageNo} pageNo={pageNo} onPageNoChange={onPageNoChange} showPagination={item.pagination}
                    offset={offset} setOffset={setOffset} /> */}
            </DashboardItem>
        </div>
    );

    const Empty = () => (
        <div
            style={{
                textAlign: 'center',
                padding: 12,
            }}
        >
            <h2>{t("header.noChartsonDashboard")}</h2>
            {/* <Link to="/explore"> */}
            <Button variant="secondary" className="m-1">
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                {t("header.addChart")}
            </Button>
            {/* </Link> */}
        </div>
    );

    const onCloseSuccessMessage = () => {
        setErrorMessage(undefined);
    }

    return alertStatsData && alertStatsData.length > 0 ? (
        <ManualAlerts
            success={success}
            setTableRefresh={setTableRefresh}
            tableRefresh={tableRefresh}
            setEditPreviewData={setEditPreviewData}
            actionData={editPreviewData}
            operationType={operationType}
            parentBlurLoader={blurLoader}
            onCloseSuccessMessage={onCloseSuccessMessage}
            errorMessage={errorMessage}
            handleClose={handleClose}
            onConfirm={onConfirm}
            confirmationText={confirmationText}
            setConfirmationText={setConfirmationText}
            dashboardItems={alertStatsData}
            selectedWebsiteId={selectedWebsiteId}
            selectedOtpclientid={selectedOtpclientid}
            setSelectedWebsiteId={setSelectedWebsiteId}
            setSelectedOtpclientid={setSelectedOtpclientid}
            setPageNo={setPageNo}
            setOffset={setOffset}
        >
            {alertStatsData.map(deserializeItem).map(dashboardItem)}
        </ManualAlerts>

    ) : (
        <h1 className='text-center'>connect.ID Intelligence</h1>
    );
};


export default ManualAlertsPage;