import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from "react-device-detect";
import { useTranslation } from 'react-i18next';
import RGL, { WidthProvider } from 'react-grid-layout';
import { Col, Row } from '@themesberg/react-bootstrap';

import { notificationHistoryItems } from '../stubs/ComponentJson/Notifications';
import { TABLE_TOTAL_ROWS } from "../AppConfig";

import ChartRenderer from '../components/ChartRenderer';
import DashboardItem from '../components/DashboardItem';
import SummaryRenderer from '../components/SummaryRenderer';
import ModalComponent from "../components/VoltModal";

const ReactGridLayout = WidthProvider(RGL);
const deserializeItem = (i) => ({
    ...i,
    layout: JSON.parse(i.layout) || {},
    summary: JSON.parse(i.summary) || {},
    vizState: JSON.parse(i.vizState)
});
const defaultLayout = (i) => ({
    x: i.layout.x || 0,
    y: i.layout.y || 0,
    w: i.layout.w || 4,
    h: i.layout.h || 8,
    minW: 4,
    minH: 8,
});

const defaultLayoutMobile = (i) => ({
    x: i.layout.x || 0,
    y: i.layout.y || 0,
    w: 12,
    minW: 4,
    minH: 8,
});
const NotificationHistoryModal = ({
    showHistoryModal,
    setShowHistoryModal,
    historyData,
    setHistoryData,
    selectedCompanyid,
    selectedCountryid,
    company,
    country,
}) => {
    const [t, i18n] = useTranslation();
    const [pageNo, setPageNo] = useState(0);
    const [offset, setOffset] = useState(0);
    const [items, setItems] = useState([]);

    useEffect(() => {
        selectedCompanyid = localStorage.getItem("airuleId");
        selectedCountryid = localStorage.getItem("countryId");
        company = localStorage.getItem("company");
        country = localStorage.getItem("country");
        let dta = notificationHistoryItems(selectedCompanyid, selectedCountryid);
        setHistoryData(dta);
    }, [selectedCompanyid, selectedCountryid]);

    const onModalClose = () => {
        setShowHistoryModal && setShowHistoryModal(false);
    };

    const onPageNoChange = (number) => {
        const num = (number - 1) * TABLE_TOTAL_ROWS;
        const page = number;
        setOffset(num);
        setPageNo(page);
    }

    const historyItem = (item) => (
        <div key={item.id} className={item.classname} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}>
            <DashboardItem
                key={item.id}
                itemId={item.id}
                title={item.name}
                hideTitle={true}
            >
                {Object.keys(item.summary).length > 0 && <SummaryRenderer vizState={item.summary} title={item.name} />}
                <ChartRenderer vizState={item.vizState} cardProperty={item.layout} items={items} setItems={setItems} legends={item.legends} setPageNo={setPageNo} pageNo={pageNo} onPageNoChange={onPageNoChange} showPagination={item.pagination}
                    offset={offset} setOffset={setOffset}
                />
            </DashboardItem>
        </div>
    );

    return (
        <ModalComponent
            open={showHistoryModal}
            title={t("header.WONotificationsHistoryTitle")}
            handleClose={onModalClose}
            showCloseButton={true}
        >
            <Row>
                <Col md={12}>
                    <p className="body-text fw-bold mb-1">{localStorage.getItem("company")}</p>
                    <p className="body-text">{localStorage.getItem("country")}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    {historyData && historyData.length > 0 &&
                        <ReactGridLayout isDraggable={false} isResizable={false}>
                            {historyData.map(deserializeItem).map(historyItem)}
                        </ReactGridLayout>
                    }
                </Col>
            </Row>
        </ModalComponent>
    );

};

NotificationHistoryModal.propTypes = {
    handleButtonClick: PropTypes.func,
    showHistoryModal: PropTypes.bool,
    setShowHistoryModal: PropTypes.func,
    selectedCompanyid: PropTypes.string,
    setSelectedCompanyid: PropTypes.func,
    selectedCountryid: PropTypes.string,
    setSelectedCountryid: PropTypes.func,
    company: PropTypes.string,
    setCompany: PropTypes.func,
};

NotificationHistoryModal.defaultProps = {
    handleButtonClick: null,
    setShowHistoryModal: true,
    setShowHistoryModal: null,
    selectedCompanyid: "",
    setSelectedCompanyid: null,
    selectedCountryid: "",
    setSelectedCountryid: null,
    company: "",
    setCompany: null,
};

export default NotificationHistoryModal;