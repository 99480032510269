
import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';
import RGL, { WidthProvider } from 'react-grid-layout';
import { useMutation } from '@apollo/react-hooks';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { Row, Col, Button, Form, InputGroup, FormCheck, Alert } from '@themesberg/react-bootstrap';
import Loader from '../components/VoltLoader';
import DropdownWithIconComponent from "../components/DropdownWithIcons";
import { isMobile } from "react-device-detect";
import { withRouter } from "react-router";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faChevronLeft, faDownload, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { DEFAULT_WEBSITE_DASHBORD, DEFAULT_PROFILE_DASHBORD, object_equals, NULL_VALUE_MESSAGE_DROPDOWN } from "../AppConfig";
import { dashbordDateRangeList } from "../utils/datetime";
import ButtonComponent from "../components/Buttons";
import AppContext from '../components/AppContext';
import { useTranslation } from 'react-i18next';
import CommonHeader from '../components/CommonHeader';
import { apiChartsPages, getPagesChart } from '../stubs/ComponentJson/Pages';
import { defaultLayout, defaultLayoutMobile } from "../utils/layoutConfig";
import DashboardItem from '../components/DashboardItem';
import ChartRenderer from '../components/ChartRenderer';
import SummaryRenderer from '../components/SummaryRenderer';
import MultilineToggleComponent from '../components/MultilineToggleComponent';
import ApiChartRenderer from './ApiChartRenderer';


const ReactGridLayout = WidthProvider(RGL);

const deserializeItem = (i) => ({
  ...i,
  layout: i.layout ? JSON.parse(i.layout) : {},
  summary: i.summary ? JSON.parse(i.summary) : {},
  layoutone: i.layoutone ? JSON.parse(i.layoutone) : {},
  layouttwo: i.layouttwo ? JSON.parse(i.layouttwo) : {},
  vizStateSecond: i.vizStateSecond ? JSON.parse(i.vizStateSecond) : {},
  vizState: i.vizState ? JSON.parse(i.vizState) : {},
  isResultShownFromApi: i.isResultShownFromApi ? true : false,
  chartType: i.chartType ? i.chartType : undefined,
  isSummeryShown: i.isSummeryShown ? i.isSummeryShown : false
});

const PagePerformanceChart = (
  { selectedProfile, startDate, endDate }
  // { selectedProfileId, selectedWebsiteId }
) => {
  const [d3, setd3] = useState();
  // const contextData = useContext(AppContext);
  const [chartPerformance, setChartPerformance] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const [t, i18n] = useTranslation();

  const [alertVisibleMessage, setAlertVisibleMessage] = useState(null);


  useEffect(() => {
    // const url = window.location.href.split("/");
    // const urlEndpoints = `${url[0]}//${url[2]}/`;
    // const selectedstartdate = moment(contextData.startDate).format("YYYY-MM-DD");
    // const selectedenddate = moment(contextData.endDate).format("YYYY-MM-DD");
    // const selectedPreviousYearStartDate = moment(contextData.startDate).add(-1, 'years').format("YYYY-MM-DD");
    // const selectedPreviousYearEndDate = moment(contextData.endDate).add(-1, 'years').format("YYYY-MM-DD");

    let dta = null;

    if (selectedProfile["WebsiteProfiles.ProfileId"]) {
      dta = getPagesChart()
      setChartPerformance(dta);
    }
    // else {
    //   dta = (selectedstartdate !== "Invalid date" && selectedenddate !== "Invalid date" && selectedPreviousYearStartDate !== "Invalid date" && selectedPreviousYearEndDate !== "Invalid date")
    //     ? getPagesChart(contextData.selectedProfile["WebsiteProfiles.ProfileId"], selectedstartdate, selectedenddate, selectedPreviousYearStartDate, selectedPreviousYearEndDate)
    //     : null;
    // } 
  }, [selectedProfile]);
  const dashboardItem = (item) => (
    <div key={item.id} className={item.classname} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}>
      {(item.isResultShownFromApi && item.chartType) ?
        <DashboardItem
          key={item.id}
          itemId={item.id}
          title={item.name}
          enableOption={item.enableOption}>

          <Row >
            <Col>
              <ApiChartRenderer setAlertVisibleMessage={setAlertVisibleMessage} isSummeryShown={item.isSummeryShown} services={apiChartsPages} chartType={item.chartType} itemId={item.id} cardProperty={Object.keys(item.layoutone).length > 0 ? item.layoutone : item.layout} legends={item.legends} isRefresh={isRefresh} setIsRefresh={setIsRefresh} setd3={setd3} cardName={item.cardName}
              // pageName ={"pageanalytics"} 
              />
            </Col>
          </Row>
        </DashboardItem> :
        <DashboardItem
          key={item.id}
          itemId={item.id}
          title={item.name}
          enableOption={item.enableOption}>
          <Row>
            <Col>
              {/* {Object.keys(item.summary).length > 0 && <SummaryRenderer vizState={item.summary} title={item.name} />}
              <ChartRenderer vizState={item.vizState} cardProperty={Object.keys(item.layoutone).length > 0 ? item.layoutone : item.layout} legends={item.legends} isRefresh={isRefresh} setIsRefresh={setIsRefresh} setd3={setd3} />
              {item.extraSummary !== undefined && <div className="switch-toggle-component w-100"><MultilineToggleComponent d3={d3} setd3={setd3} extraSummary={JSON.parse(item.extraSummary)}></MultilineToggleComponent></div>} */}
            </Col>
          </Row>
        </DashboardItem>}
    </div>
  );

  return (
    <>
      {alertVisibleMessage && (<div className="fade modal-backdrop show"></div>)}
      <Alert
        className="toast-custom"
        variant="success"
        show={alertVisibleMessage}
      >
        <div className="d-flex justify-content-between">
          <div>
            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
            {alertVisibleMessage}
          </div>
          <Button variant="close" size="xs" onClick={() => setAlertVisibleMessage(null)} />
        </div>
      </Alert>
      
      {chartPerformance &&
        <Row>
          <Col lg={12} className="pe-0" id="alertStatistics">
            <div className="d-inline-block p-3 w-100">
              {chartPerformance.map(deserializeItem).map(dashboardItem)}
            </div>
          </Col>
        </Row>
      }
    </>
  );
};

export default withRouter(PagePerformanceChart);
