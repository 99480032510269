import React, { useEffect, useState, useContext } from 'react';
import { isMobile } from "react-device-detect";
import ChartRenderer from '../components/ChartRenderer';
import UseranalyticsComponent from '../components/UseranalyticsComponent';
import DashboardItem from '../components/DashboardItem';
import { CARD_MOBILE_LAYOUT, DEFAULT_PAGE_NO } from "../AppConfig";
import { defaultLayout, defaultLayoutMobile } from "../utils/layoutConfig";
import SummaryRenderer from '../components/SummaryRenderer';
import { apiChartsUserAnalytucs, apiChartsUserAnalytucsMap, getUserAnalyticsData, getUserAnalyticsDataMap } from "../stubs/ComponentJson/userAnalytics";
import moment from "moment-timezone";
import AppContext from '../components/AppContext';
import { Row, Col } from '@themesberg/react-bootstrap';
import { TABLE_TOTAL_ROWS } from "../AppConfig";
import ApiChartRenderer from '../components/ApiChartRenderer';
import ChartRendererTable from '../components/ChartRendererTable';
import { useTranslation } from "react-i18next";

const deserializeItem = (i) => ({
    ...i,
    layout: i.layout ? JSON.parse(i.layout) : {},
    summary: i.summary ? JSON.parse(i.summary) : {},
    layoutone: i.layoutone ? JSON.parse(i.layoutone) : {},
    layouttwo: i.layouttwo ? JSON.parse(i.layouttwo) : {},
    layoutthree: i.layouttwo ? JSON.parse(i.layouttwo) : {},
    vizStateSecond: i.vizStateSecond ? JSON.parse(i.vizStateSecond) : {},
    vizStateThird: i.vizStateThird ? JSON.parse(i.vizStateThird) : {},
    vizState: i.vizState ? JSON.parse(i.vizState) : {},
    isResultShownFromApi: i.isResultShownFromApi ? true : false,
    chartType: i.chartType ? i.chartType : undefined,
    isSummeryShown: i.isSummeryShown ? i.isSummeryShown : false
});

const UserAnalyticsPage = (props) => {
    const contextData = useContext(AppContext);
    const [pageNo, setPageNo] = useState(DEFAULT_PAGE_NO);
    const [searchText, setSearchText] = useState("");
    const [selectedWebsiteId, setSelectedWebsiteId] = useState({});
    const [selectedProfileId, setSelectedProfileId] = useState({});
    const [userAnalyticsData, setUserAnalyticsDataData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [sourceMedium, setSourceMedium] = useState("direct");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [offset, setOffset] = useState(0);
    const [isRefresh, setIsRefresh] = useState(false);
    const [usersByCountryApi, setUsersByCountryApi] = useState({ url: "", type: "get", count: 0 });
    const [t] = useTranslation();

    useEffect(() => {

        const selectedstartdate = moment(contextData.startDate).format("YYYY-MM-DD");
        const selectedenddate = moment(contextData.endDate).format("YYYY-MM-DD");

        const culture = localStorage.getItem("i18nextLng") ?? "en-GB"
        if (contextData.selectedTab == "Website") {
            let dta = ((contextData.selectedProfile["WebsiteProfiles.ProfileId"] 
                && selectedstartdate !== "Invalid date"
                && selectedenddate !== "Invalid date"))
                ? getUserAnalyticsData(contextData.selectedProfile["WebsiteProfiles.ProfileId"], selectedstartdate, selectedenddate, culture)
                : null;
            setUserAnalyticsDataData(dta);
        }
        else {
            setUserAnalyticsDataData([]);
        }
    }, [contextData.selectedProfile["WebsiteProfiles.ProfileId"], contextData.selectedTab, contextData.startDate, contextData.endDate]);


    const showTooltips = (item) => {
        if (item.name == 'User') { 
            return "header.user";
        } else if (item.name == 'Users by country') {
            return 'header.usersByCountry';
        } else {
            return "";
        }
    }

    const dashboardItem = (item) => (
        <div key={item.id} className={item.classname} data-grid={isMobile ? defaultLayoutMobile(item) : defaultLayout(item)}>
            {(item.isResultShownFromApi && item.chartType) ?
                <DashboardItem
                    key={item.id}
                    itemId={item.id}
                    title={item.name}
                    enableOption={item.enableOption}>
                    <Row  >
                        <Col className="no-data-min-height" sm={12}>
                            <ApiChartRenderer summaryTooltip={showTooltips(item)} isSummeryShown={true} services={apiChartsUserAnalytucs} chartType={item.chartType} itemId={item.id} cardProperty={Object.keys(item.layoutone).length > 0 ? item.layoutone : item.layout}
                                searchText={searchText}
                                legends={item.legends} showLoader={false} isRefresh={isRefresh} setIsRefresh={setIsRefresh} />
                        </Col>
                    </Row>
                    <Row className="user-analytics-map">
                        <Col className="no-data-min-height" sm={12}>
                            <ApiChartRenderer summaryTooltip={showTooltips(item)} isSummeryShown={item.isSummeryShown} services={apiChartsUserAnalytucsMap} summaryBesideText={t("header.title_Users_by_country")} chartType={"map"} itemId={"2"} cardProperty={Object.keys(item.layoutone).length > 0 ? item.layoutone : item.layout} tooltipText={"ewrqwe"} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="no-data-min-height" sm={12}>
                            {
                                item.apiUrl !== undefined &&
                                <ChartRendererTable
                                    showCardLoader={false}
                                    showPagination={true}
                                    apiUrl={item.apiUrl}
                                    showCount={false}
                                    showNumberOfRows={10}
                                />
                            }
                        </Col>
                    </Row>
                </DashboardItem>
                : <></>
                // <DashboardItem
                //     key={item.id}
                //     itemId={item.id}
                //     title={item.name}
                //     enableOption={item.enableOption}>
                //     <Row  >
                //         <Col className="no-data-min-height" sm={12}>
                //             {Object.keys(item.summary).length > 0 && <SummaryRenderer summaryTooltip={showTooltips(item)} vizState={item.summary} title={item.name} />}
                //             <ChartRenderer vizState={item.vizState} cardProperty={Object.keys(item.layoutone).length > 0 ? item.layoutone : item.layout}
                //                 searchText={searchText}
                //                 legends={item.legends} isRefresh={isRefresh} setIsRefresh={setIsRefresh} />
                //         </Col>
                //     </Row>
                //     <Row>
                //         <Col className="no-data-min-height" sm={12}>
                //             {(item.vizStateSecond !== undefined && Object.keys(item.vizStateSecond).length > 0) &&
                //                 <ChartRenderer vizState={item.vizStateSecond} cardProperty={item.layouttwo} isRefresh={isRefresh} setIsRefresh={setIsRefresh} />}
                //         </Col>
                //     </Row>
                //     <Row>
                //         <Col className="no-data-min-height" sm={12}>
                //             {(item.vizStateThird !== undefined && Object.keys(item.vizStateThird).length > 0) &&
                //                 <ChartRenderer vizState={item.vizStateThird} cardProperty={item.layoutthree} setPageNo={setPageNo} pageNo={pageNo} onPageNoChange={onPageNoChange}
                //                     showPagination={item.paginationThird} offset={offset} setOffset={setOffset} />}
                //         </Col>
                //     </Row>
                // </DashboardItem>
            }
        </div>
    );

    const handleBackButtonClick = (e) => {
        // localStorage.setItem("IsPageRefresh", "0");
        props.history.push(`/website`);
    }

    const Empty = () => (
        <div
            style={{
                textAlign: 'center',
                padding: 12,
            }}
        >
            {/* <h2>Loading...</h2> */}
            <h2>{t("header.getConnectIdIntelligence")}</h2>
        </div>
    );

    return ((!userAnalyticsData || userAnalyticsData.length > 0)) ? (
        <>
            <UseranalyticsComponent
                // dashboardItems={userAnalyticsData}
                handleBackButtonClick={handleBackButtonClick}
            //  usersData={usersData}
            // deserializeItem={deserializeItem}
            // dashboardItem={dashboardItem}
            //selectedProfileId={selectedProfileId}
            // setSelectedProfileId={setSelectedProfileId}
            // selectedWebsiteId={selectedWebsiteId}
            // setSelectedWebsiteId={setSelectedWebsiteId}
            // startDate={contextData.startDate}
            // endDate={contextData.endDate}2
            // setStartDate={setStartDate}
            // setEndDate={setEndDate}
            // setPageNo={setPageNo}
            // setOffset={setOffset}
            >
                {userAnalyticsData !== null && userAnalyticsData.map(deserializeItem).map(dashboardItem)}
            </UseranalyticsComponent>
        </>
    ) : (
        <div id='userAnalyticsContent'>
            <Empty />
        </div>
    );
}

export default UserAnalyticsPage