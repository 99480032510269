import { Row, Col, Form, Accordion, Card } from "@themesberg/react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { QueryRenderer } from '@cubejs-client/react';
import cubejs from '@cubejs-client/core';
import Loader from '../components/VoltLoader';
import moment from "moment-timezone";
import request from "../apis/request";
import { baseURL, METHODS } from "../apis/utilities/constant";
import AppContext from "./AppContext";
import AccordionComponent from "./AccordionComponent";
import { useTranslation } from 'react-i18next';
import { decryptData } from "../utils/crypto";

const ServiceCenterSummary = (props) => {
  const [t, i18n] = useTranslation();
  const contextData = useContext(AppContext);
  const [summeryData, setSummeryData] = useState(undefined);
  const [remaningHrs, setRemaningHrs] = useState(0);

  let {
    workordercompanyid,
    setShowBlurLoader
  } = props;

  useEffect(() => {

    let companyId = contextData.companyId;
    setSummeryData(undefined);
    setShowBlurLoader && setShowBlurLoader(true);
    if (contextData.companyId > 0 && workordercompanyid > 0) {
      request(baseURL + `/ServiceCentre/GetLastWOReportSummaryByClient?CompanyId=${companyId}&workorderCompanyId=${workordercompanyid}`,
        METHODS.GET,
        {},
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
        }
      )
        .then((res) => {
          setSummeryData(res?.result);
          setShowBlurLoader && setShowBlurLoader(false);
        })
        .catch((err) => {
          setSummeryData(undefined);
          setShowBlurLoader && setShowBlurLoader(false);
        })
    }

  }, [workordercompanyid]);

  const renderServiceCenterSumamry = ({ resultSet, error, pivotConfig }) => {
    if (error) {
      return <div>{error.toString()}</div>;
    }

    if (!resultSet) {
      return <Loader show={false} />;
    }
    const memberList = resultSet.tablePivot(pivotConfig)

    return (
      <>
        {memberList.map(function (item) {
          return (
            <></>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Row className="mt-2">
        <Col xs={12}>
          {summeryData && <div className="ms-3">

            <div className="card-custom-title mb-0 fw-bold">
              {t("header.serviceCenterSummaryLabel")}
            </div>
            {
              summeryData?.ContractStartDate && summeryData?.RenewalDate ?
                <>
                  <div className="pod segment-content-panel comparison-date-panel service-hour-summary mt-2 mb-3">
                    <div className="pod">{t("header.serviceCenterSummaryLabe2") + " "}<span className="fw-bold">{summeryData.ContractStartDate ? moment(summeryData.ContractStartDate).format("MMM DD,YYYY") : " "}</span> {t("header.serviceCenterSummaryLabe3") + " "} <span className="fw-bold">{summeryData?.RenewalDate ? moment(summeryData.RenewalDate).format("MMM DD,YYYY") : " "}</span></div>
                  </div>
                </> : <></>
            }
            <div className="nested-card-container visitor-key-insights-box rounded mb-2" id="insights-box">
              <Accordion className={"className"} defaultActiveKey={"panel-2"}>
                <Accordion.Item eventKey={"eventKey-11"} className="shadow-soft border rounded border-light mb-0">
                  <Accordion.Button variant="link" className="w-100 d-flex justify-content-between p-4 pe-3">
                    <div className="panel-heading">
                      <div className="row">
                        <div className="col-xs-12 fw-bold">
                          {t("header.serviceCenterSummaryLabe4") + " "}
                          <span className="fw-bold">
                            {((summeryData.TotalAdditonalHours + summeryData.TotalMaintenanceHours) - summeryData.TotalContractBillableHours).toFixed(2)}
                            {t("header.serviceCenterSummaryLabe5")}
                          </span>
                          {" ("}
                          {t("header.serviceCenterSummaryLabe6")} {moment(summeryData.TimeStamp).format("MMM DD,YYYY hh:mm A")}
                          {")"}
                        </div>
                      </div>
                    </div>
                  </Accordion.Button>
                  <Accordion.Body>
                    <Card.Body className="py-2 px-0">
                      <div className="panel">
                        <div className="panel-body">
                          <div className="pod card-visitor-insights-container">
                            <div className="card card-key-insights eq-card-1">
                              <div className="card-body pb-0">
                                <div className="key-insight-value" title={((summeryData.TotalAdditonalHours + summeryData.TotalMaintenanceHours) - summeryData.TotalContractBillableHours).toFixed(2)}>{((summeryData.TotalAdditonalHours + summeryData.TotalMaintenanceHours) - summeryData.TotalContractBillableHours).toFixed(2)}</div>
                                <div className="card-value-title">{t("header.serviceCenterSummaryLabe44")} </div>
                              </div>
                            </div>
                            <div className="card-operator">=</div>
                            <div className="card card-key-insights eq-card-2">
                              <div className="card-body pb-0">
                                <div className="key-insight-value" title={(summeryData.TotalMaintenanceHours).toFixed(2)}>{(summeryData.TotalMaintenanceHours).toFixed(2)}</div>
                                <div className="card-value-title">{t("header.serviceCenterSummaryLabe7")}</div>
                              </div>
                            </div>
                            <div className="card-operator">+</div>
                            <div className="card card-key-insights eq-card-3">
                              <div className="card-body pb-0">
                                <div className="key-insight-value" title={(summeryData.TotalAdditonalHours).toFixed(2)}>{(summeryData.TotalAdditonalHours).toFixed(2)}</div>
                                <div className="card-value-title">{t("header.serviceCenterSummaryLabe8")}</div>
                              </div>
                            </div>
                            <div className="card-operator">-</div>
                            <div className="card card-key-insights eq-card-4">
                              <div className="card-body pb-0">
                                <div className="key-insight-value" title={(summeryData.TotalContractBillableHours).toFixed(2)}>{(summeryData.TotalContractBillableHours).toFixed(2)}</div>
                                <div className="card-value-title">{t("header.serviceCenterSummaryLabe9")}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            {/* <div class="primary-widget-box shadow-sm mb-4 card border-light service-hour-calculation">
              <div class="collapsible-box pod mb-2 ipad-only">
                <div class="panel-group">
                  <div class="panel-default panel panel-open">
                    <div class="panel-heading mb-3">
                      <div class="row">
                        <div class="col-xs-12 fw-bold">
                          {t("header.serviceCenterSummaryLabe4")}
                          <span class="fw-bold">
                            {((summeryData.TotalAdditonalHours + summeryData.TotalMaintenanceHours) - summeryData.TotalContractBillableHours).toFixed(2)}
                            {t("header.serviceCenterSummaryLabe5")}
                          </span>
                          {" ("}
                          {t("header.serviceCenterSummaryLabe6")} {moment(summeryData.TimeStamp).format("MMM DD,YYYY hh:mm A")}
                          {")"}
                        </div>
                      </div>
                    </div>
                    <div class="panel">
                      <div class="panel-body">
                        <div class="pod card-visitor-insights-container">
                          <div class="card card-key-insights eq-card-1">
                            <div class="card-body pb-0">
                              <div class="key-insight-value" title={((summeryData.TotalAdditonalHours + summeryData.TotalMaintenanceHours) - summeryData.TotalContractBillableHours).toFixed(2)}>{((summeryData.TotalAdditonalHours + summeryData.TotalMaintenanceHours) - summeryData.TotalContractBillableHours).toFixed(2)}</div>
                              <div class="card-value-title">{t("header.serviceCenterSummaryLabe4")} </div>
                            </div>
                          </div>
                          <div class="card-operator">=</div>
                          <div class="card card-key-insights eq-card-2">
                            <div class="card-body pb-0">
                              <div class="key-insight-value" title={(summeryData.TotalMaintenanceHours).toFixed(2)}>{(summeryData.TotalMaintenanceHours).toFixed(2)}</div>
                              <div class="card-value-title">{t("header.serviceCenterSummaryLabe7")}</div>
                            </div>
                          </div>
                          <div class="card-operator">+</div>
                          <div class="card card-key-insights eq-card-3">
                            <div class="card-body pb-0">
                              <div class="key-insight-value" title={(summeryData.TotalAdditonalHours).toFixed(2)}>{(summeryData.TotalAdditonalHours).toFixed(2)}</div>
                              <div class="card-value-title">{t("header.serviceCenterSummaryLabe8")}</div>
                            </div>
                          </div>
                          <div class="card-operator">-</div>
                          <div class="card card-key-insights eq-card-4">
                            <div class="card-body pb-0">
                              <div class="key-insight-value" title={(summeryData.TotalContractBillableHours).toFixed(2)}>{(summeryData.TotalContractBillableHours).toFixed(2)}</div>
                              <div class="card-value-title">{t("header.serviceCenterSummaryLabe9")}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>}
        </Col>
      </Row>

    </>
  );
};
export default withRouter(ServiceCenterSummary);