import { getCardDataFromGA, getCardDataFromGABar, getCookiesCardDataPieChart, getDataForTable } from "../../apis/services/websitePageService";
import { COMPONENT } from "../../apis/utilities/constant";

export const apiCharts = [
    {
        id: 1,
        service: (cryptoKey, payload, callBackFn) => { getCookiesCardDataPieChart(cryptoKey, payload, callBackFn) },
    },
    {
        id: 2,
        service: (cryptoKey, payload, callBackFn) => { getCardDataFromGA(cryptoKey, "sessions", payload, callBackFn, COMPONENT.AUDIENCETREND, 1) },
    },
    {
        id: 5,
        service: (cryptoKey, payload, callBackFn) => { getCardDataFromGABar(cryptoKey, payload, callBackFn, COMPONENT.TRAFFICSOURCE) },
    },
    {
        id: 7,
        service: (cryptoKey, payload, callBackFn) => { getCardDataFromGA(cryptoKey, "users", payload, callBackFn, COMPONENT.USERS, 1) },
    }

]

export const getWebsite = (profileId, sourceMedium, startDate, endDate, intellConfigId, companyid, culture) => {
    let websiteData = [
        // original cube
        // {
        //     id: "1",
        //     layout: "{\"x\":0,\"y\":24,\"w\":6,\"h\":8}",
        //     layoutone: `{}`,
        //     layouttwo:`{}`,
        //     name: "Cookies",
        //     vizStateSecond: `{}`,
        //     mainlayout:`{}`,
        //     summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"RecordsOperationsMaxDate.declinedpercent\"],\"filters\":[{\"member\": \"Records.domain\", \"operator\": \"contains\", \"values\": [\"${urlEndpoints}\"]}],\"timeDimensions\":[{\"dimension\": \"Operations.date\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{},\"dimensions\":[]}}`,
        //     vizState: `{\"chartType\":\"donet\",\"pivotConfig\":{\"x\":[\"Operations.operation\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Consent.valueCount\"],\"timeDimensions\":[{\"dimension\": \"Operations.date\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":[],\"filters\":[{\"member\": \"Records.domain\",\"operator\": \"contains\", \"values\": [\"${urlEndpoints}\"]},{\"member\": \"Consent.value\", \"operator\": \"equals\", \"values\": [\"true\"]}],\"dimensions\":[\"Operations.operation\"]}}`,          
        //     legends:``,
        //     enableOption: true
        // },
        // dummy cube
        // original
        {
            isSummeryShown: true,
            isResultShownFromApi: true,
            id: "2",
            layout: "{\"x\":6,\"y\":24,\"w\":6,\"h\":8}",
            layoutone: `{}`,
            layouttwo: `{}`,
            name: "Audience trends",
            mainlayout: `{}`,
            vizStateSecond: `{}`,
            chartType: "line",
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Googleanalyticsdata.sessions\"],\"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsdata.gadate\" , \"compareDateRange\": [[\"${previousYearStartDate}\",\"${previousYearEndDate}\"], [\"${startDate}\",\"${endDate}\"]]}],\"order\":{},\"dimensions\":[]}}`,
            // vizState: `{\"chartType\":\"line\",\"pivotConfig\":{\"x\":[\"Googleanalyticsdata.Mname\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Googleanalyticsdata.sessions\"],\"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsdata.gadate\", \"compareDateRange\": [[\"2020-06-27\",\"2020-07-27\"], [\"${startDate}\",\"${endDate}\"]]}],\"order\":{\"Googleanalyticsdata.Monthid\":\"asc\"},\"dimensions\":[\"Googleanalyticsdata.Mname\", \"Googleanalyticsdata.Monthid\"]}}`,
            //vizState: `{\"chartType\":\"line\",\"pivotConfig\":{\"x\":[\"Googleanalyticsdata.date\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Googleanalyticsdata.sessions\"],\"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsdata.gadate\",\"granularity\":\"month\",\"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{\"Googleanalyticsdata.Monthid\":\"asc\"},\"dimensions\":[]}}`,
            legends: [{ name: "Sessions" }],
            enableOption: true,

        },
        {
            isSummeryShown: true,
            chartType: "bar",
            isResultShownFromApi: true,
            id: "5",
            layout: "{\"x\":0,\"y\":24,\"w\":6,\"h\":8}",
            layoutone: `{}`,
            layouttwo: `{}`,
            name: "Traffic source",
            mainlayout: `{}`,
            vizStateSecond: `{}`,
            // summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Googleanalyticsdata.pageviews\"],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsdata.gadate\", \"compareDateRange\": [[\"${previousYearStartDate}\",\"${previousYearEndDate}\"], [\"${startDate}\",\"${endDate}\"]]}],\"order\":{},\"dimensions\":[],\"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}]}}`,
            // vizState: `{\"chartType\":\"bar\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Googleanalyticsdata.directpageviews\",\"Googleanalyticsdata.referralpageviews\",\"Googleanalyticsdata.organicpageviews\",\"Googleanalyticsdata.socialpageviews\",\"Googleanalyticsdata.paidpageviews\"],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsdata.gadate\",\"granularity\":\"month\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{\"Googleanalyticsdata.Monthid\":\"asc\"},\"measures\": [\"Googleanalyticsdata.directpageviews\",\"Googleanalyticsdata.referralpageviews\",\"Googleanalyticsdata.organicpageviews\",\"Googleanalyticsdata.socialpageviews\",\"Googleanalyticsdata.paidpageviews\"] ,\"dimensions\":[],\"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}]}}`,
            legends: [{ name: `${sourceMedium}` }],
            enableOption: true,


        },
        // {
        //     id: "9",
        //     layout: "{\"x\":6,\"y\":24,\"w\":6,\"h\":8}",
        //     layoutone: `{}`,
        //     layouttwo:`{}`,
        //     name:"Audience trends",
        //     mainlayout:`{}`,
        //     vizStateSecond: `{}`,
        //     summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Googleanalyticsdata.sessions\"],\"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsdata.gadate\" , \"compareDateRange\": [[\"${previousYearStartDate}\",\"${previousYearEndDate}\"], [\"${startDate}\",\"${endDate}\"]]}],\"order\":{},\"dimensions\":[]}}`,
        //    // vizState: `{\"chartType\":\"line\",\"pivotConfig\":{\"x\":[\"Googleanalyticsdata.Mname\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Googleanalyticsdata.sessions\"],\"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsdata.gadate\", \"compareDateRange\": [[\"2020-06-27\",\"2020-07-27\"], [\"${startDate}\",\"${endDate}\"]]}],\"order\":{\"Googleanalyticsdata.Monthid\":\"asc\"},\"dimensions\":[\"Googleanalyticsdata.Mname\", \"Googleanalyticsdata.Monthid\"]}}`,
        //     vizState: `{\"chartType\":\"line\",\"pivotConfig\":{\"x\":[\"Googleanalyticsdata.date\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Googleanalyticsdata.sessions\"],\"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsdata.gadate\",\"granularity\":\"month\",\"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{\"Googleanalyticsdata.Monthid\":\"asc\"},\"dimensions\":[]}}`,
        //     legends: [{ name: "Sessions" }],
        //     enableOption: true
        // },
        {
            isResultShownFromApi: true,
            apiUrl: {
                url: `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetVisitorWithWatchlistByProfileId?profileid=${profileId}&intellConfigId=${intellConfigId}
            &startDate=${startDate}&endDate=${endDate}&companyid=${companyid}&packageid=5&isshort=1${localStorage.getItem('refreshCache') ? "&refreshCache=" + localStorage.getItem('refreshCache') : ""}`, type: "get", count: ""
            },
            id: "3",
            layout: "{\"x\":0,\"y\":24,\"w\":6,\"h\":10}",
            layoutone: `{}`,
            layouttwo: `{}`,
            name: "Visitor Insights",
            mainlayout: `{}`,
            vizStateSecond: `{}`,
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[\"Visitors.companycountry\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false,\"limit\":1000},\"query\":{\"dimensions\":[],\"timeDimensions\":[{\"dimension\":\"Visitor_Pageviews.Date\",\"dateRange\":[\"${startDate}\",\"${endDate}\"]}],\"order\":{},\"measures\":[\"Visitors_country.count\"],\"filters\":[{\"member\":\"Visitor_Pageviews.ProfileId\",\"operator\":\"equals\",\"values\":[\"${profileId}\"]},{\"member\":\"Visitor_Pageviews.pageviewcount\",\"operator\":\"gt\",\"values\":[\"0\"]},{\"member\":\"Visitors.companycountry\",\"operator\":\"set\"}]}}`,
            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Visitors.companycountry\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false,\"limit\":1000},\"query\":{\"dimensions\":[\"Visitors.companycountry\"],\"timeDimensions\":[{\"dimension\":\"Visitor_Pageviews.Date\",\"dateRange\":[\"${startDate}\",\"${endDate}\"]}],\"order\":{\"Visitor_Pageviews.pageviewcount\":\"desc\"},\"measures\":[\"Visitor_Pageviews.pageviewcount\"],\"filters\":[{\"member\":\"Visitor_Pageviews.ProfileId\",\"operator\":\"equals\",\"values\":[\"${profileId}\"\]},{\"member\":\"Visitor_Pageviews.count\",\"operator\":\"gt\",\"values\":[\"0\"]},{\"member\":\"Visitors.companycountry\",\"operator\":\"set\"}],\"limit\":5}}`,
            legends: ``,
            enableOption: true,
            summarylabel: "Companies",

        },
        {
            isResultShownFromApi: true,
            apiUrl: {
                url: `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetWatchedVisitorByWebsite?profileid=${profileId}&intellConfigId=${intellConfigId}
            &startDate=${startDate}&endDate=${endDate}&companyid=${companyid}&packageid=5&isshort=1${localStorage.getItem('refreshCache') ? "&refreshCache=" + localStorage.getItem('refreshCache') : ""}`, type: "get", count: ""
            },
            id: "4",
            layout: "{\"x\":6,\"y\":24,\"w\":6,\"h\":10}",
            layoutone: `{}`,
            layouttwo: `{}`,
            name: "Watchlist",
            mainlayout: `{}`,
            vizStateSecond: `{}`,
            //: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Watchlist.count\"],\"timeDimensions\":[],\"order\":{},\"filters\":[{\"member\":\"Watchlist.Companyid\",\"operator\":\"equals\",\"values\":[\"${companyid}\"]},{\"member\":\"Watchlist.Profileid\",\"operator\":\"equals\",\"values\":[\"${profileId}\"]},{\"member\":\"Watchlist.Userid\",\"operator\":\"equals\",\"values\":[\"${userId}\"]}]}}`,
            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Watchlist.Watchlistid\",\"Watchlist.Visitorid\",\"Watchlist.Visitorcountryid\",\"Watchlist.Profileid\",\"Watchlist.cmp_name\"],\"y\":[],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":[{\"dimensions\":[\"Watchlist.Watchlistid\",\"Watchlist.Visitorid\",\"Watchlist.Visitorcountryid\",\"Watchlist.Profileid\",\"Watchlist.vistorcomp\",\"Watchlist.cmp_name\",\"Watchlist.pgviews\"],\"timeDimensions\":[],\"order\":{\"Watchlist.Watchlistid\":\"asc\"},\"filters\":[{\"member\":\"Watchlist.Companyid\",\"operator\":\"equals\",\"values\":[\"${companyid}\"]},{\"member\":\"Watchlist.Profileid\",\"operator\":\"equals\",\"values\":[\"${profileId}\"]},{\"member\":\"Watchlist.Userid\",\"operator\":\"equals\",\"values\":[\"${userId}\"]}]},{\"dimensions\":[\"Visitor_Pageviews.countryid\",\"Visitors_country.cmp_id\"],\"timeDimensions\":[{\"dimension\":\"Visitor_Pageviews.Date\",\"granularity\":\"day\",\"dateRange\":[\"${startDate}\",\"${endDate}\"]}],\"order\":{\"Visitor_Pageviews.pageviewcount\":\"desc\"},\"measures\":[\"Visitor_Pageviews.pageviewcount\"],\"filters\":[{\"member\":\"Visitor_Pageviews.ProfileId\",\"operator\":\"equals\",\"values\":[\"${profileId}\"]}]}],\"widjetName\":\"Watchlist\"}`,
            legends: ``,
            enableOption: true,
            summarylabel: "Watchlist",

        },
        // original
        {
            isSummeryShown: true,
            isResultShownFromApi: true,
            chartType: "pie",
            id: "1",
            layout: "{\"x\":0,\"y\":24,\"w\":6,\"h\":8}",
            layoutone: `{}`,
            layouttwo: `{}`,
            name: "Cookies",
            vizStateSecond: `{}`,
            mainlayout: `{}`,
            //summary: `{\"chartType\":\"number\",\"pivotConfig1\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"dimensions\": [\"Orders.status\"],\"timeDimensions\": [],\"order\": {\"Orders.status\": \"asc\"},\"filters\": [{\"member\": \"Orders.status\",\"operator\": \"equals\",\"values\": [\"new\"]}]}}`,
            //vizState: `{\"chartType\":\"donet\",\"pivotConfig\":{\"x\":[\"Operations.operation\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"dimensions\": [\"Orders.status\"],\"timeDimensions\": [],\"order\": {\"Orders.totalAmount\": \"desc\"},\"filters\": [{\"member\": \"Orders.status\",\"operator\": \"contains\",\"values\": [\"new\"]}],\"measures\": [\"Orders.totalAmount\"]}}`,
            legends: ``,
            enableOption: true,

        },
        // dummy
        // {
        //     id: "5",
        //     layout: "{\"x\":0,\"y\":24,\"w\":6,\"h\":8}",
        //     layoutone: `{}`,
        //     layouttwo:`{}`,
        //     name: "Traffic source",
        //     mainlayout:`{}`,
        //     vizStateSecond: `{}`,
        //     summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"dimensions\": [\"Orders.status\"],\"timeDimensions\": [],\"order\": {\"Orders.totalAmount\": \"desc\"},\"filters\": [{\"member\": \"Orders.status\",\"operator\": \"contains\",\"values\": [\"new\"]}],\"measures\": [\"Orders.totalAmount\"]}}`,
        //     vizState: `{\"chartType\":\"bar\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{ \"measures\": [\"alertCount.htmlEmail\",\"alertCount.textHtml\"],\"timeDimensions\": [],\"order\": {},\"filters\": []}}`,
        //     legends: [{ name: `${sourceMedium}` }],
        //     enableOption: true
        // },
        {
            id: "6",
            layout: "{\"x\":6,\"y\":24,\"w\":6,\"h\":8}",
            layoutone: `{}`,
            layouttwo: `{}`,
            name: "Search",
            vizStateSecond: `{}`,
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"dimensions\":[],\"measures\":[\"Googleanalyticssearchdata.searchcount\"],\"timeDimensions\":[{\"dimension\": \"Googleanalyticssearchdata.gadate\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{},\"filters\":[{\"member\": \"Googleanalyticssearchdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}]}}`,
            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Googleanalyticssearchdata.searchkeyword\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false,\"limit\":5},\"query\":{\"dimensions\":[\"Googleanalyticssearchdata.searchkeyword\"],\"timeDimensions\":[{\"dimension\": \"Googleanalyticssearchdata.gadate\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":[[\"Googleanalyticssearchdata.searchresultviews\",\"desc\"]],\"limit\":5,\"measures\":[\"Googleanalyticssearchdata.searchresultviews\"],\"filters\":[{\"member\": \"Googleanalyticssearchdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}]}}`,
            legends: ``,
            enableOption: true,
            apiUrl: { url: `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=${startDate}&EndDate=${endDate}&profileID=${profileId}&Matrix=searchResultViews&Dimensions=searchKeyword&Component=${COMPONENT.SEARCH}&isShort=1`, type: "get", count: "" },
        },
        {
            isSummeryShown: true,
            isResultShownFromApi: true,
            chartType: "line",
            id: "7",
            layout: "{\"x\":0,\"y\":25,\"w\":12,\"h\":8}",
            layoutone: "{\"x\":0,\"y\":24,\"w\":6,\"h\":7}",
            layouttwo: "{\"x\":6,\"y\":24,\"w\":6,\"h\":7}",
            name: "User",
            nametwoo: "Top 5 contries",
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"dimensions\": [\"Orders.status\"],\"timeDimensions\": [],\"order\": {\"Orders.totalAmount\": \"desc\"},\"filters\": [{\"member\": \"Orders.status\",\"operator\": \"contains\",\"values\": [\"new\"]}],\"measures\": [\"Orders.totalAmount\"]}}`,
            //vizState: `{\"chartType\":\"line\",\"pivotConfig\":{\"x\":[\"Googleanalyticsdata.date\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"dimensions\": [\"Orders.status\"],\"timeDimensions\": [],\"order\": {\"Orders.totalAmount\": \"desc\"},\"filters\": [{\"member\": \"Orders.status\",\"operator\": \"contains\",\"values\": [\"new\"]}],\"measures\": [\"Orders.totalAmount\"]}}`,
            vizStateSecond: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Googleanalyticsusersdata.country\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false,\"limit\":5},\"query\":{\"dimensions\":[\"Googleanalyticsusersdata.country"],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsusersdata.gadate\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":[[\"Googleanalyticsusersdata.users\",\"desc\"]],\"limit\":5,\"measures\":[\"Googleanalyticsusersdata.users\"],\"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\",\"values\": [\"${profileId}\"]}]},\"tableCaption\":\"Top 5 Countries\"}`,
            legends: [{ name: "User" }],
            enableOption: true,
            apiUrl: { url: `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=${startDate}&EndDate=${endDate}&profileID=${profileId}&Matrix=users&Dimensions=country&Component=${COMPONENT.COUNTRY}&culture=${culture}&isShort=1`, type: "get", count: "" },

        },
        {
            id: "8",
            layout: "{\"x\":0,\"y\":25,\"w\":12,\"h\":10}",
            layoutone: `{}`,
            layouttwo: `{}`,
            name: "Content Insights",
            mainlayout: `{}`,
            vizStateSecond: `{}`,
            //summary: `{\"chartType\":\"number\",\"pivotConfig\":{\"x\":[],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false},\"query\":{\"measures\":[\"Googleanalyticsdata.pageviews\"],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsdata.gadate\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{},\"dimensions\":[], \"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}]}}`,
            //vizState: `{\"chartType\":\"table\",\"pivotConfig\":{\"x\":[\"Googleanalyticsdata.pagetitlepath\"],\"y\":[\"measures\"],\"fillMissingDates\":true,\"joinDateRange\":false,\"limit\":5},\"query\":{\"measures\":[\"Googleanalyticsdata.avgtimeonpage\",\"Googleanalyticsdata.pageviews\",\"Googleanalyticsdata.bouncerate\",\"Googleanalyticsdata.users\"],\"timeDimensions\":[{\"dimension\": \"Googleanalyticsdata.gadate\", \"dateRange\": [\"${startDate}\",\"${endDate}\"]}],\"order\":{\"Googleanalyticsdata.pageviews\":\"desc\"},\"limit\":5,\"dimensions\":[\"Googleanalyticsdata.pagetitlepath\"], \"filters\":[{\"member\": \"Googleanalyticsdata.profileid\", \"operator\": \"equals\", \"values\": [\"${profileId}\"]}]}}`,
            apiUrl: { url: `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetCardDataFromGAV2?StartDate=${startDate}&EndDate=${endDate}&profileID=${profileId}&Matrix=screenPageViews,averageSessionDuration,bounceRate,totalUsers&Dimensions=pageTitle,pagePath&Component=${COMPONENT.CONTENTINSIGHT}&isShort=1`, type: "get", count: "" },
            legends: ``,
            //classname: "col-full-width",
            enableOption: true,

        }

    ];
    return websiteData;
};


