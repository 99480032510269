import React, { useEffect, useState } from 'react'
import { Card, Col, OverlayTrigger, Row, Button, Tooltip, Nav, Tab } from '@themesberg/react-bootstrap'
import { useTranslation } from 'react-i18next';
import CommonMessageComponent from '../CommonMessageComponent.js';
import { DataStatusMessage } from "../../utils/constant";
import DataTableRenderer from '../DataTableRenderer.js';
import DataChartRenderer from '../DataChartRenderer.js';

export const OMXNews = () => {
    const [t, i18n] = useTranslation();

    const [summary, setSummary] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [lineChart, setLineChart] = useState({ transactionStatus: DataStatusMessage.Loading });

    const [mapData, setMapData] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [tableDataMap, setTableDataMap] = useState({ transactionStatus: DataStatusMessage.Loading });

    const [tableDataDateSelector, setTableDataDateSelector] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [tableDataSearchOptions, setTableDataSearchOptions] = useState({ transactionStatus: DataStatusMessage.Loading });

    const [tab, setTab] = useState(1);

    useEffect(() => {

        if (summary.transactionStatus !== DataStatusMessage.Loading) {
            setSummary({ transactionStatus: DataStatusMessage.Loading });
            setLineChart({ transactionStatus: DataStatusMessage.Loading });
            setMapData({ transactionStatus: DataStatusMessage.Loading });
            setTableDataMap({ transactionStatus: DataStatusMessage.Loading });
            setTableDataDateSelector({ transactionStatus: DataStatusMessage.Loading })
            setTableDataSearchOptions({ transactionStatus: DataStatusMessage.Loading })
        }
    }, [])


    const createTooltip = (tooltipKey, placement) => {
        if (tooltipKey !== "") {
            return (
                <OverlayTrigger
                    placement={placement}
                    trigger={["hover", "focus"]}
                    overlay={
                        // <Tooltip>{t("header.VisitorAnalyticsTooltip")}</Tooltip>
                        <Tooltip>
                            <div
                            // dangerouslySetInnerHTML={{
                            //   __html: t("header.VisitorAnalyticsTooltip", {
                            //     interpolation: { escapeValue: false },
                            //   }),
                            // }}
                            >{tooltipKey}</div>
                        </Tooltip>
                    }
                >

                    <Button variant="white" className="btn-help border-0 p-1">
                        <i className="far fa-question-circle lg"></i>
                    </Button>
                </OverlayTrigger>
            );
        }
    }

    return (
        <>
            {/* <div className="d-inline-block tools-dashboard-card-loader w-100">
                <Row className="mb-3">
                    <Col lg={12}>
                        <h2 className="h2 mb-0 ms-3">{t("header.OMX_News")}</h2>
                    </Col>
                </Row>
            </div> */}

            {/* need to add condition based class using summary */}
            <div className={summary.transactionStatus !== DataStatusMessage.DataAvailable ?
                "d-inline-block tools-dashboard-card-loader w-100" : "d-inline-block w-100"}>
                <Card border='light' className='bg-white shadow-sm mb-4 ms-3'>
                    <Card.Header className='ps-3 pt-3'>
                        {t("header.Summary")}
                        {createTooltip(t("header.SPC_Summary_Tooltip"), "right")}
                    </Card.Header>
                    <Card.Body className=' pb-0'>
                        {/* This needs to uncommented */}
                        {
                            summary.transactionStatus !== DataStatusMessage.DataAvailable &&
                            <CommonMessageComponent transactionStatus={DataStatusMessage.DataUnAvailable}></CommonMessageComponent>
                        }
                        {summary.transactionStatus === DataStatusMessage.DataAvailable &&
                            <Row className="tools-overview-key-insight-container">
                                <Col md={3}>
                                    <div className="pod equation-calc-card-container ng-scope">
                                        <div className="card card-key-insights w-100">
                                            <div className="card-body">
                                                <div className="key-insight-value ng-binding" title={summary.result?.ScreenPageViews}>
                                                    {summary.result?.ScreenPageViews}
                                                </div>
                                                <div className="card-value-title ng-binding key-insight-title-box">
                                                    {t("header.Page_views")}
                                                    {createTooltip(t("header.SPC_Summary_pageViews_Tooltip"), "right")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="pod equation-calc-card-container ng-scope">
                                        <div className="card card-key-insights w-100">
                                            <div className="card-body">
                                                <div className="key-insight-value ng-binding" title={summary.result?.ScreenPageViewsPerUser}>
                                                    {summary.result?.ScreenPageViewsPerUser}

                                                </div>
                                                <div className="card-value-title ng-binding key-insight-title-box">
                                                    {t("header.Views_per_user_average")}
                                                    {/* {t("header.VisitorInsightTotalVisitorsLabel")} */}
                                                    {createTooltip(t("header.SPC_Summary_viewsperuseraverage_Tooltip"), "right")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="pod equation-calc-card-container ng-scope">
                                        <div className="card card-key-insights w-100">
                                            <div className="card-body">
                                                <div className="key-insight-value ng-binding" title={summary.result?.UserEngagementDuration}>
                                                    {summary.result?.UserEngagementDuration}

                                                </div>
                                                <div className="card-value-title ng-binding key-insight-title-box">
                                                    {t("header.Average_Engagement_Time")}
                                                    {/* {t("header.VisitorInsightTotalVisitorsLabel")} */}
                                                    {createTooltip(t("header.SPC_Summary_averageengagementtime_Tooltip"), "right")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="pod equation-calc-card-container ng-scope">
                                        <div className="card card-key-insights w-100">
                                            <div className="card-body">
                                                <div className="key-insight-value ng-binding" title={summary.result?.ActiveUsers}>
                                                    {summary.result?.ActiveUsers}

                                                </div>
                                                <div className="card-value-title ng-binding key-insight-title-box">
                                                    {t("header.Active_Users")}
                                                    {/* {t("header.VisitorInsightTotalVisitorsLabel")} */}
                                                    {createTooltip(t("header.SPC_Summary_activeusers_Tooltip"), "left")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        }
                    </Card.Body>
                </Card>
            </div>

            <div className="d-inline-block tools-dashboard-card-loader w-100">
                <Card border="light" className="bg-white shadow-sm mb-4 ms-3 p-3">
                    <Card.Body>
                        <Row className="tools-overview-subtab">
                            <Col md={12}>
                                {
                                    lineChart.transactionStatus === DataStatusMessage.DataAvailable && summary.transactionStatus === DataStatusMessage.DataAvailable &&
                                    <Tab.Container defaultActiveKey="tab1" >
                                        <Nav fill variant="pills" className="flex-column flex-sm-row tab subtab ms-0 me-0 mt-1">
                                            <Nav.Item className="pe-0">
                                                <Nav.Link eventKey="tab1" onClick={() => { setTab(1) }} className="mb-sm-3 mb-md-0">
                                                    <span className="d-inline-block w-100 text-left tools-tab-label-text">{t("header.Event_Count")}
                                                        {createTooltip(t("header.SPC_eventcount_Tooltip"), "right")}
                                                    </span>
                                                    <span className="d-inline-block w-100 text-left tools-tab-count"> {summary.result?.EventCount}</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="tab2" onClick={() => { setTab(2) }} className="mb-sm-3 mb-md-0">
                                                    <span className="d-inline-block w-100 text-left tools-tab-label-text">{t("header.Total_Users")}
                                                        {createTooltip(t("header.SPC_totalusers_Tooltip"), "right")}
                                                    </span>
                                                    <span className="d-inline-block w-100 text-left tools-tab-count">{summary.result?.TotalUsers}</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="tab3" onClick={() => { setTab(3) }} className="mb-sm-3 mb-md-0">
                                                    <span className="d-inline-block w-100 text-left tools-tab-label-text">{t("header.Event_Count_Per_User")}
                                                        {createTooltip(t("header.SPC_eventcountperuser_Tooltip"), "right")}
                                                    </span>
                                                    <span className="d-inline-block w-100 text-left tools-tab-count">{summary.result?.EventCountPerUser}</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="tab4" onClick={() => { setTab(4) }} className="mb-sm-3 mb-md-0">
                                                    <span className="d-inline-block w-100 text-left tools-tab-label-text">{t("header.Event_Value")}
                                                        {createTooltip(t("header.SPC_eventvalue_Tooltip"), "right")}
                                                    </span>
                                                    <span className="d-inline-block w-100 text-left tools-tab-count">{summary.result?.EventValue}</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="tab1" className="py-2">
                                                {/* {tab === 1 && lineChart.result?.EventCount?.count > 0 &&
                                                    <DataChartRenderer
                                                        data={
                                                            lineChart.result.EventCount
                                                        }
                                                        chartType="line" />
                                                }
                                                {!lineChart.result.EventCount.count &&
                                                    <CommonMessageComponent transactionStatus={DataStatusMessage.DataUnAvailable}></CommonMessageComponent>
                                                } */}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tab2" className="py-2">
                                                {/* {tab === 2 && lineChart.result?.TotalUsers?.count > 0 &&
                                                    <DataChartRenderer
                                                        data={
                                                            lineChart.result.TotalUsers
                                                        }
                                                        chartType="line" />
                                                }
                                                {!lineChart.result.TotalUsers.count &&
                                                    <CommonMessageComponent transactionStatus={DataStatusMessage.DataUnAvailable}></CommonMessageComponent>
                                                } */}

                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tab3" className="py-2">
                                                {/* {tab === 3 && lineChart.result?.EventCountPerUser?.count > 0 &&
                                                    <DataChartRenderer
                                                        data={
                                                            lineChart.result.EventCountPerUser
                                                        }
                                                        chartType="line" />
                                                }
                                                {!lineChart.result.EventCountPerUser.count &&
                                                    <CommonMessageComponent transactionStatus={DataStatusMessage.DataUnAvailable}></CommonMessageComponent>
                                                } */}

                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tab4" className="py-2">
                                                {/* {tab === 4 && lineChart.result?.EventValue?.count > 0 &&
                                                    <DataChartRenderer
                                                        data={
                                                            lineChart.result.EventValue
                                                        }
                                                        chartType="line" />
                                                }
                                                {!lineChart.result.EventValue.count &&
                                                    <CommonMessageComponent transactionStatus={DataStatusMessage.DataUnAvailable}></CommonMessageComponent>
                                                } */}
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                }

                                {/* {
                                    (lineChart.transactionStatus !== DataStatusMessage.DataAvailable || summary.transactionStatus !== DataStatusMessage.DataAvailable) &&
                                    <CommonMessageComponent transactionStatus={lineChart.transactionStatus}></CommonMessageComponent>
                                } */}

                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>

            <div className="d-inline-block tools-dashboard-card-loader w-100">
                <Card border="light" className="bg-white shadow-sm mb-4 ms-3">
                    <Card.Header className="pt-3 ps-3">
                        {t("header.cardheading_views_by_country")}{createTooltip(t("header.cardheading_views_by_country_tooltip"), "right")}
                    </Card.Header>
                    <Card.Body>
                        {/* change transaction status from DataStatusMessage.DataUnAvailable to mapData.transactionStatus */}
                        {
                            mapData.transactionStatus !== DataStatusMessage.DataAvailable &&
                            <Row>
                                <Col md={12}>
                                    <CommonMessageComponent transactionStatus={DataStatusMessage.DataUnAvailable}></CommonMessageComponent>
                                </Col>
                            </Row>
                        }
                        {
                            mapData.transactionStatus === DataStatusMessage.DataAvailable &&
                            <Row>
                                <Col md={12}>
                                    <DataChartRenderer
                                        data={
                                            mapData.result
                                        }
                                        title={t("header.cardheading_views_by_country")}
                                        chartType="map" />
                                </Col>
                                <Col md={12} className='mt-2'>
                                    {tableDataMap.transactionStatus !== DataStatusMessage.Loading &&
                                        <DataTableRenderer
                                            header={tableDataMap.header}
                                            dataSet={tableDataMap.data}
                                            transactionStatus={tableDataMap.transactionStatus}
                                            customAttributes={{ displayRowsPerPage: 15, showPagination: true }}
                                        ></DataTableRenderer>
                                    }
                                </Col>
                            </Row>
                        }
                    </Card.Body>
                </Card>
            </div>

            <Row className="half-width-card-container">
                <Col md={6}>
                    <div className="d-inline-block tools-dashboard-card-loader w-100">
                        <Card border="light" className="bg-white shadow-sm mb-4 ms-3">
                            <Card.Header className="pt-3 ps-3">
                                {t("header.Spc_Advanced_Search_Options")}{createTooltip(t("header.Spc_Advanced_Search_Options_Tooltip"), "right")}
                            </Card.Header>
                            <Card.Body>
                                {/* {
                                tableDataSearchOptions.transactionStatus === DataStatusMessage.Loading &&
                                <CommonMessageComponent transactionStatus={tableDataSearchOptions.transactionStatus}></CommonMessageComponent>
                            } */}
                                {/* {
                                tableDataSearchOptions.transactionStatus !== DataStatusMessage.Loading &&
                                <DataTableRenderer
                                    header={tableDataSearchOptions.header}
                                    dataSet={tableDataSearchOptions.data}
                                    transactionStatus={tableDataSearchOptions.transactionStatus}
                                    headerSummaryData={tableDataSearchOptions.headerSummary}
                                    customAttributes={{ displayRowsPerPage: 7, showHeaderSummary: true, showPagination: true }}
                                ></DataTableRenderer>
                            } */}
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="d-inline-block tools-dashboard-card-loader w-100 md-ps-1">
                        <Card border="light" className="bg-white shadow-sm mb-4">
                            <Card.Header className="pt-3 ps-3">
                                {t("header.Share_Chart_Date_Selector")}{createTooltip(t("header.Spc_Share_Chart_Date_Selector_Tooltip"), "right")}
                            </Card.Header>
                            <Card.Body>
                                {
                                    tableDataDateSelector.transactionStatus === DataStatusMessage.Loading &&
                                    <CommonMessageComponent transactionStatus={DataStatusMessage.DataUnAvailable}></CommonMessageComponent>
                                }
                                {
                                    tableDataDateSelector.transactionStatus !== DataStatusMessage.Loading &&
                                    <DataTableRenderer
                                        header={tableDataDateSelector.header}
                                        dataSet={tableDataDateSelector.data}
                                        transactionStatus={tableDataDateSelector.transactionStatus}
                                        headerSummaryData={tableDataDateSelector.headerSummary}
                                        customAttributes={{ displayRowsPerPage: 7, showHeaderSummary: true, showPagination: true }}
                                    ></DataTableRenderer>
                                }
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
            </Row>

        </>
    )

}
