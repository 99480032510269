
import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';
import RGL, { WidthProvider } from 'react-grid-layout';
import { useMutation } from '@apollo/react-hooks';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
// import { GET_DASHBOARD_ITEMS } from '../graphql/queries';
// import { UPDATE_DASHBOARD_ITEM } from '../graphql/mutations';
import { Row, Col, Button, Form, InputGroup, FormCheck, Image } from '@themesberg/react-bootstrap';
import Loader from '../components/VoltLoader';
import DropdownWithIconComponent from "../components/DropdownWithIcons";
import { isMobile } from "react-device-detect";
import { withRouter } from "react-router";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faChevronLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import { DEFAULT_WEBSITE_DASHBORD, DEFAULT_PROFILE_DASHBORD, object_equals, NULL_VALUE_MESSAGE_DROPDOWN } from "../AppConfig";
import { dashbordDateRangeList } from "../utils/datetime";
import ButtonComponent from "../components/Buttons";
import AppContext from '../components/AppContext';
import { useTranslation } from 'react-i18next';
import CommonHeader from '../components/CommonHeader';
import PagePerformanceChart from './PagePerformanceChart';
import request from '../apis/request';
import { baseURL, METHODS } from '../apis/utilities/constant';
import LoaderImage from "../assets/img/LoaderGraphic.svg";
import WebsiteMarketingPages from '../pages/MarketingPages/WebsiteMarketing';
import { decryptData } from '../utils/crypto';

const ReactGridLayout = WidthProvider(RGL);


const PagePerformance = ({ children,
  //dashboardItems,
  //selectedProfileId,
  // setSelectedProfileId,
  //selectedWebsiteId,
  //setSelectedWebsiteId,
  //history,
  //location, startDate, setStartDate, endDate, setEndDate, profileData, setProfileData,
  handleBackButtonClick }) => {

  const contextData = useContext(AppContext);
  // // const [websiteId, setWebsiteId] = useState(0);
  // const [selectedWebsiteId, setSelectedWebsiteId] = useState({});
  // // const [profileId, setProfileId] = useState(0);
  // const [selectedProfileId, setSelectedProfileId] = useState({});
  // const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);

  // const [datePickerValue, setDatePickerValue] = useState("");
  // const [selectedDatePicker, setSelectedDatePicker] = useState({});
  // const [datePickerCompareValue, setDatePickerCompareValue] = useState("");
  // const [companyIdValue, setcompanyIdValue] = useState("");
  // const [toggle, setToggle] = useState(false);
  const [t, i18n] = useTranslation();
  // const [siteData, setSiteData] = useState([])
  // const [updateKey, setUpdateKey] = useState(0)
  // const [websiteData, setWebsiteData] = useState([])
  const [selectedProfileIdNew, setSelectedProfileIdNew] = useState("")
  const [selectedWebsiteIdNew, setSelectedWebsiteIdNew] = useState("");
  const [autoLoad, setAutoLoad] = useState("");
  const [profilesList, setProfilesList] = useState([]);
  const [blurLoader, setBlurLoader] = useState(false);
  // const [tabdatahide, setTabDataHide] = useState(0);
  const [websiteList, setWebsiteList] = useState([]);
  //   const [updateDashboardItem] = useMutation(UPDATE_DASHBOARD_ITEM, {
  //     refetchQueries: [
  //       {
  //         query: GET_DASHBOARD_ITEMS,
  //       },
  //     ],
  //   });

  // const showLeftIcon = isMobile ? true : false;

  // const cubejsApi = cubejs(
  //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJteWFwcGlkIjozLCJpYXQiOjE2MTgyMjkzNDIsInVzZXJfaWQiOjM4MDgxLCJwbGF0Zm9ybV9pZCI6MSwic2VydmljZV9pZCI6OCwiY29tcGFueV9pZCI6OTM0NX0.lYSmPrF55TH3y8HQk96khmXn08XBt5nkUh7vzxfyak4',
  //   { apiUrl: `${process.env.REACT_APP_CUBESERVERURL}/cubejs-api/v1` }
  // );


  // useEffect(() => {

  //   if (profilesList != null && profilesList.length) {
  //     let profilesData = profilesList.filter((e) => { return e.WebsiteId === selectedWebsiteIdNew["WebsiteList.WebsiteId"] });
  //     let profileDataUpdated = profilesData.map((e) => ({ ["WebsiteProfiles.ProfileId"]: e.ProfileId, ["WebsiteProfiles.DisplayName"]: e.DisplayName, ["WebsiteProfiles.IntelConfigId"]: e.IntelConfigId }));
  //     if (selectedWebsiteIdNew["WebsiteList.WebsiteId"]) {
  //       renderWebsiteProfiles({ resultSet: profileDataUpdated })
  //     }

  //   }

  // }, [selectedWebsiteIdNew["WebsiteList.WebsiteId"], profilesList])
  // useEffect(() => {
  //   setTabDataHide(0);
  //   localStorage.setItem("websiteData", JSON.stringify([]))
  //   localStorage.setItem("profileData", JSON.stringify([]))
  //   getWebsites();
  //   //setSelectedProfileIdNew({})
  // }, [contextData.userId, contextData.companyId]);

  // const getWebsites = () => {
  //   setBlurLoader(true);
  //   request(baseURL + `/CommonV2/GetWebsiteChannelWebsiteByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
  //     METHODS.GET,
  //     {},
  //     {
  //       Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
  //     })
  //     .then((res) => {
  //       let resultData = [];
  //       if (res.result !== null && res.result.length) {
  //         resultData = res.result.map((e) => ({ cmsplatformid: res.platformid, ["WebsiteList.WebsiteName"]: e.WebsiteName, ["WebsiteList.WebsiteId"]: e.WebsiteId, ["WebsiteList.WebsiteUrl"]: e.WebsiteUrl }))
  //       }
  //       rendersite({ resultSet: resultData })
  //     })
  //     .catch((err) => {

  //     })

  //   request(baseURL + `/WebsiteAnalytics/GetWebsites?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
  //     METHODS.GET,
  //     {},
  //     {
  //       Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
  //     })
  //     .then((res) => {
  //       setProfilesList(res.result)
  //     })
  //     .catch((err) => {

  //     })
  // }

  // useEffect(() => {
  //   if (contextData.userId > 0 && contextData.companyId > 0) {

  //     setcompanyIdValue(contextData.companyId);
  //     // Fetching values from Local storage in case of Page Refresh
  //     if (localStorage.getItem("IsPageRefresh") == "1") {
  //       if (localStorage.getItem("WebsiteListWebsiteId")) {
  //         setSelectedWebsiteIdNew(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")));
  //       }
  //       if (localStorage.getItem("WebsiteProfilesIntelConfigId") && localStorage.getItem("WebsiteProfilesIntelConfigId") != "")
  //         setSelectedProfileIdNew(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")));
  //       if (localStorage.getItem("SelectedDatePicker") && localStorage.getItem("SelectedDatePicker") != "")
  //         setSelectedDatePicker(JSON.parse(localStorage.getItem("SelectedDatePicker")));
  //     }
  //     // contextData.changeSelectedWebsite({});
  //     // setSelectedWebsiteId({});
  //     //setSelectedWebsiteIdNew({})
  //   }
  //   setAutoLoad(false)
  // }, [contextData.userId, contextData.companyId]);

  // useEffect(() => {
  //   localStorage.setItem("websiteData", JSON.stringify([]))
  //   localStorage.setItem("profileData", JSON.stringify([]))
  //   //setSelectedProfileIdNew({})
  // }, [contextData.companyId]);

  // const compareItem = (items, compareItem) => {
  //   if (items.length > 0) {
  //     for (let i = 0; i <= items.length; i++) {
  //       if (object_equals(items[i], compareItem)) {
  //         return true;
  //       }
  //     }
  //   }
  // }

  // const onWebsiteDropdownChange = (data) => {

  //   if (!object_equals(selectedWebsiteId, data)) {
  //     setSelectedProfileIdNew({});
  //     //localStorage.setItem("WebsiteProfilesIntelConfigId", "");// Adding WebsiteProfiles.IntelConfigId to local stoarge in case of Page Refresh
  //   }
  //   //setWebsiteData(Math.random())

  //   // contextData.changeSelectedWebsite(data);
  //   setSelectedWebsiteIdNew(data)
  //   //setSelectedWebsiteId(data);


  // };

  // const onProfileDropdownChange = (data) => {
  //   const intellConfigId = data["WebsiteProfiles.IntelConfigId"];
  //   contextData.changeIntelConfigId(intellConfigId);
  //   // contextData.changeSelectedProfile(data);
  //   // setSelectedProfileId(data);
  //   setSelectedProfileIdNew(data)

  //   setUpdateKey(Math.random())
  // };

  // const setDefaultPickerValueOnDateChange = (dateType) => {
  //   if (datePickerValue.length > 0) {
  //     const datePickerSplit = datePickerValue.split("-");
  //     let datePickerVle;
  //     if (dateType == "startDate") {

  //       datePickerVle = `${moment(startDate).format("MMM DD,YYYY")} - ${moment(datePickerSplit[1]).format("MMM DD,YYYY")}`;
  //       setDtPickerCompareValueOnDatePickerChange(startDate, moment(datePickerSplit[1]));
  //     }
  //     else if (dateType == "endDate") {
  //       datePickerVle = `${moment(moment(datePickerSplit[0])).format("MMM DD,YYYY")} - ${moment(endDate).format("MMM DD,YYYY")}`;
  //       setDtPickerCompareValueOnDatePickerChange(moment(datePickerSplit[0]), endDate);
  //     }
  //     setDatePickerValue(datePickerVle);
  //   }
  // };

  // const setDtPickerCompareValueOnDatePickerChange = (startDate, endDate) => {
  //   endDate = moment(endDate);
  //   startDate = moment(startDate);

  //   let previousEndDate = moment(endDate).subtract(1, 'years').format("MMM DD,YYYY");
  //   let previousStartDate = moment(startDate).subtract(1, 'years').format("MMM DD,YYYY");
  //   let datePickerCmpr = `${previousStartDate} - ${previousEndDate}`;
  //   setDatePickerCompareValue(datePickerCmpr);

  // };
  // const applyFilters = () => {
  //   if (JSON.stringify(selectedProfileIdNew).length == 2) {
  //     setTabDataHide(1);
  //   }
  //   else {
  //     setTabDataHide(-1);
  //   }
  //   setBlurLoader(false);
  //   const websiteId = selectedWebsiteIdNew["WebsiteList.WebsiteId"];
  //   contextData.changeWebsiteId(websiteId);
  //   contextData.changeSelectedWebsite(selectedWebsiteIdNew);
  //   setSelectedWebsiteId(selectedWebsiteIdNew)
  //   contextData.changeSelectedProfile(selectedProfileIdNew);
  //   setSelectedProfileId(selectedProfileIdNew);
  //   localStorage.setItem("WebsiteListWebsiteId", JSON.stringify(selectedWebsiteIdNew));// Adding WebsiteList.WebsiteId to local stoarge in case of Page Refresh
  //   localStorage.setItem("WebsiteProfilesIntelConfigId", JSON.stringify(selectedProfileIdNew));// Adding WebsiteProfiles.IntelConfigId to local stoarge in case of Page Refresh

  // }
  // const cancelFilters = () => {
  //   onWebsiteDropdownChange(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
  //   onProfileDropdownChange(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")))
  // }
  // const rendersite = ({ resultSet, error, pivotConfig, request }) => {

  //   // if (error) {
  //   //   return <div>{error.toString()}</div>;
  //   // }

  //   // if (!resultSet) {
  //   //   return <Loader show={false} />;
  //   // }

  //   localStorage.setItem("websiteData", JSON.stringify([]))
  //   localStorage.setItem("websiteData", JSON.stringify(resultSet))
  //   if (resultSet !== null && resultSet.length) {
  //     setUpdateKey(JSON.stringify(resultSet))

  //     if (resultSet !== null && resultSet.length && selectedWebsiteIdNew !== undefined && Object.keys(selectedWebsiteIdNew).length <= 0) {
  //       if (localStorage.getItem("WebsiteListWebsiteId")) {
  //         onWebsiteDropdownChange(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
  //       } else {
  //         onWebsiteDropdownChange(resultSet[0])
  //       }

  //     }

  //   }


  //   return (

  //     <>


  //     </>
  //   );

  // }

  // const renderWebsiteProfiles = ({ resultSet, error, pivotConfig, request }) => {

  //   // if (error) {
  //   //   return <div>{error.toString()}</div>;
  //   // }

  //   // if (!resultSet) {
  //   //   return <Loader show={false} />;
  //   // }
  //   localStorage.setItem("profileData", JSON.stringify([]))
  //   if (resultSet !== undefined && resultSet.length && Object.keys(selectedProfileIdNew).length <= 0) {
  //     //setTimeout(()=>{
  //     if (compareItem(resultSet, JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId"))) === true) {

  //       onProfileDropdownChange(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")))
  //       //setSelectedProfileIdNew(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")));
  //       setAutoLoad(true)

  //     } else {

  //       onProfileDropdownChange(resultSet[0])
  //       //contextData.changeSelectedProfile(resultSet.tablePivot(pivotConfig)[0]);
  //       setSelectedProfileIdNew(resultSet[0]);
  //       setAutoLoad(true)
  //     }

  //     //},3000)

  //   }

  //   if (resultSet !== undefined && resultSet.length) {
  //     localStorage.setItem("profileData", JSON.stringify(resultSet))
  //     setUpdateKey(JSON.stringify(resultSet))
  //     // if(Object.keys(selectedProfileIdNew).length){
  //     //   setAutoLoad(true)
  //     // }


  //   } else if (resultSet !== undefined && resultSet.length <= 0) {
  //     setUpdateKey(JSON.stringify(resultSet))
  //     setAutoLoad(true)
  //   } else {

  //   }
  //   return (<></>);

  // };
  // const renderWebsiteProfiles = ({ resultSet, error, pivotConfig, request }) => {

  //   if (error) {
  //     return <div>{error.toString()}</div>;
  //   }

  //   if (!resultSet) {
  //     return <Loader show={false} />;
  //   }
  //   if (resultSet !== null && resultSet.tablePivot(pivotConfig).length && Object.keys(selectedProfileIdNew).length <= 0) {
  //     //setTimeout(()=>{
  //     if (compareItem(resultSet.tablePivot(pivotConfig), JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")))) {
  //       onProfileDropdownChange(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")))
  //       setAutoLoad(true)
  //     } else {
  //       onProfileDropdownChange(resultSet.tablePivot(pivotConfig)[0])
  //       setSelectedProfileIdNew(resultSet.tablePivot(pivotConfig)[0])
  //       setAutoLoad(true)
  //     }

  //     //},3000)

  //     //
  //   }
  //   localStorage.setItem("profileData", JSON.stringify([]))
  //   if (resultSet !== null && resultSet.tablePivot(pivotConfig).length) {
  //     localStorage.setItem("profileData", JSON.stringify(resultSet.tablePivot(pivotConfig)))
  //     setUpdateKey(JSON.stringify(resultSet.tablePivot(pivotConfig)))
  //     // if(Object.keys(selectedProfileIdNew).length){
  //     //   setAutoLoad(true)
  //     // }


  //   } else if (resultSet !== null) {
  //     setUpdateKey(JSON.stringify(resultSet.tablePivot(pivotConfig)))
  //     setAutoLoad(true)
  //   } else {

  //   }


  //   return (

  //     <></>
  //   );

  // };

  // const onDatePickerDropdownChange = (data) => {

  //   if (data && Object.keys(data).length > 0) {
  //     if (data.key.length > 0) {
  //       const datePickerSplit = data.key.split("-");
  //       contextData.changeStartDate(moment(datePickerSplit[0]));
  //       contextData.changeEndDate(moment(datePickerSplit[1]));
  //       if (data.compareOn || data.showDatePicker) {
  //         const compareOnStartDate = moment(datePickerSplit[0]).add(-data.compareOn, 'days').format("MMM DD,YYYY");
  //         const compareOnEndDate = moment(datePickerSplit[1]).add(-data.compareOn, 'days').format("MMM DD,YYYY");
  //         const compareOnValue = `${compareOnStartDate} - ${compareOnEndDate}`;
  //         setDatePickerValue(`${moment(datePickerSplit[0]).format("MMM DD,YYYY")} - ${moment(datePickerSplit[1]).format("MMM DD,YYYY")}`);
  //         setDatePickerCompareValue(compareOnValue);
  //       }
  //     }

  //     if (data.showDatePicker)
  //       setShowCustomDatePicker(true);
  //     else
  //       setShowCustomDatePicker(false);
  //   }
  //   // if (value) {
  //   // setDatePickerValue(data.key);
  //   setSelectedDatePicker(data)
  //   localStorage.setItem("SelectedDatePicker", JSON.stringify(data));// Adding SelectedDatePicker to local stoarge in case of Page Refresh

  //   // }

  // };

  // const validStartDate = (current) => {
  //   let currentDay = moment(endDate).format("MM/DD/YYYY");
  //   return current.isBefore(currentDay);
  // };

  // const validEndDate = (current) => {
  //   // let currentDay = moment().format();
  //   let yesterday = moment().subtract(1, 'day');
  //   return current.isBefore(yesterday);
  // };

  // const toggleChange = () => {
  //   setToggle(!toggle);
  // };

  // const onLeftIconClick = (e) => {
  //   e.preventDefault();
  //   history.goBack();
  //   // window.history.back();
  //   // history.push(`${location.pathname}`);
  // };


  //slider dropdown
  // const onClickOutsideListener = () => {
  //   setShowCustomDatePicker(false);
  //   document.removeEventListener("click", onClickOutsideListener)
  // }

  // const profileQuery = useMemo(() => <>
  //   <QueryRenderer
  //     query={{
  //       "dimensions": [
  //         "WebsiteProfiles.ProfileId",
  //         "WebsiteProfiles.DisplayName",
  //         "WebsiteProfiles.IntelConfigId"
  //       ],
  //       "timeDimensions": [],
  //       "order": {
  //         "WebsiteProfiles.DisplayName": "asc"
  //       },
  //       "filters": [
  //         {
  //           "member": "WebsiteProfiles.WebsiteId",
  //           "operator": "equals",
  //           "values": [`${selectedWebsiteIdNew["WebsiteList.WebsiteId"]}`]
  //         },
  //         {
  //           "member": "WebsiteProfiles.CompanyId",
  //           "operator": "equals",
  //           "values": [`${contextData.companyId}`]
  //         },
  //         {
  //           "member": "WebsiteProfiles.UserId",
  //           "operator": "equals",
  //           "values": [`${contextData.userId}`]
  //         }
  //       ],
  //     }}
  //     cubejsApi={cubejsApi}
  //     resetResultSetOnChange={true}
  //     render={(props) => renderWebsiteProfiles({
  //       ...props,
  //       chartType: 'dropdown',
  //       pivotConfig: {
  //         "x": [
  //           "WebsiteProfiles.ProfileId",
  //           "WebsiteProfiles.DisplayName",
  //           "WebsiteProfiles.IntelConfigId"

  //         ],
  //         "y": ["WebsiteProfiles.DisplayName"],
  //         "fillMissingDates": true,
  //         "joinDateRange": false
  //       }
  //     })}
  //   />
  // </>, [selectedWebsiteIdNew])


  // =================
  useEffect(() => {
    if (contextData.companyId > 0) {

      if (localStorage.getItem("websiteData") && JSON.parse(localStorage.getItem("websiteData")).length > 0
        && localStorage.getItem("AllprofileData") && JSON.parse(localStorage.getItem("AllprofileData")).length > 0) {

        let websiteList = JSON.parse(localStorage.getItem("websiteData"));
        let selectedWebsite = JSON.parse(localStorage.getItem("WebsiteListWebsiteId"));

        renderWebsite(websiteList, selectedWebsite)

      }
      else if (!localStorage.getItem("websiteData") || !localStorage.getItem("AllprofileData")) {
        if (!localStorage.getItem("websiteData")) {
          getWebsites();
        }
        else if (!localStorage.getItem("AllprofileData")) {
          getProfiles(JSON.parse(localStorage.getItem("websiteData")));
        }
      }
    }
  }, [contextData.companyId])

  const renderWebsite = (resultSet, selectedWebsiteValue) => {

    setWebsiteList(resultSet)
    setSelectedWebsiteIdNew(selectedWebsiteValue);

    renderProfileList(selectedWebsiteValue, 0);

  };

  const renderProfileList = (selectedWebsiteValue, isTempUpdate) => {

    if (localStorage.getItem("AllprofileData") && JSON.parse(localStorage.getItem("AllprofileData")).length > 0) {

      let profileList = JSON.parse(localStorage.getItem("AllprofileData"));
      let selectedWebsiteProfiles = profileList.filter((e) => { return e.WebsiteId === selectedWebsiteValue["WebsiteList.WebsiteId"] });

      if (selectedWebsiteProfiles.length > 0) {
        localStorage.setItem("cookieProfileData", JSON.stringify(selectedWebsiteProfiles));
        let profileData = selectedWebsiteProfiles.map((e) => ({ ["WebsiteProfiles.ProfileId"]: e.ProfileId, ["WebsiteProfiles.DisplayName"]: e.DisplayName, ["WebsiteProfiles.IntelConfigId"]: e.IntelConfigId }));

        setProfilesList(profileData)

        let selectedProfile = profileData[0];


        if (isTempUpdate === 0) {

          let storedProfile = JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId"));
          if (storedProfile && Object.keys(storedProfile).length > 0
            && profileData.some(obj => obj["WebsiteProfiles.ProfileId"] === storedProfile["WebsiteProfiles.ProfileId"])) {
            selectedProfile = storedProfile;
          };

          localStorage.setItem("profileData", JSON.stringify(profileData))
        }
        setSelectedProfileIdNew(selectedProfile);

      }
      else {


        setProfilesList([])
        setSelectedProfileIdNew({});
        if (isTempUpdate === 0) {
          localStorage.setItem("profileData", JSON.stringify([]))
        }
      }

    }
    setAutoLoad(true)
  }
  const getWebsites = () => {

    //setBlurLoader(true);

    request(baseURL + `/CommonV2/GetWebsiteChannelWebsiteByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {
        let resultData = [];

        if (res.result && res.result.length > 0) {
          resultData = res.result.map((e) => ({ cmsplatformid: res.platformid, ["WebsiteList.WebsiteName"]: e.WebsiteName, ["WebsiteList.WebsiteId"]: e.WebsiteId, ["WebsiteList.WebsiteUrl"]: e.WebsiteUrl }))
        }

        if (resultData.length > 0) {
          localStorage.setItem("websiteData", JSON.stringify(resultData))
          getProfiles(resultData);
        }
        else {
          localStorage.setItem("websiteData", JSON.stringify([]))
          localStorage.setItem("AllprofileData", JSON.stringify([]))
        }

      })
      .catch((err) => {
        //setBlurLoader(false);
      })

  }
  const getProfiles = (resultData) => {
    request(baseURL + `/WebsiteAnalytics/GetWebsites?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
      })
      .then((res) => {

        if (res.result != null && res.result.length > 0) {

          let result = removeDuplicateObjects(res.result, 'IntelConfigId')
          localStorage.setItem("AllprofileData", JSON.stringify(result))

          // let selectedWebsiteProfiles = result.filter((e) => { return e.WebsiteId === resultData[0]["WebsiteList.WebsiteId"] });

          // if (selectedWebsiteProfiles.length > 0) {

          // }
          // else {

          // }
        }
        else {
          localStorage.setItem("profileData", JSON.stringify([]))
        }
        // localStorage.setItem("websiteData", JSON.stringify(resultData))
        if (!localStorage.getItem("WebsiteListWebsiteId")) {
          renderWebsite(resultData, resultData[0])
        }
        else {
          renderWebsite(resultData, JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
        }
        //setBlurLoader(false);
        if (res.result.length == 0) {
          setAutoLoad(true);
        }

      })
      .catch((err) => {
        //setBlurLoader(false);
      })
  }
  function removeDuplicateObjects(array, key) {
    const seen = {};
    return array.filter(item => {
      const itemKey = key ? item[key] : item;
      return seen.hasOwnProperty(itemKey) ? false : (seen[itemKey] = true);
    });
  }
  const onWebsiteDropdownChange = (data) => {
    if (!object_equals(selectedWebsiteIdNew, data)) {
      setSelectedProfileIdNew({});
    }
    setSelectedWebsiteIdNew(data);
    renderProfileList(data, 1)
  };
  const onProfileDropdownChange = (data) => {
    const intellConfigId = data["WebsiteProfiles.IntelConfigId"];
    contextData.changeIntelConfigId(intellConfigId);
    setSelectedProfileIdNew(data)

    //setUpdateKey(Math.random())
  };
  const cancelFilters = () => {
    onWebsiteDropdownChange(JSON.parse(localStorage.getItem("WebsiteListWebsiteId")))
    onProfileDropdownChange(JSON.parse(localStorage.getItem("WebsiteProfilesIntelConfigId")))
  }
  const applyFilters = () => {

    const websiteId = selectedWebsiteIdNew["WebsiteList.WebsiteId"];

    // if (JSON.stringify(selectedProfileIdNew).length == 2) {
    //   setTabDataHide(1);
    // }
    // else {
    //   setTabDataHide(-1);
    // }

    contextData.changeWebsiteId(websiteId);
    contextData.changeSelectedWebsite(selectedWebsiteIdNew);
    //setSelectedWebsiteId(selectedWebsiteIdNew)
    contextData.changeSelectedProfile(selectedProfileIdNew);
    //setSelectedProfileId(selectedProfileIdNew);

    if (profilesList.length > 0) {
      localStorage.setItem("profileData", JSON.stringify(profilesList))
    }
    else {
      localStorage.setItem("profileData", JSON.stringify([]))
    }
    localStorage.setItem("WebsiteListWebsiteId", JSON.stringify(selectedWebsiteIdNew));// Adding WebsiteList.WebsiteId to local stoarge in case of Page Refresh
    localStorage.setItem("WebsiteProfilesIntelConfigId", JSON.stringify(selectedProfileIdNew));// Adding WebsiteProfiles.IntelConfigId to local stoarge in case of Page Refresh
  }
  const commonHeaderChild = useMemo(() => <><CommonHeader cancelFilters={cancelFilters} channel="website" autoLoad={autoLoad} dataSource={[
    { name: 'Dropdown1 - Website', data: websiteList, selectedItem: selectedWebsiteIdNew, isVisible: true, label: t("header.filterLabelWebsite"), callback: onWebsiteDropdownChange, displayText: "WebsiteList.WebsiteName" },
    { name: 'Dropdown2 - Profile', data: profilesList, selectedItem: selectedProfileIdNew, isVisible: true, label: t("header.filterLabelProfile"), callback: onProfileDropdownChange, displayText: "WebsiteProfiles.DisplayName" },
  ]} title={t("header.pageAnalyticsTitle")} showDate={true} applyFilters={applyFilters} /></>, [selectedProfileIdNew, selectedWebsiteIdNew, autoLoad])


  return (
    <>
      {blurLoader && <div id='blurdiv' className="blurbackground">
        <div className={`preloader loader-center-align`}>
          <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
        </div>
      </div>}

      <Row className="align-items-center">
        <Col>
          {commonHeaderChild}
        </Col>
      </Row>

      {contextData.websiteId && children ?
        <Row>
          <Col>
            <div className="d-inline-block ps-3 w-100" id="pageAnalyticsContent">
              <div className="shadow-soft border rounded border-light pb-3 pt-0 ps-1 pe-2 w-100 nested-card-container page-performance-detail">
                {
                  contextData.selectedProfile["WebsiteProfiles.ProfileId"] &&
                  <PagePerformanceChart
                    selectedProfile={contextData.selectedProfile}
                    startDate={contextData.startDate}
                    endDate={contextData.endDate}
                  />
                }
              </div>

              <div className="shadow-soft border rounded border-light mt-3 pt-3 w-100 nested-card-container page-performance-detail page-performance-content-insight">
                {children}
              </div>
            </div>
          </Col>
        </Row>
        :
        <></>
      }
    </>
  );
};

export default withRouter(PagePerformance);
