import React, { useEffect, useState } from 'react';
import { Card, OverlayTrigger, Image, Button, Tooltip, Accordion } from '@themesberg/react-bootstrap';
import LoaderImage from "../../assets/img/LoaderGraphic.svg";
import { useTranslation } from 'react-i18next';
import { DataStatusMessage } from "../../utils/constant";
import { METHODS } from '../../apis/utilities/constant.js';
import request from '../../apis/request.js';
import { decryptData } from '../../utils/crypto.js';
import moment from 'moment';

export const Summary = ({ startDate, endDate, profileId, companyId, isTF, toolId, cryptoKey, intelConfigId }) => {

    const [summary, setSummary] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [t] = useTranslation();

    useEffect(() => {
        if (profileId) {
            setSummaryData();
        }
    }, [profileId, startDate, endDate, toolId]);

    const setSummaryData = () => {
        setSummary({ transactionStatus: DataStatusMessage.Loading });
        const selectedStartDate = moment(startDate).format("YYYY-MM-DD");
        const selectedEndDate = moment(endDate).format("YYYY-MM-DD");
        request(`${process.env.REACT_APP_API_KEY}/VisitorAnalytics/GetResolvedUnresolvedVisitorsCountByProfileId?startDate=${selectedStartDate}&endDate=${selectedEndDate}&profileId=${profileId}&companyid=${companyId}&isTF=${isTF}&toolid=${toolId}&intelconfigid=${intelConfigId}`,
            METHODS.GET, {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                "content-type": "application/json"
            })
            .then((response) => {
                if (response && response.transactionStatus === DataStatusMessage.DataAvailable) {
                    setSummary({ ...response.result.objResponse, transactionStatus: response.transactionStatus });
                }
                else {
                    setSummary({ transactionStatus: DataStatusMessage.ErrorOccured });
                }
            })
            .catch(() => {
                setSummary({ transactionStatus: DataStatusMessage.ErrorOccured });
            })
    }

    const createTooltip = (tooltipKey, placement) => {
        if (tooltipKey !== "") {
            return (
                <OverlayTrigger
                    placement={placement}
                    trigger={["hover", "focus"]}
                    overlay={

                        <Tooltip>
                            <div>{tooltipKey}</div>
                        </Tooltip>
                    }
                >
                    <Button variant="white" className="btn-help border-0 p-2">
                        <i className="far fa-question-circle lg"></i>
                    </Button>
                </OverlayTrigger>
            );
        }
    }

    return (
        <div className="d-inline-block pt-3 ps-3 w-100 position-relative">
            <div className="nested-card-container visitor-key-insights-box rounded" id="insights-box">
                <Accordion className={"className"} defaultActiveKey={"eventKey-11"}>
                    <Accordion.Item eventKey={"eventKey-11"} className="shadow-soft border rounded border-light mb-0">
                        <Accordion.Button variant="link" className="w-100 d-flex justify-content-between p-4 pe-3">
                            <h6 className="mb-0">
                                {t("header.VisitorInsightsEquationHeading1") + " "} {summary.resolvedVisitors ? summary.resolvedVisitors : 0}
                                &nbsp;{t("header.VisitorInsightsEquationHeading2") + " "} {summary.allVisitors ? summary.allVisitors : 0} {t("header.VisitorInsightsEquationHeading3")}
                            </h6>
                        </Accordion.Button>
                        <Accordion.Body>
                            <Card.Body>
                                {summary.transactionStatus === DataStatusMessage.Loading && <div className={`preloader loader-center-align`} >
                                    <Image className="loader-element animate__animated animate__jackInTheBox" src={LoaderImage} height={40} data-seconds={30} />
                                </div>}

                                {summary.transactionStatus === DataStatusMessage.DataAvailable && summary.allVisitors != 0 && <div className="pod card-visitor-insights-container ng-scope">
                                    <div className="card card-key-insights eq-card-1">
                                        <div className="card-body">
                                            <div className="key-insight-value ng-binding" title={summary.allVisitors}>{summary.allVisitors}</div>
                                            <div className="card-value-title ng-binding key-insight-title-box">{t("header.VisitorInsightTotalVisitorsLabel")}
                                                {createTooltip(t("header.WOVisitorEquationTotalVisitors"), "right")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-operator">=</div>
                                    <div className="card card-key-insights identified-company-visitor eq-card-2">
                                        <div className="card-body">
                                            <div className="key-insight-value ng-binding" title={summary.resolvedVisitors}>
                                                {summary.resolvedVisitors}
                                                <span className="insight-percentage-value ng-binding" title={summary.rVisitorsPercentage + " of total visitors"}>{summary.rVisitorsPercentage}</span>
                                            </div>
                                            <div className="card-value-title ng-binding key-insight-title-box">{t("header.VisitorInsightIdentifiedCompanyVisitorLabel")}
                                                {createTooltip(t("header.WOVisitorEquationIdentifiedCompanyVisitors"), "right")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-operator">+</div>
                                    <div className="card card-key-insights eq-card-3">
                                        <div className="card-body">
                                            <div className="key-insight-value ng-binding" title={summary.bots}>
                                                {summary.bots}
                                                <span className="insight-percentage-value ng-binding" title={summary.botsPercentage + " of total visitors"}>{summary.botsPercentage}</span>
                                            </div>
                                            <div className="card-value-title ng-binding key-insight-title-box">{t("header.VisitorInsightIdentifiedBotsLabel")}
                                                {createTooltip(t("header.WOVisitorEquationIdentifiedBots"), "right")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-operator">+</div>
                                    <div className="card card-key-insights eq-card-4">
                                        <div className="card-body">
                                            <div className="key-insight-value ng-binding" title={summary.ispProvider}>
                                                {summary.ispProvider}
                                                <span className="insight-percentage-value ng-binding" title={summary.ispProviderPercentage + " of total visitors"}>{summary.ispProviderPercentage}</span>
                                            </div>
                                            <div className="card-value-title ng-binding key-insight-title-box">{t("header.VisitorInsightIdentifiedISPSLabel")}
                                                {createTooltip(t("header.visitorDetailsMessageText"), "right")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-operator">+</div>
                                    <div className="card card-key-insights eq-card-5">
                                        <div className="card-body">
                                            <div className="key-insight-value ng-binding" title={summary.unresolvedVisitors}>
                                                {summary.unresolvedVisitors}
                                                <span className="insight-percentage-value ng-binding" title={summary.unresolvedVisitorsPercentage + " of total visitors"}>{summary.unresolvedVisitorsPercentage}</span>
                                            </div>
                                            <div className="card-value-title ng-binding key-insight-title-box">{t("header.VisitorInsightUnknownVisitorsLabel")}
                                                {createTooltip(t("header.WOVisitorEquationUnknownVisitors"), "left")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                {summary.transactionStatus === DataStatusMessage.DataAvailable && summary.allVisitors === 0 &&
                                    <div className="card-no-data">{t("header.contentUnavailable")}</div>
                                }
                            </Card.Body>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    )
}
