import React from 'react';
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Tooltip, Button } from "@themesberg/react-bootstrap";

import { DataStatusMessage } from "../../utils/constant";

import CommonMessageComponent from "../CommonMessageComponent";

const WebcastSummary = ({ summary }) => {
    const [t] = useTranslation();

    const createTooltip = (tooltipKey, placement) => {
        if (tooltipKey !== "") {
            return (
                <OverlayTrigger
                    placement={placement}
                    trigger={["hover", "focus"]}
                    overlay={
                        <Tooltip>
                            <div>{tooltipKey}</div>
                        </Tooltip>
                    }
                >
                    <Button variant="white" className="btn-help border-0 p-1">
                        <i className="far fa-question-circle lg"></i>
                    </Button>
                </OverlayTrigger>
            );
        }
    };

    return (
        <>
            {summary.transactionStatus === DataStatusMessage.DataAvailable && (
                <div className="switch-toggle-component col-spacing-bottom webcast-stat shadow-soft border rounded border-light p-4 ps-2 pe-2 w-80 nested-card-container position-relative">
                    <div className="d-inline-block switch-toggle-component-item">
                        <div className="metrics-label-container">
                            <label className="widget-summary-metrics-label d-inline-block text-uppercase">
                                {t("header.WebcastAditionalInsightLiveViewersFilter")}{" "}
                                {createTooltip(
                                    t("header.WebcastStatisticsSummaryLiveToolTip"),
                                    "right"
                                )}
                            </label>
                            <div className="widget-summary-metrics">
                                {summary.data.uniqueLiveViewers}
                            </div>
                        </div>
                    </div>
                    <div className="d-inline-block switch-toggle-component-item">
                        <div className="metrics-label-container">
                            <label className="widget-summary-metrics-label d-inline-block text-uppercase">
                                {t("header.WebcastAditionalInsightOnDemandFilter")}{" "}
                                {createTooltip(
                                    t("header.WebcastStatisticsSummaryODToolTip"),
                                    "right"
                                )}
                            </label>
                            <div className="widget-summary-metrics">
                                {summary.data.uniqueOnDemandViewers}
                            </div>
                        </div>
                    </div>
                    <div className="d-inline-block switch-toggle-component-item">
                        <div className="metrics-label-container">
                            <label className="widget-summary-metrics-label d-inline-block text-uppercase">
                                {t("header.WebcastAditionalInsightAvgLiveViewers")}{" "}
                                {createTooltip(
                                    t("header.WebcastAvgViewDurationLive"),
                                    "left"
                                )}
                            </label>
                            <div className="widget-summary-metrics">
                                {summary.data.avgViewDurationLive
                                    ? summary.data.avgViewDurationLive
                                    : "-"}
                            </div>
                        </div>
                    </div>
                    <div className="d-inline-block switch-toggle-component-item">
                        <div className="metrics-label-container">
                            <label className="widget-summary-metrics-label d-inline-block text-uppercase">
                                {t("header.WebcastAditionalInsightAvgOnDemand")}{" "}
                                {createTooltip(
                                    t("header.WebcastAvgViewDurationOnDemand"),
                                    "left"
                                )}
                            </label>
                            <div className="widget-summary-metrics">
                                {summary.data.avgViewDurationOnDemand
                                    ? summary.data.avgViewDurationOnDemand
                                    : "-"}
                            </div>
                        </div>
                    </div>
                    <div className="d-inline-block switch-toggle-component-item">
                        <div className="metrics-label-container">
                            <label className="widget-summary-metrics-label d-inline-block text-uppercase">
                                {t("header.WebcastSummaryTotalWebcastTimeText")}{" "}
                                {createTooltip(
                                    t("header.WebcastStatisticsSummaryDuration"),
                                    "left"
                                )}
                            </label>
                            <div className="widget-summary-metrics">
                                {summary.data.webcastDuration
                                    ? summary.data.webcastDuration
                                    : "-"}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {summary.transactionStatus !== DataStatusMessage.DataAvailable && (
                <div className="switch-toggle-component col-spacing-bottom webcast-stat shadow-soft border rounded border-light p-4 ps-2 pe-2 w-100 nested-card-container position-relative">
                    <div className="d-inline-block tools-dashboard-card-loader card-spacing-bottom">
                        <CommonMessageComponent
                            transactionStatus={summary.transactionStatus}
                        ></CommonMessageComponent>
                    </div>
                </div>
            )}
        </>
    );
}

export default WebcastSummary;