import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Card, Nav, Tab } from "@themesberg/react-bootstrap";

import { DataStatusMessage } from "../../utils/constant";

import WebcastViewers from "./WebcastViewers";
import WebcastQuestions from "./WebcastQuestions";
import WebcastPolls from "./WebcastPolls";

const WebcastTabs = ({ hasEvents, companyId, webcastType, cryptoKey, filteredPresentation,
    viewerDomain, setShowLoader, setAlertVisible, masterViewers, onPageRefresh, setOnPageRefresh }) => {

    const [t] = useTranslation();

    const [activeTab, setActiveTab] = useState("viewers");
    const [masterQuestions, setMasterQuestions] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [polls, setPolls] = useState({ transactionStatus: DataStatusMessage.Loading });
    const [appliedViewerFilter, setAppliedViewerFilter] = useState({
        viewerType: { value: "", label: "All" },
        deviceType: { value: "", label: "All" },
        domain: { value: "", label: "All" },
        viewer: { value: "unique", label: "Unique" }
    });
    const [appliedQuestionFilter, setAppliedQuestionFilter] = useState({
        status: { value: "", label: "All" }
    });

    useEffect(() => {
        if (Object.keys(filteredPresentation).length > 0) {
            setActiveTab("viewers");
            setMasterQuestions({ transactionStatus: DataStatusMessage.Loading });
            setPolls({ transactionStatus: DataStatusMessage.Loading });
        }
    }, [filteredPresentation]);

    return (
        <Row>
            <Col md={12}>
                <div>
                    <Card border="light" className="bg-white shadow-sm">
                        <Card.Body>
                            <Tab.Container activeKey={activeTab}>
                                <Nav
                                    fill
                                    variant="pills"
                                    className="flex-column main-tab flex-sm-row tab border-bottom-1 mt-3 mb-2 mx-3"
                                >
                                    <Nav.Item className="pe-0">
                                        <Nav.Link
                                            eventKey="viewers"
                                            onClick={() => {
                                                setActiveTab("viewers"); setOnPageRefresh(0);
                                            }}
                                            className="mb-sm-3 mb-md-0"
                                        >
                                            <span className="d-inline-block w-100 tools-tab-label-text">
                                                {t("header.VisitorsActivityPopWebcastTabText")}
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="pe-0">
                                        <Nav.Link
                                            eventKey="questions"
                                            onClick={() => {
                                                setActiveTab("questions"); setOnPageRefresh(0);
                                            }}
                                            className="mb-sm-3 mb-md-0"
                                        >
                                            <span className="d-inline-block w-100 tools-tab-label-text">
                                                {t("header.WebcastViewersQuestionsTab")}
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    {hasEvents === 1 && (
                                        <Nav.Item className="pe-0">
                                            <Nav.Link
                                                eventKey="polls"
                                                onClick={() => {
                                                    setActiveTab("polls"); setOnPageRefresh(0);
                                                }}
                                                className="mb-sm-3 mb-md-0"
                                            >
                                                <span className="d-inline-block w-100 tools-tab-label-text">
                                                    {t("header.webcastTabText")}
                                                </span>
                                            </Nav.Link>
                                        </Nav.Item>
                                    )}
                                </Nav>
                                <Tab.Content className="mt-4">
                                    <Tab.Pane eventKey="viewers" className="py-2">
                                        {activeTab === "viewers" && <WebcastViewers hasEvents={hasEvents} companyId={companyId} cryptoKey={cryptoKey} filteredPresentation={filteredPresentation}
                                            setShowLoader={setShowLoader} setAlertVisible={setAlertVisible} masterViewers={masterViewers} viewerDomain={viewerDomain} appliedViewerFilter={appliedViewerFilter} setAppliedViewerFilter={setAppliedViewerFilter} />}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="questions" className="py-2">
                                        {activeTab === "questions" && <WebcastQuestions hasEvents={hasEvents} webcastType={webcastType} cryptoKey={cryptoKey} filteredPresentation={filteredPresentation}
                                            setShowLoader={setShowLoader} setAlertVisible={setAlertVisible} masterQuestions={masterQuestions} setMasterQuestions={setMasterQuestions} appliedQuestionFilter={appliedQuestionFilter} setAppliedQuestionFilter={setAppliedQuestionFilter} onPageRefresh={onPageRefresh} />}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="polls" className="py-2">
                                        {activeTab === "polls" && <WebcastPolls hasEvents={hasEvents} cryptoKey={cryptoKey} filteredPresentation={filteredPresentation} polls={polls} setPolls={setPolls} onPageRefresh={onPageRefresh} />}
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Card.Body>
                    </Card>
                </div>
            </Col>
        </Row>
    )
}

export default WebcastTabs;