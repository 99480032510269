import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router';
import { HashLink } from 'react-router-hash-link';
import AppContext from '../components/AppContext';
import { isMobile } from "react-device-detect";
import { Nav, Card, Navbar, Container, Image, Form, Button, Dropdown } from '@themesberg/react-bootstrap';
import { getStringValue } from "../utils/common";
import Profile3 from "../assets/img/profile-picture-3.jpg";
import NoImageFound from "../assets/img/no-user-image.jpg";
import ConnectIDLogo from "../assets/img/ConnectIDIntelligence.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import HeaderDropdowns from "../components/HeaderDropdowns";
import { useSSR, useTranslation } from 'react-i18next';
import IdleTimerContainer from './IdleTimerContainer';
import { decryptData } from '../utils/crypto';

const Header = (props) => {
  const contextData = useContext(AppContext);

  const [showSideBar, setShowSideBar] = useState(false);
  const [t, i18n] = useTranslation();
  const showHamburger = isMobile ? true : false;

  return (

    <Navbar expanded
      className=""
      id="mainnav">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">


          <Navbar.Brand as={HashLink} to="/website" className="me-lg-3 d-flex align-items-center" id="logo">
            <Image src={ConnectIDLogo} className="logo-avtar large-avatar" />
          </Navbar.Brand>


          <Nav className="align-items-center">
            <HeaderDropdowns setValid={props.setValid}/>
          </Nav>

        </div>
        <IdleTimerContainer></IdleTimerContainer> {/* IdleTimerContainer is for Session time out Popup*/}
      </Container>
    </Navbar>

  );
};

export default withRouter(Header);
