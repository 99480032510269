import SECFilings from '../../components/TnfAnalytics/SECFilings'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from "react-i18next";
import { Row, Col } from '@themesberg/react-bootstrap';
import CommonHeader from '../../components/CommonHeader';
import AppContext from '../../components/AppContext';
import request from "../../apis/request";
import { METHODS, baseURL } from "../../apis/utilities/constant";
import { decryptData } from '../../utils/crypto';


export const SECFilingsPage = () => {
    const [t] = useTranslation();
    const contextData = useContext(AppContext);

    const [websiteList, setWebsiteList] = useState([]);
    const [profilesList, setProfilesList] = useState([]);

    const [selectedWebsiteIdNew, setSelectedWebsiteIdNew] = useState(websiteList[0]);
    const [selectedProfileIdNew, setSelectedProfileIdNew] = useState('')
    const [autoLoad, setAutoLoad] = useState(false);

    useEffect(() => {
        if (contextData.companyId > 0) {

            setAutoLoad(false);
            if (localStorage.getItem("TnfWebsiteData") && JSON.parse(localStorage.getItem("TnfWebsiteData")).length > 0
                && localStorage.getItem("TnfAllProfileData") && JSON.parse(localStorage.getItem("TnfAllProfileData")).length > 0) {

                let websiteList = JSON.parse(localStorage.getItem("TnfWebsiteData"));
                let selectedWebsite = JSON.parse(localStorage.getItem("TnfWebsiteListWebsiteId"));
                renderWebsite(websiteList, selectedWebsite)

            } else if (!localStorage.getItem("TnfWebsiteData") || !localStorage.getItem("TnfAllProfileData")
                || JSON.parse(localStorage.getItem("TnfWebsiteData")).length == 0 || JSON.parse(localStorage.getItem("TnfAllProfileData")).length == 0) {

                if (!localStorage.getItem("TnfWebsiteData") || JSON.parse(localStorage.getItem("TnfWebsiteData")).length == 0) {
                    getWebsites();
                }
                else if (!localStorage.getItem("TnfAllProfileData") || JSON.parse(localStorage.getItem("TnfAllProfileData")).length == 0) {
                    getProfiles(JSON.parse(localStorage.getItem("TnfWebsiteData")));
                }
            }

        }
    }, [contextData.companyId])

    const getWebsites = () => {



        request(baseURL + `/TnfAnalytics/GetTnFChannelWebsiteByClientUser?CompanyId=${contextData.companyId}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
                let resultData = [];

                if (res.result && res.result.length > 0) {
                    resultData = res.result.map((e) => ({ cmsplatformid: res.platformid, ["WebsiteList.WebsiteName"]: e.WebsiteName, ["WebsiteList.WebsiteId"]: e.WebsiteId, ["WebsiteList.WebsiteUrl"]: e.WebsiteUrl }))
                }

                if (resultData.length > 0) {
                    localStorage.setItem("TnfWebsiteData", JSON.stringify(resultData))
                    getProfiles(resultData)
                }
                else {
                    localStorage.setItem("TnfWebsiteData", JSON.stringify([]))
                    localStorage.setItem("TnfAllProfileData", JSON.stringify([]))
                }

            })
            .catch((err) => {

            })

    }

    const getProfiles = (resultData) => {


        request(baseURL + `/TnfAnalytics/GetTnFWebsites?CompanyId=${contextData.companyId}`,
            METHODS.GET,
            {},
            {
                Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`, "content-type": "application/json"
            })
            .then((res) => {
                if (res.result != null && res.result.length > 0) {
                    let result = removeDuplicateObjects(res.result, 'IntelConfigId')
                    localStorage.setItem("TnfAllProfileData", JSON.stringify(result))

                } else {

                    localStorage.setItem("TnfAllProfileData", JSON.stringify([]))
                }


                if (!localStorage.getItem("TnfWebsiteListWebsiteId")) {
                    renderWebsite(resultData, resultData[0])
                }
                else {
                    renderWebsite(resultData, JSON.parse(localStorage.getItem("TnfWebsiteListWebsiteId")))
                }

                if (res.result.length == 0) {
                    setAutoLoad(true);
                }
            })
            .catch((err) => {

            })

    };

    const renderWebsite = (resultSet, selectedWebsiteValue) => {
        setWebsiteList(resultSet)
        setSelectedWebsiteIdNew(selectedWebsiteValue);
        renderProfileList(selectedWebsiteValue, 0);
    };

    const renderProfileList = (selectedWebsiteValue, isTempUpdate) => {
        if (localStorage.getItem("TnfAllProfileData") && JSON.parse(localStorage.getItem("TnfAllProfileData")).length > 0) {

            let profileList = JSON.parse(localStorage.getItem("TnfAllProfileData"));
            let selectedWebsiteProfiles = profileList.filter((e) => { return e.WebsiteId === selectedWebsiteValue["WebsiteList.WebsiteId"] });

            if (selectedWebsiteProfiles.length > 0) {
                let profileData = selectedWebsiteProfiles.map((e) => ({ ["WebsiteProfiles.ProfileId"]: e.ProfileId, ["WebsiteProfiles.DisplayName"]: e.DisplayName, ["WebsiteProfiles.IntelConfigId"]: e.IntelConfigId }));

                setProfilesList(profileData)

                let selectedProfile = profileData[0];


                if (isTempUpdate === 0) {

                    let storedProfile = JSON.parse(localStorage.getItem("TnfWebsiteProfilesIntelConfigId"));
                    if (storedProfile && Object.keys(storedProfile).length > 0
                        && profileData.some(obj => obj["WebsiteProfiles.ProfileId"] === storedProfile["WebsiteProfiles.ProfileId"])) {
                        selectedProfile = storedProfile;
                    };

                    localStorage.setItem("TnfProfileData", JSON.stringify(profileData))
                }
                setSelectedProfileIdNew(selectedProfile);
            }
            else {
                setProfilesList([])
                setSelectedProfileIdNew({});
                if (isTempUpdate === 0) {
                    localStorage.setItem("TnfProfileData", JSON.stringify([]))
                }
            }
        }
        setAutoLoad(true)
    }

    function removeDuplicateObjects(array, key) {
        const seen = {};
        return array.filter(item => {
            const itemKey = key ? item[key] : item;
            return seen.hasOwnProperty(itemKey) ? false : (seen[itemKey] = true);
        });
    }


    const onWebsiteDropdownChange = (data) => {

        setSelectedWebsiteIdNew(data);
        renderProfileList(data, 1)
    };

    const onProfileDropdownChange = (data) => {
        const intellConfigId = data["WebsiteProfiles.IntelConfigId"];
        contextData.changeIntelConfigId(intellConfigId);
        setSelectedProfileIdNew(data)
    };

    const applyFilters = () => {
        const websiteId = selectedWebsiteIdNew["WebsiteList.WebsiteId"];

        contextData.changeWebsiteId(websiteId);
        contextData.changeSelectedWebsite(selectedWebsiteIdNew);


        if (JSON.parse(localStorage.getItem("TnfAllProfileData")).length > 0) {
            contextData.changeSelectedProfile(selectedProfileIdNew);
        }



        if (profilesList.length > 0) {
            localStorage.setItem("TnfProfileData", JSON.stringify(profilesList))
        }
        else {
            localStorage.setItem("TnfProfileData", JSON.stringify([]))
        }
        localStorage.setItem("TnfWebsiteListWebsiteId", JSON.stringify(selectedWebsiteIdNew));// Adding WebsiteList.WebsiteId to local stoarge in case of Page Refresh
        localStorage.setItem("TnfWebsiteProfilesIntelConfigId", JSON.stringify(selectedProfileIdNew));// Adding WebsiteProfiles.IntelConfigId to local stoarge in case of Page Refresh
    }

    const cancelFilters = () => {
        onWebsiteDropdownChange(JSON.parse(localStorage.getItem("TnfWebsiteListWebsiteId")))
        onProfileDropdownChange(JSON.parse(localStorage.getItem("TnfWebsiteProfilesIntelConfigId")))
    }



    const commonHeaderChild = useMemo(() =>
        <>
            <CommonHeader channel="IR-tools-anaytics" cancelFilters={cancelFilters} autoLoad={autoLoad} dataSource={[
                { name: 'Dropdown1 - Website', data: websiteList, selectedItem: selectedWebsiteIdNew, isVisible: true, label: t("header.filterLabelWebsite"), callback: onWebsiteDropdownChange, displayText: "WebsiteList.WebsiteName" },
                { name: 'Dropdown2 - Profile', data: profilesList, selectedItem: selectedProfileIdNew, isVisible: true, label: t("header.filterLabelProfile"), callback: onProfileDropdownChange, displayText: "WebsiteProfiles.DisplayName" },
            ]}
                // title={t("header.Share_Price_Calculator")}
                title={contextData.menu
                    .find(menuItem => menuItem.title === "IR Tools Analytics")
                    ?.subMenu?.find(subMenuItem => subMenuItem.toolid === 16)
                    ?.title}
                showDate={true}
                applyFilters={applyFilters}
            />
        </>,
        [selectedProfileIdNew, selectedWebsiteIdNew, autoLoad])
    return (
        <>
            <Row className="align-items-center">
                <Col>
                    {commonHeaderChild}
                </Col>
            </Row>
            {contextData.selectedProfile["WebsiteProfiles.ProfileId"] &&
                <SECFilings
                    profileID={contextData.selectedProfile["WebsiteProfiles.ProfileId"]}
                    startDate={contextData.startDate}
                    endDate={contextData.endDate}
                    intelConfigID={contextData.selectedProfile["WebsiteProfiles.IntelConfigId"]}
                    cryptoKey={contextData.cryptoKey}
                />
            }
        </>
    )
}
