export const getNotifications = (profileId, companyid, intellconfigid) => {
  let obj = [
    {
      apiUrl: { url: `${process.env.REACT_APP_API_KEY}/WebsiteAnalytics/GetNotification?profileId=` + profileId + `&packageid=5&companyid=` + companyid + `&intellconfigid=` + intellconfigid, type: 'get', count: 0 },
      id: "1",
      layout: "{\"x\":0,\"y\":24,\"w\":12,\"h\":15}",
      name: "Notifications",
    }
  ];
  return obj;
};
