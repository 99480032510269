import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from "react-router-dom";
import { getInitials } from '../utils/common';
import iconSprite from "../assets/Icon/svg-icn-sprite.svg";
import iconSprite2 from "../assets/Icon/icon-sprite.svg";
import AppContext from '../components/AppContext';
import { faComment, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Feedback from './Feedback';
import { Alert, Button } from "@themesberg/react-bootstrap";


function AvatarWithDropdown({ userName, packageData, logout }) {
    const contextData = useContext(AppContext);
    const dropdownRef = useRef(null);
    const history = useHistory();


    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const [feedbackModal, setFeedbackModal] = useState(false);

    const [message, setMessage] = useState(undefined);


    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        // alert()
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const HandleTabClick = (tabName) => {
        localStorage.setItem("selectedTab", "Settings")
        contextData.changeSelectedTab("Settings")

        if (tabName === "myaccount") {
            history.push("/settings/myaccount-page");
        }
        if (tabName === "mydocuments") {
            history.push("/settings/mydocuments-page");
        }
        if (tabName === "digestsusbcriptions") {
            history.push("/settings/digestsubscriptions-page");
        }
    }
    const HandleFeedbackClick = () => {
        setDropdownOpen(false);
        setFeedbackModal(true)
    }

    const closeModal = () => {
        setFeedbackModal(false)
    }

    const onCloseSuccessMessage = () => {
        setMessage(undefined)
    };


    return (
        <div className="avatar" ref={dropdownRef}>

            <div className='avatar--container'>
                <div className='name-package'>
                    <span className="avatar--container__name">{userName}</span>

                    <div className={`package badge package--${packageData?.replace(/\s+/g, "")} me-3`}>
                        <span className='package--value'>{packageData}</span>
                    </div>
                </div>


                <div className="avatar--container__circle" onClick={() => toggleDropdown()}>
                    <span className="avatar--container__circle--image">{getInitials(userName)}</span>
                </div>
            </div>

            <div className={`avatar--dropdown-menu ${isDropdownOpen ? "open" : "closed"}`}>
                <ul>
                    <li onClick={() => HandleTabClick("myaccount")}>
                        <svg>
                            <use href={iconSprite2 + `#account`} />
                        </svg>
                        My account
                    </li>
                    <li onClick={() => HandleTabClick("mydocuments")}>
                        <svg>
                            <use href={iconSprite2 + `#file`} />
                        </svg>
                        My documents
                    </li>
                    <li onClick={() => HandleTabClick("digestsusbcriptions")}>
                        <svg>
                            <use href={iconSprite2 + `#mail`} />
                        </svg>
                        Digest subscriptions
                    </li>
                    <li onClick={() => HandleFeedbackClick()}>
                        <FontAwesomeIcon icon={faComment} className="fa-lg" />
                        Feedback
                    </li>
                    <li onClick={() => logout()}>
                        <svg>
                            <use href={iconSprite + `#Log out`} />
                        </svg>
                        Log out
                    </li>
                </ul>
            </div>

            {message && <div className="fade modal-backdrop show"></div>}

            {message &&
                <Alert
                    className="toast-custom"
                    variant={message.status === "Success" ? "success" : "danger"}
                    show={message ? true : false}
                    onClose={() => onCloseSuccessMessage()}
                >
                    <div className="d-flex justify-content-between">
                        <div>
                            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                            {message.message}
                        </div>
                        <Button variant="close" size="xs" onClick={() => onCloseSuccessMessage()} />
                    </div>
                </Alert>
            }

            {feedbackModal &&
                <Feedback cryptoKey={contextData.cryptoKey} setMessage={setMessage} onClose={closeModal} />
            }

        </div>
    );
}

export default AvatarWithDropdown
