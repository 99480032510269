import React, { useEffect, useState, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "@themesberg/react-bootstrap";

import request from "../apis/request";
import { baseURL, METHODS } from "../apis/utilities/constant";
import { decryptData } from "../utils/crypto";

import AppContext from "../components/AppContext";
import CommonHeader from "../components/CommonHeader";
import WebcastManageUsers from "../components/Webcast/WebcastManageUsers";

export const WebcastManageUserListPage = () => {
  const [t, i18n] = useTranslation();
  const contextData = useContext(AppContext);

  const [selectedPresentationNew, setSelectedPresentationNew] = useState({});
  const [selectedEventNew, setSelectedEventNew] = useState({});

  const [filteredEvent, setFilteredEvent] = useState({});
  const [filteredPresentation, setFilteredPresentation] = useState({});

  const [eventData, setEventData] = useState([]);
  const [presentationData, setPresentationData] = useState([]);

  const [autoLoad, setAutoLoad] = useState(false);

  useEffect(() => {
    if (contextData.companyId) {
      getWebcastConfig(contextData.companyId);
    }
  }, [contextData.companyId]);

  const getWebcastConfig = (companyId) => {
    if (companyId > 0) {
      request(
        baseURL + `/WebcastV2/GetWebcastConfiguration?companyId=` + companyId,
        METHODS.GET,
        {},
        {
          Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
          "content-type": "application/json",
        }
      )
        .then((res) => {
          if (res) {
            contextData.changeHasEvents(res.hasEvent);
            contextData.changeWebcastType(res.webcastType);
            contextData.changeWebcastClientId(res.webcastClientId);
            getEventPresentationData(res.hasEvent);
          }
        })
        .catch((err) => {
          console.log("error", err);
        })
    }
  }

  const getEventPresentationData = (hasEvents) => {
    request(baseURL + `/Common/GetWebcastPresentationByClientUser?CompanyId=${contextData.companyId}&UserId=${contextData.userId}`,
      METHODS.GET,
      {},
      {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"), contextData.cryptoKey)}`,
        "content-type": "application/json",
      }
    )
      .then((res) => {
        debugger;
        let event = [];
        let presentation = [];
        if (res !== null) {
          if (hasEvents === 1) {
            event = res.Events.map((e) => ({ ["Events.title"]: e.EventTitle, ["Events.id"]: e.EventId }));
          }
          else {
            presentation = res.EventPresentations.map((e) => ({
              ["Presentations.name"]: e.Name,
              ["Presentations.id"]: e.Slug,
              ["Presentations.clientslug"]: e.ClientId,
              ["Presentations.slug"]: e.Url,
              ["Presentations.eventid"]: e.EventId,
              ["Presentations.url"]: e.Url,
              ["Presentations.clientid"]: e.ClientId,
            }));
          }

          if (event !== null && event.length) {
            setEventData(event);
            if (JSON.parse(localStorage.getItem("webcastEventId")) !== null && Object.keys(JSON.parse(localStorage.getItem("webcastEventId"))).length) {
              onEventDropdownChange(JSON.parse(localStorage.getItem("webcastEventId")));
            } else {
              onEventDropdownChange(event[0]);
            }
          }

          if (presentation !== null && presentation.length) {
            setPresentationData(presentation);
            console.log(JSON.parse(localStorage.getItem("presentationId")));
            if (JSON.parse(localStorage.getItem("presentationId")) !== null && Object.keys(JSON.parse(localStorage.getItem("presentationId"))).length) {
              onPresentationDropdownChange(JSON.parse(localStorage.getItem("presentationId")));
            }
            else {
              onPresentationDropdownChange(presentation[0]);
            }
          }
          setAutoLoad(true);
        }
      })
      .catch((err) => { });
  };

  const onEventDropdownChange = (data) => {
    setSelectedEventNew(data);
  };

  const onPresentationDropdownChange = (data) => {
    setSelectedPresentationNew(data);
  };

  const applyFilters = () => {
    if (contextData.hasEvents === 1) {
      setFilteredEvent(selectedEventNew);
      localStorage.setItem("webcastEventId", JSON.stringify(selectedEventNew));
    } else {
      localStorage.setItem("presentationId", JSON.stringify(selectedPresentationNew));
      setFilteredPresentation(selectedPresentationNew)
    }
  }

  const cancelFilters = () => {
    if (contextData.hasEvents === 1) {
      onEventDropdownChange(JSON.parse(localStorage.getItem("webcastEventId")))
    } else {
      onPresentationDropdownChange(JSON.parse(localStorage.getItem("presentationId")))
    }
  }

  const commonHeader = useMemo(
    () => (
      <>
        <CommonHeader
          channel="webcast"
          cancelFilters={cancelFilters}
          autoLoad={autoLoad}
          dataSource={[
            {
              name: "Dropdown1 - Events",
              data: eventData,
              selectedItem: selectedEventNew,
              isVisible: contextData.hasEvents == 1,
              label: t("header.filterEventLabel"),
              callback: onEventDropdownChange,
              displayText: "Events.title",
            },
            {
              name: "Dropdown2 - Presentation",
              data: presentationData,
              selectedItem: selectedPresentationNew,
              isVisible: contextData.hasEvents == 0,
              label: t("header.filterPresentationLabel"),
              callback: onPresentationDropdownChange,
              displayText: "Presentations.name",
            },
          ]}
          title={t("header.manageusersTitle")}
          showDate={false}
          applyFilters={applyFilters}
        />
      </>
    ),
    [selectedPresentationNew, selectedEventNew, autoLoad]
  );

  return (
    <Row>
      <Col xs={12}>
        {commonHeader}
        {(Object.keys(filteredEvent).length > 0 || Object.keys(filteredPresentation).length > 0) &&
          <WebcastManageUsers hasEvents={contextData.hasEvents} event={filteredEvent} presentation={filteredPresentation} cryptoKey={contextData.cryptoKey} />
        }
      </Col>
    </Row>
  )
};


export default WebcastManageUserListPage;